import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Fade, Grid, MenuItem, Modal, Typography } from "@mui/material";
import { Field, FieldArray, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { array, object, string } from "yup";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import REACT_APP from "../../../environment";
import axios from "axios";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Backdrop from "@mui/material/Backdrop";
import "../../../App.css";
import { Spin } from "antd";
import regex from "tts-hrp-val-lib";
import { useTranslation } from "react-i18next";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const WorkHistory = ({ handleClosee, id, getDataa }) => {
    const { t } = useTranslation();
    const [view, setView] = useState(false);
    const [works, setWorks] = useState();
    const [country, setCountry] = useState([]);
    const [regions, setRegions] = useState([]);
    const [edit, setEdit] = useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const params = useParams();

    useEffect(() => {
        getWork();
        getCountry();
        getRegions();
    }, []);

    const emptyWorkHistory = {
        name: "",
        position: "",
        start_date: "",
        end_date: "",
        phone: "",
        email: undefined,
        website: undefined,
        supervisor: { name: "", position: "", phone: "", email: "" },
        references: [
            { first_name: "", last_name: "", position: "", phone: "", email: "" },
            { first_name: "", last_name: "", position: "", phone: "", email: "" },
        ],
        address: {
            country: "",
            address1: "",
            address2: undefined,
            city: "",
            region: "",
            zip_code: undefined,
        },
    };

    const workHistorySchema = object({
        name: string()
            .matches(regex.company_name, { message: t("invalid value") })
            .required(t("field is required")),
        position: string()
            .matches(regex.work_position, { message: t("invalid value") })
            .required(t("field is required")),
        start_date: Yup.date().max(new Date(), t("invalid value")).typeError(t("invalid value")).required(t("field is required")),
        end_date: Yup.date()
            .required(t("field is required"))
            .when("start_date", (start_date, yup) => start_date && yup.min(start_date, t("invalid value"))),
        email: string().email(t("invalid value")).nullable(),
        phone: string()
            .matches(regex.phone, { message: t("invalid value") })
            .required(t("field is required")),
        website: string()
            .matches(regex.website, { message: t("invalid value") })
            .nullable(),

        supervisor: object({
            name: string()
                .matches(regex.full_name, { message: t("invalid value") })
                .required(t("field is required")),
            position: string()
                .matches(regex.work_position, { message: t("invalid value") })
                .required(t("field is required")),
            phone: string()
                .matches(regex.phone, { message: t("invalid value") })
                .required(t("field is required")),
            email: string().required(t("field is required")).email(t("invalid value")),
        }),

        references: array(
            object({
                first_name: string()
                    .matches(regex.first_name, { message: t("invalid value") })
                    .required(t("field is required")),
                last_name: string()
                    .matches(regex.last_name, { message: t("invalid value") })
                    .required(t("field is required")),
                position: string()
                    .matches(regex.work_position, { message: t("invalid value") })
                    .required(t("field is required")),
                phone: string()
                    .matches(regex.phone, { message: t("invalid value") })
                    .required(t("field is required")),
                email: string().required(t("field is required")).email(t("invalid value")),
            })
        ),

        address: object({
            country: string().required(t("field is required")),
            address1: string()
                .matches(regex.address1, { message: t("invalid value") })
                .required(t("field is required")),
            address2: string()
                .matches(regex.address2, { message: t("invalid value") })
                .nullable(),
            city: string()
                .matches(regex.city, { message: t("invalid value") })
                .required(t("field is required")),
            region: string().required(t("field is required")),
            zip_code: string()
                .matches(regex.zip_code, { message: t("invalid value") })
                .nullable()
                .optional(),
        }),
    });

    const getCountry = () => {
        axios(`${REACT_APP.COMMON_API}/tts/api/v01/countries`, {
            headers: {
                "client-id": REACT_APP.COMMON_ID,
                secret: REACT_APP.COMMON_SECRET,
            },
        })
            .then((res) => setCountry(res.data.data))
            .catch((err) => {});
    };

    const getRegions = () => {
        axios(`${REACT_APP.COMMON_API}/tts/api/v01/region/14ed6cfc-d787-45ba-b44d-84ac06e37935`, {
            headers: {
                "client-id": REACT_APP.COMMON_ID,
                secret: REACT_APP.COMMON_SECRET,
            },
        })
            .then((res) => setRegions(res.data.data))
            .catch((err) => {});
    };

    const getWork = async () => {
        await fetch(`${REACT_APP.URL}/api/hrp/v1/employee/edit/work/${id}`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((response) => response.json())
            .then((res) => {
                if (res?.data?.address?.zip_code === null) {
                    res.data.address.zip_code = "";
                }
                setWorks(res.data);
                setTimeout(() => {
                    setView(true);
                }, 1000);
            })
            .catch((err) => {
                setView(false);
                toast.error(t("Something went wrong"))
            });
    };

    const submit = (value) => {
        if (!value?.address?.zip_code.length) {
            value.address.zip_code = null;
        }
        axios
            .put(`${REACT_APP.URL}/api/hrp/v1/employee/edit/work`, value, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                handleClosee();
                getDataa();
                toast.success(t("Success"));
            })
            .catch((err) => toast.error(t("Something went wrong")));
    };

    return (
        <>
            <div>
                <Button onClick={handleOpen}>{t("Review")}</Button>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{ backdrop: { timeout: 500 } }}
                >
                    <Fade in={open}>
                        <Box className="res_modal overflow_scroll" sx={style}>
                            <Button onClick={handleClose} variant="contained" color="primary" style={{ float: "right" }}>
                                <CloseIcon />
                            </Button>
                            <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                                {t("Work history info")}
                            </Typography>
                            {view ? (
                                <Formik
                                    initialValues={works?.length !== 0 ? works : { work: emptyWorkHistory }}
                                    validationSchema={workHistorySchema}
                                    onSubmit={async (values) => {
                                        submit(values);
                                        return new Promise((res) => setTimeout(res, 500));
                                    }}
                                >
                                    {({ values, errors, isSubmitting }) => (
                                        <Form autoComplete="off">
                                            <Grid container direction="column" spacing={2}>
                                                <React.Fragment>
                                                    <Grid item container spacing={2} xs={12}>
                                                        <Grid item xs={12} sm={4}>
                                                            <Field
                                                                fullWidth
                                                                variant="standard"
                                                                name="name"
                                                                pattern={"[0-9]"}
                                                                component={TextField}
                                                                disabled={edit ? false : true}
                                                                label={t("Name")}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Field
                                                                fullWidth
                                                                variant="standard"
                                                                name={`position`}
                                                                component={TextField}
                                                                disabled={edit ? false : true}
                                                                label={t("Position")}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Field
                                                                fullWidth
                                                                variant="standard"
                                                                name={`phone`}
                                                                component={TextField}
                                                                disabled={edit ? false : true}
                                                                label={t("Company phone")}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Field
                                                                fullWidth
                                                                variant="standard"
                                                                name={`website`}
                                                                component={TextField}
                                                                disabled={edit ? false : true}
                                                                label={t("Company website")}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Field
                                                                fullWidth
                                                                variant="standard"
                                                                name={`email`}
                                                                component={TextField}
                                                                disabled={edit ? false : true}
                                                                label={t("Company email")}
                                                            />
                                                        </Grid>
                                                        {edit ? (
                                                            <>
                                                                <Grid item xs={12} sm={4}>
                                                                    <label className="mat_label">{t("Start date")}</label>
                                                                    <Field
                                                                        fullWidth
                                                                        variant="standard"
                                                                        type="date"
                                                                        name={`start_date`}
                                                                        component={TextField}
                                                                        disabled={edit ? false : true}
                                                                        format="dd/MM/yyyy"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={12}>
                                                                    <label className="mat_label">{t("End date")}</label>
                                                                    <Field
                                                                        fullWidth
                                                                        variant="standard"
                                                                        type="date"
                                                                        name={`end_date`}
                                                                        component={TextField}
                                                                        disabled={edit ? false : true}
                                                                        format="dd/MM/yyyy"
                                                                    />
                                                                </Grid>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Grid item xs={12} sm={4}>
                                                                    <label className="mat_label">{t("Start date")}</label>
                                                                    <Field
                                                                        fullWidth
                                                                        variant="standard"
                                                                        name={`start_date`}
                                                                        component={TextField}
                                                                        disabled={edit ? false : true}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={12}>
                                                                    <label className="mat_label">{t("End date")}</label>
                                                                    <Field
                                                                        fullWidth
                                                                        variant="standard"
                                                                        name={`end_date`}
                                                                        component={TextField}
                                                                        disabled={edit ? false : true}
                                                                    />
                                                                </Grid>
                                                            </>
                                                        )}
                                                        <Grid item xs={12}>
                                                            <p className="text-center mt-3">{t("Supervisor")}</p>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Field
                                                                fullWidth
                                                                variant="standard"
                                                                name={`supervisor.name`}
                                                                component={TextField}
                                                                disabled={edit ? false : true}
                                                                label={t("Supervisor name")}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Field
                                                                fullWidth
                                                                variant="standard"
                                                                name={`supervisor.position`}
                                                                component={TextField}
                                                                disabled={edit ? false : true}
                                                                label={t("Supervisor position")}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Field
                                                                fullWidth
                                                                variant="standard"
                                                                name={`supervisor.phone`}
                                                                component={TextField}
                                                                disabled={edit ? false : true}
                                                                label={t("Supervisor phone")}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={12}>
                                                            <Field
                                                                fullWidth
                                                                variant="standard"
                                                                name={`supervisor.email`}
                                                                component={TextField}
                                                                disabled={edit ? false : true}
                                                                label={t("Supervisor email")}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <p className="text-center mt-3">{t("References")}</p>
                                                        </Grid>

                                                        {values.references?.map((_, idx) => (
                                                            <Grid container item key={idx} spacing={2}>
                                                                <Grid item container spacing={2} xs={12}>
                                                                    <Grid item xs={12} sm={6}>
                                                                        <Field
                                                                            fullWidth
                                                                            variant="standard"
                                                                            name={`references[${idx}].first_name`}
                                                                            component={TextField}
                                                                            disabled={edit ? false : true}
                                                                            label={t("First Name")}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6}>
                                                                        <Field
                                                                            fullWidth
                                                                            variant="standard"
                                                                            name={`references[${idx}].last_name`}
                                                                            component={TextField}
                                                                            disabled={edit ? false : true}
                                                                            label={t("Last Name")}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={4}>
                                                                        <Field
                                                                            fullWidth
                                                                            variant="standard"
                                                                            name={`references[${idx}].position`}
                                                                            component={TextField}
                                                                            disabled={edit ? false : true}
                                                                            label={t("Position")}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={4}>
                                                                        <Field
                                                                            fullWidth
                                                                            variant="standard"
                                                                            name={`references[${idx}].phone`}
                                                                            component={TextField}
                                                                            disabled={edit ? false : true}
                                                                            label={t("Phone")}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={4}>
                                                                        <Field
                                                                            fullWidth
                                                                            variant="standard"
                                                                            name={`references[${idx}].email`}
                                                                            component={TextField}
                                                                            disabled={edit ? false : true}
                                                                            label={t("Email")}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        ))}

                                                        <Grid item xs={12}>
                                                            <p className="text-center mt-3">{t("Address")}</p>
                                                        </Grid>

                                                        <Grid item xs={12} sm={4}>
                                                            <Field
                                                                fullWidth
                                                                variant="standard"
                                                                name={`address.address1`}
                                                                component={TextField}
                                                                disabled={edit ? false : true}
                                                                label={t("Address 1")}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Field
                                                                fullWidth
                                                                variant="standard"
                                                                name={`address.address2`}
                                                                component={TextField}
                                                                disabled={edit ? false : true}
                                                                label={t("Address 2")}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Field
                                                                fullWidth
                                                                variant="standard"
                                                                name={`address.city`}
                                                                component={TextField}
                                                                disabled={edit ? false : true}
                                                                label={t("City")}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Field
                                                                fullWidth
                                                                variant="standard"
                                                                name={`address.zip_code`}
                                                                component={TextField}
                                                                disabled={edit ? false : true}
                                                                label={t("Zipcode")}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Field
                                                                fullWidth
                                                                variant="standard"
                                                                component={TextField}
                                                                disabled={edit ? false : true}
                                                                name={`address.country`}
                                                                onClick={() => (values.address.region = "")}
                                                                select
                                                                label={t("Country")}
                                                            >
                                                                {country.map((el) => (
                                                                    <MenuItem key={el.id} value={el.name}>
                                                                        {el.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            {values.address.country === "Uzbekistan" ? (
                                                                <Field
                                                                    fullWidth
                                                                    variant="standard"
                                                                    component={TextField}
                                                                    disabled={edit ? false : true}
                                                                    name={`address.region`}
                                                                    select
                                                                    label={t("Region")}
                                                                >
                                                                    {regions.map((el) => (
                                                                        <MenuItem key={el.id} value={el.name}>
                                                                            {/* to-do: translate */}
                                                                            {el.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Field>
                                                            ) : (
                                                                <Field
                                                                    fullWidth
                                                                    disabled={edit ? false : true}
                                                                    label={t("Region")}
                                                                    name={`address.region`}
                                                                    component={TextField}
                                                                    variant="standard"
                                                                />
                                                            )}
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item>
                                                        {typeof errors.work === "string" ? <Typography color="error">{errors.work}</Typography> : null}
                                                    </Grid>

                                                    <Grid item container spacing={2} xs={12}>
                                                        <Grid item xs={6} sm={6}>
                                                            <Button variant="contained" color="warning" onClick={() => setEdit((prev) => !prev)}>
                                                                {edit ? <span>{t("cancel Edit")}</span> : <span>{t("Edit")}</span>}
                                                            </Button>
                                                        </Grid>

                                                        <Grid item xs={6} sm={6}>
                                                            <Button
                                                                className="float-end"
                                                                type="submit"
                                                                variant="contained"
                                                                disabled={!edit ? true : false}
                                                                startIcon={isSubmitting ? <CircularProgress size="0.9rem" /> : undefined}
                                                            >
                                                                {isSubmitting ? t("Saving") : t("Save")}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </React.Fragment>
                                            </Grid>
                                        </Form>
                                    )}
                                </Formik>
                            ) : (
                                <div>
                                    <Spin tip="Loading" size="large">
                                        <div className="content" />
                                    </Spin>
                                </div>
                            )}
                        </Box>
                    </Fade>
                </Modal>
            </div>
        </>
    );
};
export default WorkHistory;
