import PerformanceTable from "./PerformanceTable";

const Index = () => {
    return (
        <div className="container">
            <PerformanceTable />
        </div>
    );
};

export default Index;
