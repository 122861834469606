import { Button, Grid } from "@material-ui/core";
import React, { useRef, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import axios from "axios";
import REACT_APP from "../../environment";
import FileSaver from "file-saver";
import Hourglass from "../../assets/svg/Hourglass";
import { useTranslation } from "react-i18next";

const CreateWithCSV = ({ getDate }) => {
    const { t } = useTranslation();
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [zip, setZip] = useState(null);
    const closeRef = useRef(null);
    const inputRef = useRef(null);
    const onchange = async (e) => {
        try {
            if (e.target.files[0]) {
                setFile(e.target.files[0]);
            }
        } catch (error) {
            toast.error(t("Something went wrong"));
        }
    };

    const Submit = async () => {
        setLoading(true);
        inputRef.current.value = null;
        setFile(null);

        const data = new FormData();
        data.append("file", file);
        await axios
            .post(`${REACT_APP.AUTH}/api/hrp/v2/admin/employee/csv`, data, {
                headers: { Authorization: sessionStorage.getItem("token") },
                responseType: "blob",
            })
            .then(async (blob) => {
                if (blob.data.type === "application/zip") {
                    setZip(blob.data);
                } else {
                    toast.success(t("Success"));
                    closeRef.current.click();
                    await getDate();
                }
            })
            .catch(async (err) => {
                toast.error(t("Something went wrong"))
                closeRef.current.click();
            });
        setLoading(false);
    };

    const openModal = () => {
        setFile(null);
        setZip(null);
        inputRef.current.value = null;
        closeRef.current.click();
    };

    const getZip = () => {
        FileSaver.saveAs(zip, "results");
        setZip(null);
        inputRef.current.value = null;
        closeRef.current.click();
        setFile(null);
    };

    return (
        <div className="container">
            {loading && <div className="hrp-overlay"></div>}
            <Button className="float-start" variant="contained" color="primary" data-bs-toggle="modal" data-bs-target="#csv" onClick={openModal}>
                {t("Create Employee via CSV File")}
            </Button>
            <div className="modal fade" id="csv" tabIndex="-1" aria-labelledby="createModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xxl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body p-3">
                            <Grid container direction="column">
                                <h5>{t("Create employees via CSV file")}</h5>
                                <Grid item container spacing={3} xs={12} sm="auto" className="pt-0 mt-0">
                                    <Grid className="pt-4" item xs={12} sm={6}>
                                        <Button variant="outlined" color="primary" style={{ position: "relative" }}>
                                            <input type="file" id="csv-file" ref={inputRef} accept=".csv" onChange={onchange} disabled={!!zip || loading} />
                                            {t("Select file")}
                                        </Button>
                                    </Grid>
                                    <Grid className="pt-4" item xs={12} sm={6}>
                                        {file?.name}
                                    </Grid>
                                    {loading && (
                                        <Grid item xs={12} sm={12}>
                                            <Hourglass />
                                        </Grid>
                                    )}
                                    {zip && (
                                        <>
                                            <Grid item xs={12} sm={6}>
                                                <p className="float-start">{t("Valid and invalid data were found in the file. Download to learn more")}</p>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Button className="float-end" color="primary" type="button" variant="contained" onClick={getZip}>
                                                    {t("Download")}
                                                </Button>
                                            </Grid>
                                        </>
                                    )}
                                    <Grid className="modal-footer mt-3 pt-3" item xs={12} sm={12}>
                                        <Button className="me-3" variant="contained" id="csv-cancel" data-bs-dismiss="modal" color="secondary" ref={closeRef}>
                                            {t("Cancel")}
                                        </Button>
                                        <Button
                                            className="float-end"
                                            disabled={!file || loading}
                                            color="primary"
                                            type="submit"
                                            variant="contained"
                                            onClick={Submit}
                                        >
                                            {loading ? t("Submitting") : t("Submit")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default React.memo(CreateWithCSV);
