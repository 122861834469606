import SkillSearch from "./SkillSearch";

const Index = () => {
    return (
        <div>
            <SkillSearch />
        </div>
    );
};

export default Index;
