import React from "react";
import axios from "axios";
import REACT_APP from "../../../environment";
import Loading from "../../Loading";
import Modal from "./ReviewModal";
import Error from "../../Error";
import { useEffect } from "react";
import { useState } from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const ReviewPage = ({ empId }) => {
    const [data, setData] = useState({});
    const [errorMsg, setErrorMsg] = useState(null);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    const getReview = async () => {
        setLoading(true);
        axios
            .get(`${REACT_APP.URL}/api/hrp/v1/goal/performance_score/${empId}`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setData(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                setErrorMsg(err.message);
                setLoading(false);
            });
    };

    useEffect(() => {
        getReview();
    }, []);

    return loading ? (
        <Loading />
    ) : errorMsg ? (
        <Error message={errorMsg} />
    ) : (
        <div>
            {data.status === "start" && (
                <Button style={{ float: "right" }}>
                    <Modal empId={empId} getReview={getReview} />
                </Button>
            )}
            <h4>
                {data.employee.first_name} {data.employee.last_name}
            </h4>
            {data.status === "not" && t("Employee has not assigned a performance score yet")}
            <div style={{ marginTop: "15px" }}>
                {data.employee_performance_score && (
                    <div>
                        <b style={{ color: "#555" }}>{t("Employee")}:</b>
                        <br />
                        {t("Performance score")}: <b style={{ color: "#555" }}>{t(data.employee_performance_score)}</b>
                        <br />
                        {t("Comment")}: {data.employee_comment}
                    </div>
                )}
            </div>
            <div style={{ marginTop: "15px" }}>
                {data.manager_performance_score && (
                    <div>
                        <b style={{ color: "#555" }}>{t("Manager")}:</b>
                        <br />
                        {t("Performance score")}: <b style={{ color: "#555" }}>{t(data.manager_performance_score)}</b>
                        <br />
                        {t("Comment")}: {data.manager_comment}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ReviewPage;
