import "./index.css";
import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import REACT_APP from "./environment";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error?.response?.data?.errors && error?.response?.data?.errors[0]?.code === "hrp_cmn_auth_003") {
            sessionStorage.clear();
            window.onbeforeunload = {};
            window.location.replace(`${REACT_APP.LOGIN}/login?url=${window.location.href}logout`);
            return 0;
        }
        return Promise.reject(error);
    }
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <>
        <I18nextProvider i18n={i18n}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </I18nextProvider>
    </>
);
