import React from "react";
import Table from "../components/OpenPosition/Table";

const OpenPosition = () => {
    return (
        <>
            <Table />
        </>
    );
};

export default OpenPosition;
