import { Button, CircularProgress, Grid } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import REACT_APP from "../../environment";
import { toast } from "react-toastify";
import axios from "axios";
import regex from "tts-hrp-val-lib";
import { useTranslation } from "react-i18next";

const CreateEmployee = ({ getDate }) => {
    const { t } = useTranslation();
    const [disabled, setDisabled] = useState(true);
    const [disabled2, setDisabled2] = useState(true);
    const [loading, setLoading] = useState(false);
    const [branchPresence, setBranchPresence] = useState(sessionStorage.getItem("branch_presence") === "true");
    const [branchs, setBranchs] = useState([]);
    const [bramch_id, setBranch_id] = useState(undefined);
    const [department, setDepartment] = useState([]);
    const [department_id, setDepartment_id] = useState([]);
    const [position, setPosition] = useState([]);
    const [employment, setEmployment] = useState([]);
    const [reports, setReports] = useState([]);

    const closeRef = useRef(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const getBranch = () => {
        axios(`${REACT_APP.URL}/api/hrp/v1/organization/branches`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => {
                if (!branchPresence) handleChange({ target: { value: res.data.data[0].id } });
                setBranchs(res.data.data);
            })
            .catch((err) => {});
    };

    const getPosition = () => {
        axios(`${REACT_APP.URL}/api/hrp/v1/employee/transfer/positions`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => setPosition(res.data.data))
            .catch((err) => {});
    };

    const getEmployment = () => {
        axios(`${REACT_APP.URL}/api/hrp/v1/types/employment-type`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => setEmployment(res.data.data))
            .catch((err) => {});
    };

    const handleChange = (event) => {
        setDisabled(true);
        setBranch_id(event.target.value);
        axios(`${REACT_APP.URL}/api/hrp/v1/organization/${event.target.value}/departments`, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setDepartment(res.data.data);
                setDisabled(false);
            })
            .catch((err) => {
                setDisabled(true);
            });
    };

    const handleReport = async (event) => {
        setDisabled2(true);
        setDepartment_id(event.target.value);

        // error hisobga olinmagan
        let data1;
        if (event.target.value) {
            data1 = await axios(`${REACT_APP.URL}/api/hrp/v1/organization/reports_to/${event.target.value}`, {
                headers: { authorization: sessionStorage.getItem("token") },
            }).catch((e) => console.log(e));
            setReports(data1.data.data);
        }

        const data2 = await axios(`${REACT_APP.URL}/api/hrp/v1/organization/reports_to`, { headers: { authorization: sessionStorage.getItem("token") } }).catch(
            (e) => console.log(e)
        );
        setReports([...data1.data.data, ...data2.data.data.filter((e) => !data1.data.data.find((i) => i.id === e.id))]);

        // agar head office bo'lsa
        const data3 = await axios(`${REACT_APP.URL}/api/hrp/v1/organization/reports_to/company`, {
            headers: { authorization: sessionStorage.getItem("token") },
        }).catch((e) => console.log(e));
        setReports([
            ...data1.data.data,
            ...data2.data.data.filter((e) => !data1.data.data.find((i) => i.id === e.id)),
            ...data3.data.data.filter((e) => !data1.data.data.find((i) => i.id === e.id) && !data2.data.data.find((i) => i.id === e.id)),
        ]);

        setDisabled2(false);
    };

    const Submit = async (date) => {
        delete date.bramch_id;
        if (!date.middle_name) date.middle_name = undefined;

        setLoading(true);
        axios
            .post(`${REACT_APP.AUTH}/api/hrp/v2/admin/employee`, date, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setLoading(false);
                reset();
                getDate();
                closeRef.current.click();
                toast.success(t("Success"));
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("Something went wrong"));
            });
    };

    useEffect(() => {
        getBranch();
        getPosition();
        getEmployment();
    }, []);

    return (
        <div className="container">
            <Button className="float-start" variant="contained" color="primary" data-bs-toggle="modal" data-bs-target="#createModal">
                {t("Create Employee")}
            </Button>

            <div className="modal fade" id="createModal" tabIndex="-1" aria-labelledby="createModalLabel" aria-hidden="true">
                {loading ? <div className="hrp-overlay"></div> : null}
                <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body p-3">
                            <div className=" flex-column container">
                                <form onSubmit={handleSubmit(Submit)} className="container py-2 example row g-3">
                                    <Grid className="modal-header py-2 px-0">
                                        <h5>{t("Create Employee")}</h5>
                                    </Grid>
                                    <Grid container direction="column">
                                        <Grid item container spacing={3} xs={12} sm="auto" className="pt-2 mt-0">
                                            <Grid item xs={12} sm={4}>
                                                <input
                                                    type="text"
                                                    placeholder={t("First Name")}
                                                    disabled={loading}
                                                    className={errors.first_name ? "is-invalid form-control" : "form-control"}
                                                    {...register("first_name", { required: t("This is required"), pattern: regex.first_name })}
                                                />
                                                {errors.first_name && errors.first_name.type === "required" && (
                                                    <span style={{ color: "red" }}>{t("This is required")}</span>
                                                )}
                                                {errors.first_name && errors.first_name.type === "pattern" && (
                                                    <span style={{ color: "red" }}>{t("This value is invalid")}</span>
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <input
                                                    type="text"
                                                    placeholder={t("Last Name")}
                                                    disabled={loading}
                                                    className={errors.last_name ? "is-invalid form-control" : "form-control"}
                                                    {...register("last_name", { required: true, pattern: regex.last_name })}
                                                />
                                                {errors.last_name && errors.last_name.type === "required" && (
                                                    <span style={{ color: "red" }}>{t("This is required")}</span>
                                                )}
                                                {errors.last_name && errors.last_name.type === "pattern" && (
                                                    <span style={{ color: "red" }}>{t("This value is invalid")}</span>
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <input
                                                    type="text"
                                                    placeholder={t("Middle Name")}
                                                    disabled={loading}
                                                    className={errors.middle_name ? "is-invalid form-control" : "form-control"}
                                                    {...register("middle_name", { pattern: regex.middle_name })}
                                                />
                                                {errors.middle_name && errors.middle_name.type === "pattern" && (
                                                    <span style={{ color: "red" }}>{t("This value is invalid")}</span>
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <label htmlFor="male">
                                                    <input
                                                        type="radio"
                                                        value="male"
                                                        id="male"
                                                        {...register("gender", { required: true })}
                                                        disabled={loading}
                                                        className={errors.gender ? "is-invalid form-check-input me-1" : "form-check-input me-1"}
                                                    />
                                                    {t("Male")}
                                                </label>
                                                <label htmlFor="female" className="ms-3">
                                                    <input
                                                        type="radio"
                                                        value="female"
                                                        id="female"
                                                        {...register("gender", { required: true })}
                                                        disabled={loading}
                                                        className={errors.gender ? "is-invalid form-check-input me-1" : "form-check-input me-1"}
                                                    />
                                                    {t("Female")}
                                                </label>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <input
                                                    type="text"
                                                    placeholder={t("Email")}
                                                    disabled={loading}
                                                    className={errors.email ? "is-invalid form-control" : "form-control"}
                                                    {...register("email", { required: true, pattern: regex.email })}
                                                />
                                                {errors.email && errors.email.type === "required" && (
                                                    <span style={{ color: "red" }}>{t("This is required")}</span>
                                                )}
                                                {errors.email && errors.email.type === "pattern" && (
                                                    <span style={{ color: "red" }}>{t("This value is invalid")}</span>
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <input
                                                    type="text"
                                                    placeholder={t("Cell Phone")}
                                                    disabled={loading}
                                                    className={errors.cell_phone ? "is-invalid form-control" : "form-control"}
                                                    {...register("cell_phone", { required: true, pattern: regex.phone })}
                                                />
                                                {errors.cell_phone && errors.cell_phone.type === "required" && (
                                                    <span style={{ color: "red" }}>{t("This is required")}</span>
                                                )}
                                                {errors.cell_phone && errors.cell_phone.type === "pattern" && (
                                                    <span style={{ color: "red" }}>{t("This value is invalid")}</span>
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <input
                                                    type="text"
                                                    placeholder={t("Birth Date")}
                                                    onFocus={(e) => (e.target.type = "date")}
                                                    {...register("birth_date", {
                                                        required: true,
                                                        max: { value: "2009-12-30", message: t("Birth date must be younger than 2010 year") },
                                                    })}
                                                    disabled={loading}
                                                    className={errors.birth_date ? "is-invalid form-control" : "form-control"}
                                                />
                                                {errors.birth_date && errors.birth_date.type === "required" && (
                                                    <span style={{ color: "red" }}>{t("This is required")}</span>
                                                )}
                                                {errors.birth_date?.message ? (
                                                    <span style={{ fontSize: "13px" }} className="text-danger">
                                                        {errors.birth_date?.message}
                                                    </span>
                                                ) : null}
                                            </Grid>
                                            {branchPresence && (
                                                <Grid item xs={12} sm={4}>
                                                    <select
                                                        value={bramch_id}
                                                        {...register("bramch_id", { required: true, onChange: handleChange })}
                                                        className={errors.bramch_id ? "is-invalid form-select" : "form-select"}
                                                        disabled={loading}
                                                    >
                                                        <option value="">{t("Select Branch")}</option>
                                                        {branchs.map((el) => (
                                                            <option key={el.id} value={el.id}>
                                                                {el.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {errors.bramch_id && errors.bramch_id.type === "required" && (
                                                        <span style={{ color: "red" }}>{t("This is required")}</span>
                                                    )}
                                                </Grid>
                                            )}
                                            <Grid item xs={12} sm={4}>
                                                <select
                                                    value={department_id}
                                                    disabled={disabled | loading}
                                                    {...register("department_id", { required: true, onChange: handleReport })}
                                                    className={errors.department_id ? "is-invalid form-select" : "form-select"}
                                                >
                                                    <option value="">{t("Select Department")}</option>
                                                    {department.map((el) => (
                                                        <option key={el.id} value={el.id}>
                                                            {el.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {errors.department_id && errors.department_id.type === "required" && (
                                                    <span style={{ color: "red" }}>{t("This is required")}</span>
                                                )}
                                            </Grid>

                                            <Grid item xs={12} sm={4}>
                                                <select
                                                    disabled={loading}
                                                    {...register("position", { required: true })}
                                                    className={errors.position ? "is-invalid form-select" : "form-select"}
                                                >
                                                    <option value="">{t("Select Position")}</option>
                                                    {position.map((el) => (
                                                        <option key={el.id} value={el.id}>
                                                            {el.position}
                                                        </option>
                                                    ))}
                                                </select>
                                                {errors.position && errors.position.type === "required" && (
                                                    <span style={{ color: "red" }}>{t("This is required")}</span>
                                                )}
                                            </Grid>

                                            <Grid item xs={12} sm={branchPresence ? 4 : 6}>
                                                <input
                                                    type="number"
                                                    placeholder={t("Annual Salary")}
                                                    disabled={loading}
                                                    className={errors.annual_salary ? "is-invalid form-control" : "form-control"}
                                                    {...register("annual_salary", { required: true })}
                                                />
                                                {errors.annual_salary && errors.annual_salary.type === "required" && (
                                                    <span style={{ color: "red" }}>{t("This is required")}</span>
                                                )}
                                            </Grid>

                                            <Grid item xs={12} sm={branchPresence ? 4 : 6}>
                                                <input
                                                    type="number"
                                                    placeholder={t("Annual Vacation")}
                                                    disabled={loading}
                                                    className={errors.time_off_balance ? "is-invalid form-control" : "form-control"}
                                                    {...register("time_off_balance", { required: true })}
                                                />
                                                {errors.time_off_balance && errors.time_off_balance.type === "required" && (
                                                    <span style={{ color: "red" }}>{t("This is required")}</span>
                                                )}
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <select
                                                    disabled={loading}
                                                    {...register("employment_type", { required: true })}
                                                    className={errors.employment_type ? "is-invalid form-select" : "form-select"}
                                                >
                                                    <option value="">{t("Select employee Arrangement")}</option>
                                                    {employment.map((el) => (
                                                        <option key={el.id} value={el.id}>
                                                            {t(el.name)}
                                                        </option>
                                                    ))}
                                                </select>
                                                {errors.employment_type && errors.employment_type.type === "required" && (
                                                    <span style={{ color: "red" }}>{t("This is required")}</span>
                                                )}
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <select
                                                    disabled={disabled2 | loading}
                                                    {...register("reports_to", { required: true })}
                                                    className={errors.reports_to ? "is-invalid form-select" : "form-select"}
                                                >
                                                    <option value="">{t("Select Reports To")}</option>
                                                    {reports.map((el) => (
                                                        <option key={el.id} value={el.id}>
                                                            {el.first_name} {el.last_name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {errors.reports_to && errors.reports_to.type === "required" && (
                                                    <span style={{ color: "red" }}>{t("This is required")}</span>
                                                )}
                                            </Grid>

                                            <Grid className="modal-footer mt-4 pt-3" item xs={12} sm={12}>
                                                <Button
                                                    className="me-3"
                                                    variant="contained"
                                                    data-bs-dismiss="modal"
                                                    color={loading ? "inherit" : "secondary"}
                                                    ref={closeRef}
                                                    onClick={() => reset()}
                                                >
                                                    {t("Cancel")}
                                                </Button>

                                                <Button
                                                    className="float-end"
                                                    disabled={loading}
                                                    color="primary"
                                                    type="submit"
                                                    variant="contained"
                                                    startIcon={loading && <CircularProgress size="0.9rem" />}
                                                >
                                                    {loading ? t("Saving") : t("Save")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(CreateEmployee);
