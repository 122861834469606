import { FormControls, FormControlWrapper, ListBtn, Wrapper } from "./ListStyle";
import { FormControl, InputLabel, Select, MenuItem, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import REACT_APP from "../../environment";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const List = () => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [employee_id, setEmployee_id] = useState("");
    const [employeeRoles, setEmployeeRoles] = useState([]);
    const [boards, setBoards] = useState([{ id: 1, title: t("All of Roles"), items: [] }]);
    const [boards2, setBoards2] = useState([{ id: 1, title: t("Employee's Roles"), items: [] }]);
    const [currentBoard, setCurrentBoard] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);

    i18next.on("languageChanged", () => {
        setBoards([{ id: 1, title: t("All of Roles"), items: [] }]);
        setBoards2([{ id: 1, title: t("Employee's Roles"), items: [] }]);
    });

    const dragOverHandler = (e) => {
        e.preventDefault();
    };
    const dragLeaveHandler = (e) => {
        e.target.style.boxShadow = "none";
    };

    const dragStartHandler = (e, board, item) => {
        setCurrentBoard(board);
        setCurrentItem(item);
    };

    const DropHandler = (e, board, item) => {
        e.preventDefault();
        const currentIndex = currentBoard.indexOf(currentItem);
        currentBoard.splice(currentIndex, 1);
        setEmployeeRoles([...employeeRoles, currentItem]);
        setBoards2(
            boards.map((b) => {
                if (b.id === board.id) return board;
                if (b.id === currentBoard.id) return currentBoard;
                return b;
            })
        );
    };

    const DropHandler2 = (e, board, item) => {
        e.preventDefault();
        const currentIndex = currentBoard.indexOf(currentItem);
        currentBoard.splice(currentIndex, 1);
        setRoles([...roles, currentItem]);
        setBoards(
            boards.map((b) => {
                if (b.id === board.id) return board;
                if (b.id === currentBoard.id) return currentBoard;
                return b;
            })
        );
    };
    const dragEndHandler = (e) => {
        e.target.style.boxShadow = "none";
    };

    function getRoles() {
        axios
            .get(`${REACT_APP.URL}/api/hrp/v1/types/role`, {
                headers: { authorization: sessionStorage.getItem("token") },
            })
            .then((response) => {
                setData(response.data.data);
                setRoles(response.data.data);
            })
            .catch((err) => {});
    }

    function getEmployee() {
        axios
            .get(`${REACT_APP.URL}/api/hrp/v1/employee/edit/all`, {
                headers: { authorization: sessionStorage.getItem("token") },
            })
            .then((response) => {
                setEmployees(response.data.data);
            })
            .catch((err) => {});
    }

    const handleChange = (e) => {
        setEmployee_id(e.target.value);
        axios
            .get(`${REACT_APP.URL}/api/hrp/v1/employee/role/${e.target.value.id}`, { headers: { authorization: sessionStorage.getItem("token") } })
            .then((response) => {
                setRoles(data.filter((value) => response.data.data.roles.map((e) => e.id).indexOf(value.id) === -1));
                setEmployeeRoles(response.data.data.roles);
            })
            .catch((err) => {
                toast.error(t("Something went wrong"));
                setEmployeeRoles([]);
                getRoles();
            });
    };

    const onSave = () => {
        setLoading(true);
        const data = { employee_id: employee_id.id, roles: [] };
        for (let i = 0; i < employeeRoles.length; i++) {
            data.roles.push({ id: employeeRoles[i].id });
        }

        axios
            .put(`${REACT_APP.URL}/api/hrp/v1/employee/role`, data, { headers: { authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setLoading(false);
                toast.success(t("Success"));
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("Something went wrong"));
            });
    };

    useEffect(() => {
        getRoles();
        getEmployee();
    }, []);

    return (
        <Wrapper>
            {loading && <div className="hrp-overlay"></div>}
            <h4 className="text-center mt-3">{t("Role Assignment")}</h4>
            <FormControlWrapper>
                <FormControls>
                    <FormControl style={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">{t("Select Employee")}</InputLabel>
                        <Select
                            placeholder={t("Select Employee")}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={employee_id}
                            label={t("Select Employee")}
                            onChange={handleChange}
                        >
                            {employees.map((el, index) => (
                                <MenuItem key={index} value={el}>
                                    {el.first_name + " " + el.last_name + " " + (el.middle_name || "")}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </FormControls>
            </FormControlWrapper>
            <div className="DropContainer">
                {boards.map((value) => {
                    return (
                        <div className="Board" key={value.id} onDragOver={(e) => dragOverHandler(e)} onDrop={(e) => DropHandler2(e, roles, 1)}>
                            <div className="Board_title" key={value.id}>
                                {value.title}
                            </div>

                            {roles.map((value) => {
                                return (
                                    <div
                                        className="drop_item"
                                        draggable={true}
                                        key={value.id}
                                        onDragStart={(e) => dragStartHandler(e, roles, value)}
                                        onDragLeave={(e) => dragLeaveHandler(e)}
                                        onDragEnd={(e) => dragEndHandler(e)}
                                    >
                                        {t(value.role)}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}

                {boards2.map((value) => {
                    return (
                        <div className="Board" key={value.id} onDragOver={(e) => dragOverHandler(e)} onDrop={(e) => DropHandler(e, value, 1)}>
                            <div className="Board_title" key={value.id}>
                                {value.title}
                            </div>

                            {employeeRoles.map((el) => {
                                return (
                                    <div
                                        className="drop_item"
                                        draggable={true}
                                        key={el.id}
                                        onDragStart={(e) => dragStartHandler(e, employeeRoles, el)}
                                        onDragLeave={(e) => dragLeaveHandler(e)}
                                        onDragEnd={(e) => dragEndHandler(e)}
                                    >
                                        {t(el.role)}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
            <ListBtn>
                {loading ? (
                    <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">
                        {t("Submit")}
                    </LoadingButton>
                ) : (
                    <Button onClick={onSave} variant="contained" color="success" disabled={employee_id && employeeRoles.length ? false : true}>
                        {t("Submit")}
                    </Button>
                )}
            </ListBtn>
        </Wrapper>
    );
};

export default List;
