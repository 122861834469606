import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, CircularProgress, Grid, MenuItem, Typography } from "@mui/material";
import { Field, FieldArray, Form, Formik } from "formik";
import { workHistorySchema } from "./validateSchema";
import { TextField } from "formik-material-ui";
import axios from "axios";
import REACT_APP from "../../../environment";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function WorkHistory({ next }) {
    const { t } = useTranslation();
    const [id, setId] = useState(useParams().id);
    const [errorMsg, setErrorMsg] = useState(false);
    const [disable, setDisable] = useState(true);
    const [loading, setLoading] = useState(true);
    const [edit, setEdit] = useState(true);
    const [work, setWork] = useState([]);

    useEffect(() => {
        getWork();
    }, []);

    const getWork = async () => {
        setLoading(true);
        setErrorMsg(false);
        axios(`${REACT_APP.ONB_URL}/api/hrp/v2/onb/adm/${id}/workhistory`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => {
                setWork(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                setErrorMsg(true);
                setLoading(false);
            });
    };

    const submit = async (values) => {
        setDisable(true);
        await values.work?.filter((el) => {
            if (!el.email) el.email = undefined;
            if (!el.website) el.website = undefined;
            if (!el.address.zip_code) el.address.zip_code = undefined;
            if (!el.address.address2) el.address.address2 = undefined;
        });

        axios
            .put(`${REACT_APP.ONB_URL}/api/hrp/v2/onb/adm/${id}/workhistory`, values.work, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setDisable(true);
                next();
            })
            .catch((err) => {
                setErrorMsg(true);
                setDisable(false);
            });
    };

    return (
        <div className="d-flex justify-content-center flex-column container py-3">
            <div className="py-2">
                <div className="card">
                    <div className="card-header">
                        <h5 className="text-center" style={disable ? { color: "#aaa" } : {}}>
                            {t("Work History")}
                        </h5>
                    </div>
                    <div>
                        {loading ? (
                            <div className="w-100 all__center py-5 my-5">
                                <CircularProgress />
                            </div>
                        ) : errorMsg ? (
                            <div className="w-100 all__center py-5 my-5">
                                <p style={{ color: "#ff0000", fontWeight: "550" }}>{t("An error occurred")}</p>
                            </div>
                        ) : work.length !== 0 ? (
                            <Card>
                                <CardContent style={{ padding: "24px" }}>
                                    <Formik
                                        initialValues={{ work: work }}
                                        validationSchema={workHistorySchema}
                                        onSubmit={async (values) => {
                                            submit(values);
                                            return new Promise((res) => setTimeout(res, 500));
                                        }}
                                    >
                                        {({ values, errors, isSubmitting }) => (
                                            <Form autoComplete="off">
                                                <Grid container direction="column" spacing={2}>
                                                    <FieldArray name="work">
                                                        {({ push, remove }) => (
                                                            <React.Fragment>
                                                                {values.work?.map((_, index) => (
                                                                    <Grid container item key={index} spacing={2}>
                                                                        <Grid item container spacing={2} xs={12}>
                                                                            <Grid item xs={12} style={index === 0 ? { display: "none" } : { display: "block" }}>
                                                                                <p
                                                                                    className="text-center mt-3"
                                                                                    style={
                                                                                        disable ? { fontWeight: "500", color: "#aaa" } : { fontWeight: "500" }
                                                                                    }
                                                                                >
                                                                                    {t("Work History")} {index + 1}
                                                                                </p>
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`work[${index}].name`}
                                                                                    pattern={"[0-9]"}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Name")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`work[${index}].position`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Position")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`work[${index}].phone`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Company phone")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`work[${index}].website`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Company website")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`work[${index}].email`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Company email")}
                                                                                />
                                                                            </Grid>

                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`work[${index}].supervisor.name`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Supervisor name")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`work[${index}].supervisor.position`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Supervisor position")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`work[${index}].supervisor.phone`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Supervisor phone")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`work[${index}].supervisor.email`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Supervisor email")}
                                                                                />
                                                                            </Grid>

                                                                            <Grid item xs={12} sm={6}>
                                                                                <label className="mat_label">{t("From date")}</label>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    type="date"
                                                                                    name={`work[${index}].start_date`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    format="dd/MM/yyyy"
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={6}>
                                                                                <label className="mat_label">{t("To date")}</label>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    type="date"
                                                                                    name={`work[${index}].end_date`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    format="dd/MM/yyyy"
                                                                                />
                                                                            </Grid>

                                                                            <Grid item xs={12}>
                                                                                <p className="text-center mt-3" style={disable ? { color: "#aaa" } : {}}>
                                                                                    {t("References")}
                                                                                </p>
                                                                            </Grid>

                                                                            {_.references?.map((_, idx) => (
                                                                                <Grid container item key={idx} spacing={2}>
                                                                                    <Grid item container spacing={2} xs={12}>
                                                                                        <Grid item xs={12} sm={3}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                name={`work[${index}].references[${idx}].name`}
                                                                                                component={TextField}
                                                                                                variant="standard"
                                                                                                label={t("Full Name")}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={3}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                name={`work[${index}].references[${idx}].position`}
                                                                                                component={TextField}
                                                                                                variant="standard"
                                                                                                label={t("Position")}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={3}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                name={`work[${index}].references[${idx}].phone`}
                                                                                                component={TextField}
                                                                                                variant="standard"
                                                                                                label={t("Phone")}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={3}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                name={`work[${index}].references[${idx}].email`}
                                                                                                component={TextField}
                                                                                                variant="standard"
                                                                                                label={t("Email")}
                                                                                            />
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            ))}

                                                                            <Grid item xs={12}>
                                                                                <p className="text-center mt-3" style={disable ? { color: "#aaa" } : {}}>
                                                                                    {t("Address")}
                                                                                </p>
                                                                            </Grid>

                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`work[${index}].address.address1`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Address 1")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`work[${index}].address.address2`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Address 2")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`work[${index}].address.city`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("City")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`work[${index}].address.zip_code`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Zipcode")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    select
                                                                                    name={`work[${index}].address.country`}
                                                                                    label={t("Country")}
                                                                                >
                                                                                    {/* to-do: API */}
                                                                                    <MenuItem value="uz">Uz</MenuItem>
                                                                                    <MenuItem value="value2">KZ</MenuItem>
                                                                                    <MenuItem value="new value3">USA</MenuItem>
                                                                                </Field>
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    select
                                                                                    name={`work[${index}].address.region`}
                                                                                    label={t("Region")}
                                                                                >
                                                                                    {/* to-do: API */}
                                                                                    <MenuItem value="tosh">Tashkent</MenuItem>
                                                                                    <MenuItem value="value2">Namangan</MenuItem>
                                                                                </Field>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                ))}

                                                                <Grid item>
                                                                    {typeof errors.work === "string" ? (
                                                                        <Typography color="error">{errors.work}</Typography>
                                                                    ) : null}
                                                                </Grid>
                                                            </React.Fragment>
                                                        )}
                                                    </FieldArray>
                                                </Grid>
                                            </Form>
                                        )}
                                    </Formik>
                                </CardContent>
                            </Card>
                        ) : (
                            <div className="w-100 all__center py-5 my-5">
                                <p style={{ color: "#1976d2" }}>{t("Work histories are empty")}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
