import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Field, Form, Formik } from "formik";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";
import REACT_APP from "../../../environment";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";
import * as Yup from "yup";
import axios from "axios";
import regex from "tts-hrp-val-lib";
import { useTranslation } from "react-i18next";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

function TabModal({ empId, getReview }) {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [scores, setScores] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    const validate = Yup.object({
        performance_score_id: Yup.string().required(t("field is required")),
        comment: Yup.string()
            .required(t("field is required"))
            .matches(regex.description, { message: t("invalid value") }),
    });

    const getScores = async () => {
        await axios(`${REACT_APP.URL}/api/hrp/v1/goal/performance_score`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => setScores(res.data.data))
            .catch((err) => console.log(err));
    };

    const onSubmit = async (values) => {
        values.employee_id = empId;
        setLoading(true);
        await axios
            .post(`${REACT_APP.URL}/api/hrp/v1/goal/performance_score`, values, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setLoading(false);
                toast.success(t("Success"));
                handleClose();
                getReview();
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("Something went wrong"));
            });
    };

    useEffect(() => {
        getScores();
    }, []);

    return (
        <div>
            {loading && <div className="hrp-overlay"></div>}
            <Button onClick={handleOpen}>{t("setting performance score")}</Button>
            <Modal
                open={open}
                slots={{ backdrop: Backdrop }}
                onClose={handleClose}
                closeAfterTransition
                aria-labelledby="transition-modal-title"
                slotProps={{ backdrop: { timeout: 500 } }}
                aria-describedby="transition-modal-description"
            >
                <Fade in={open}>
                    <Box sx={style} className="addcompanymodal">
                        <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                            {t("Enter value")}
                        </Typography>
                        <Formik
                            initialValues={{ performance_score_id: "", comment: "" }}
                            validationSchema={validate}
                            onSubmit={async (values) => onSubmit(values)}
                        >
                            {({ errors, handleBlur, handleChange }) => (
                                <Form autoComplete="off" className="mt-4">
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Grid item xs={12} sm={12} className="pt-0">
                                                <label className="form-label">{t("Performance score")}</label>
                                                <select
                                                    name="performance_score_id"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={`form-control ${errors.performance_score_id ? "is-invalid" : ""}`}
                                                >
                                                    <option value="">{t("Select performance score")}</option>
                                                    {scores.map((el) => (
                                                        <option key={el.id} value={el.id}>
                                                            {el.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {errors.performance_score_id && <div className="err_comment">{errors.performance_score_id}</div>}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <label className="ms-2 mb-1" style={errors.comment && { color: "#d32f2f" }}>
                                                {t("Comment")}
                                            </label>
                                            <Field
                                                as="textarea"
                                                label={"Score"}
                                                name="comment"
                                                rows="3"
                                                className={`form-control ${errors.comment ? "is-invalid" : ""}`}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.comment && <div className="err_comment">{errors.comment}</div>}
                                        </Grid>
                                    </Grid>
                                    <div className="mt-5">
                                        <Button onClick={handleClose} variant="contained" style={{ backgroundColor: "#d32f2f", float: "left" }}>
                                            {t("Close")}
                                        </Button>
                                        <Button type="submit" variant="contained" color="primary" style={{ float: "right" }}>
                                            {t("Submit")}
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default React.memo(TabModal);
