import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useState } from "react";
import ModalCalendar from "./ModalCalendar";
import REACT_APP from "../../environment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { toast } from "react-toastify";
import axios from "axios";
import { useEffect } from "react";
import "./Calendar.css";
import "moment/locale/uz-latn";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { useTranslation } from "react-i18next";

let localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const BigCalendar = () => {
    const [events, setEvents] = useState([]);
    const [modalStatus, setModalStatus] = useState(false);
    const [eventInput, setEventInput] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [eventId, setEventId] = useState("");
    const [editStatus, setEditStatus] = useState(false);
    const { t, i18n } = useTranslation();

    const handleClose = () => {
        setModalStatus(false);
        setEditStatus(false);
        setEventInput("");
    };

    const handleChange = (e) => {
        setEventInput(e.target.value);
    };

    const handleSave = async () => {
        if (eventInput) {
            const body = { title: `${eventInput}`, start: new Date(`${startDate}`), end: new Date(`${endDate}`) };
            await axios
                .post(`${REACT_APP.URL}/api/hrp/v1/company/big-calendar`, body, { headers: { Authorization: sessionStorage.getItem("token") } })
                .then(() => {
                    setModalStatus(false);
                    getData();
                })
                .catch(() => toast.error(t("Something went wrong")));
        }
    };

    const handleSlotSelectEvent = (slotInfo) => {
        setStartDate(new Date(`${slotInfo.start}`));
        setEndDate(new Date(`${slotInfo.end}`));
        setModalStatus(true);
        setEventInput("");
    };

    const moveEventHandler = ({ event, start, end }) => {
        if (event.editable === true) {
            const body = { id: `${event.id}`, title: `${event.title.props.children[0]}`, start: new Date(`${start}`), end: new Date(`${end}`) };
            axios
                .put(`${REACT_APP.URL}/api/hrp/v1/company/big-calendar`, body, { headers: { Authorization: sessionStorage.getItem("token") } })
                .then(() => {
                    setModalStatus(false);
                    getData();
                })
                .catch(() => toast.error(t("Something went wrong")));
        }
    };

    const resizeEventHandler = ({ event, start, end }) => {
        if (event.editable === true) {
            const body = { id: `${event.id}`, title: `${event.title.props.children[0]}`, start: new Date(`${start}`), end: new Date(`${end}`) };
            axios
                .put(`${REACT_APP.URL}/api/hrp/v1/company/big-calendar`, body, { headers: { Authorization: sessionStorage.getItem("token") } })
                .then(() => {
                    setModalStatus(false);
                    getData();
                })
                .catch(() => toast.error(t("Something went wrong")));
        }
    };

    const hanldeOnSelectEvent = (e) => {
        if (e.editable === true) {
            setEditStatus(true);
            setStartDate(new Date(`${e.start}`));
            setEndDate(new Date(`${e.end}`));
            setEventInput(e.title.props.children[0]);
            setEventId(e.id);
            setModalStatus(true);
        }
    };

    const handleEditEvent = (e) => {
        setEventInput(e.target.value);
    };

    const handleEdited = async (e) => {
        if (eventInput) {
            const body = {
                id: `${eventId}`,
                title: `${eventInput}`,
                start: new Date(`${startDate}`),
                end: new Date(`${endDate}`),
            };

            await axios
                .put(`${REACT_APP.URL}/api/hrp/v1/company/big-calendar`, body, { headers: { Authorization: sessionStorage.getItem("token") } })
                .then((res) => {
                    setModalStatus(false);
                    getData();
                })
                .catch((err) => toast.error(t("Something went wrong")));
        }

        setEditStatus(false);
        setEventInput("");
    };

    const handleDelete = () => {
        axios
            .delete(`${REACT_APP.URL}/api/hrp/v1/company/big-calendar`, {
                data: { id: `${eventId}` },
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then(() => {
                setModalStatus(false);
                setEventInput("");
                getData();
            })
            .catch(() => toast.error(t("Something went wrong")));
    };

    const getData = async () => {
        await axios(`${REACT_APP.URL}/api/hrp/v1/company/big-calendar`, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                res.data.data?.forEach((el) => {
                    el.start = new Date(el.start);
                    el.end = new Date(el.end);
                    el.tooltip = el.title;
                    el.title = (
                        <p style={{ height: "10px" }}>
                            {el.title} <span id="f_name">{el.first_name?.slice(0, 1) + "." + el.last_name}</span>
                        </p>
                    );
                });
                setEvents(res.data.data);
            })
            .catch(() => toast.error(t("Something went wrong")));
    };

    i18n.on("languageChanged", () => {
        moment.locale(t("big-clalendar-locale"));
        localizer = momentLocalizer(moment);
    });

    useEffect(() => {
        moment.locale(t("big-clalendar-locale"));
        localizer = momentLocalizer(moment);
        getData();
    }, []);

    return (
        <div id="big-calendar">
            <div className="my-calendar">
                <DnDCalendar
                    localizer={localizer}
                    events={events}
                    tooltipAccessor={"tooltip"}
                    startAccessor="start"
                    endAccessor="end"
                    selectable
                    onSelectSlot={handleSlotSelectEvent}
                    onSelectEvent={hanldeOnSelectEvent}
                    onEventDrop={moveEventHandler}
                    resizable
                    onEventResize={resizeEventHandler}
                    longPressThreshold={10}
                    messages={{
                        next: t("next"),
                        previous: t("previous"),
                        today: t("today"),
                        month: t("month"),
                        week: t("week"),
                        day: t("day"),
                        january: t("january"),
                        allDay: t("allDay"),
                        agenda: t("agenda"),
                        date: t("date"),
                        time: t("time"),
                        event: t("event"),
                        tomorrow: t("tomorrow"),
                        yesterday: t("yesterday"),
                        work_week: t("work_week"),
                        noEventsInRange: t("noEventsInRange"),
                    }}
                    eventPropGetter={() => ({
                        style: {
                            backgroundColor: "rgba(80, 130, 180, 0.3)",
                            color: "black",
                            borderLeft: "3px solid rgb(80, 130, 180)",
                            borderRadius: "0",
                            fontFamily: "monospace",
                        },
                    })}
                />
                <ModalCalendar
                    modalStatus={modalStatus}
                    handleClose={handleClose}
                    handleSave={handleSave}
                    handleChange={handleChange}
                    startDate={startDate}
                    endDate={endDate}
                    eventInput={eventInput}
                    handleEditEvent={handleEditEvent}
                    handleEdited={handleEdited}
                    editStatus={editStatus}
                    handleDelete={handleDelete}
                />
            </div>
        </div>
    );
};

export default BigCalendar;
