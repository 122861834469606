import { Alert } from "antd";
import "./css/error.css";
import { useTranslation } from "react-i18next";

export default function Datatable({ message }) {
    console.log(message);
    const { t } = useTranslation();
    return (
        <div className="container py-5">
            <Alert message={t("Something went wrong")} type="error" showIcon />
        </div>
    );
}
