import "./TimeOff.css";
import React, { useRef } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useTranslation } from "react-i18next";

const ViewItem = ({ item, name }) => {
    const closeRef = useRef(null);
    const { t } = useTranslation();

    return (
        <div className="container ">
            <div className="modal fade" id="viewItem" tabIndex="-1" aria-labelledby="viewItemLabel" aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <article className="modal-content view_item">
                        <span className="cencel" ref={closeRef} data-bs-dismiss="modal">
                            <ClearIcon id="clear" />
                        </span>
                        <header>
                            <h3>{name}</h3>
                        </header>
                        <main className="leaderboard__profiles">
                            {item.length === 0
                                ? "empty"
                                : item.map((el) => (
                                      <div className="card d-flex flex-row px-4 py-2" key={el.id}>
                                          <div className="datestamp small">
                                              <span className="datestamp-month badge">{new Date(el.date).toLocaleString("en-us", { month: "long" })}</span>
                                              <span className="datestamp-day py-1">{new Date(el.date).getDate()}</span>
                                              <span className="datestamp-year badge text-muted border-top border-grey">{new Date(el.date).getFullYear()}</span>
                                          </div>
                                          <div className="date-body border-left ms-5">
                                              <h4 className="font-weight-bold">
                                                  {t(name)}
                                                  <span className="hours">
                                                      {el.hour}
                                                      {t("short-hour")}
                                                  </span>
                                              </h4>
                                              <div className="date-time mb-1">
                                                  <AccessTimeIcon className="mb-1 me-1" fontSize="small" />
                                                  {el.date?.slice(0, 10)}
                                              </div>
                                              {/* <div className="date-time mb-1"><AccessTimeIcon className="mb-1 me-1" fontSize="small"/> 9:00 dan {10+el.hour}:00 gacha</div> */}
                                              {/* <div className="date-time mb-1">{el.date?.slice(0, 10)} to {el.date?.slice(0, 10)}</div> */}
                                          </div>
                                      </div>
                                  ))}
                        </main>
                    </article>
                </div>
            </div>
        </div>
    );
};

export default React.memo(ViewItem);
