import * as React from "react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { Button } from "@material-ui/core";
import Box from "@mui/material/Box";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import REACT_APP from "../../environment";
import FolderIcon from "@mui/icons-material/Folder";
import Error from "../Error";
import { Spin } from "antd";
import { useEffect } from "react";

import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const DevGoalComments = () => {
    const { t } = useTranslation();
    const [errorMsg, setErrorMsg] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [goal, setGoal] = React.useState(null);
    const params = useParams();
    const navigate = useNavigate();
    const [comment, setComment] = React.useState([]);
    const [commentLoading, setCommentLoading] = React.useState(false);

    const getData = async () => {
        try {
            setLoading(true);
            const headers = { Authorization: sessionStorage.getItem("token") };
            const res = await axios(`${REACT_APP.URL}/api/hrp/v1/goal/development/${params.id}`, { headers });
            const elem = res?.data?.data || {};
            console.log(res?.data?.data);
            setGoal(elem);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };
    const gotoPerformance = () => navigate(`/performance`);
    const gotoGoals = () => navigate(`/review/${goal.employee_id}`);

    const getComment = () => {
        setCommentLoading(true);
        axios(`${REACT_APP.URL}/api/hrp/v1/goal/development/${params.id}/comment`, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setComment(res.data.data);
                setCommentLoading(false);
            })
            .catch((err) => {
                setComment([]);
                setCommentLoading(false);
                toast.error(t("Something went wrong"));
            });
    };

    useEffect(() => {
        getData();
        getComment();
    }, []);

    return (
        <>
            <div className="tabs_box">
                <Box>
                    <Button onClick={gotoPerformance}>
                        <ArrowLeftIcon /> {t("Performance")}
                    </Button>

                    <Button onClick={goal ? gotoGoals : gotoPerformance}>
                        <ArrowLeftIcon /> {goal ? `${goal.first_name} ${goal.last_name}` : t("Back")}
                    </Button>
                    <br />
                    {loading ? (
                        <Spin style={{ marginTop: "65px" }} tip="Loading" size="large">
                            <div className="content" />
                        </Spin>
                    ) : errorMsg ? (
                        <Error message={errorMsg} />
                    ) : (
                        <div>
                            <List className="core_list" style={{ marginTop: "20px" }}>
                                <Divider />
                                <ListItem className="list_item">
                                    <ListItemAvatar>
                                        <Avatar>
                                            <FolderIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={goal?.goal_name} />
                                    <ListItemText primary={goal?.short_description} />
                                    <ListItemText primary={goal?.status} />
                                </ListItem>
                            </List>

                            <List className="core_list" style={{ marginTop: "20px" }}>
                                {comment.map((el) => (
                                    <>
                                        <Divider />
                                        <ListItem key={el.id} className="list_item">
                                            <p>{el.text}</p>
                                        </ListItem>
                                    </>
                                ))}
                            </List>
                        </div>
                    )}
                </Box>
            </div>
        </>
    );
};

export default DevGoalComments;
