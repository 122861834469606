import RequestsTable from "../components/BGD/Requests/Requests";

const Requests = () => {
    return (
        <div className="container">
            <RequestsTable />
        </div>
    );
};

export default Requests;
