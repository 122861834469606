import React from "react";
import EmployeesList from "./EmployeesList";

const Employees = () => {
    return (
        <div className="container">
            <EmployeesList />
        </div>
    );
};

export default Employees;
