import React, { useEffect, useState } from "react";
import { Card, CardContent, CircularProgress, Grid, MenuItem, Typography } from "@mui/material";
import { Field, FieldArray, Form, Formik } from "formik";
import REACT_APP from "../../../environment";
import { useParams } from "react-router-dom";
import { contactSchema } from "./validateSchema";
import { TextField } from "formik-material-ui";
import { toast } from "react-toastify";
import axios from "axios";
import { useTranslation } from "react-i18next";

export default function Contact({ next }) {
    const { t } = useTranslation();
    const [edit, setEdit] = useState(true);
    const [id, setId] = useState(useParams().id);
    const [errorMsg, setErrorMsg] = useState(false);

    const [disable, setDisable] = useState(true);
    const [loading, setLoading] = useState(true);
    const [contact, setContact] = useState([]);

    useEffect(() => {
        getContact();
    }, []);

    const getContact = () => {
        setLoading(true);
        axios(`${REACT_APP.ONB_URL}/api/hrp/v2/onb/adm/${id}/emergency_contacts`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => {
                setContact(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                setErrorMsg(true);
                setLoading(false);
            });
    };

    const submit = (values) => {
        axios
            .put(`${REACT_APP.ONB_URL}/api/hrp/v2/onb/adm/${id}/emergency_contacts`, values.contacts, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setDisable(true);
                next();
            })
            .catch((err) => toast.error(t("Something went wrong")));
    };

    return (
        <div className="d-flex justify-content-center flex-column container py-3">
            <div className="py-2">
                <div className="card">
                    <div className="card-header">
                        <h5 className="text-center" style={disable ? { color: "#aaa" } : {}}>
                            {t("Contacts")}
                        </h5>
                    </div>
                    <div>
                        {loading ? (
                            <div className="w-100 all__center py-5 my-5">
                                <CircularProgress />
                            </div>
                        ) : errorMsg ? (
                            <div className="w-100 all__center py-5 my-5">
                                <p style={{ color: "#ff0000", fontWeight: "550" }}>{t("An error occurred")}</p>
                            </div>
                        ) : contact.length !== 0 ? (
                            <Card>
                                <CardContent style={{ padding: "24px" }}>
                                    <Formik
                                        initialValues={{ contacts: contact }}
                                        validationSchema={contactSchema}
                                        onSubmit={async (values) => {
                                            submit(values);
                                            return new Promise((res) => setTimeout(res, 100));
                                        }}
                                    >
                                        {({ values, errors, isSubmitting }) => (
                                            <Form autoComplete="off">
                                                <Grid container direction="column" spacing={2}>
                                                    <FieldArray name="contacts">
                                                        {({ push, remove }) => (
                                                            <React.Fragment>
                                                                {values.contacts?.map((_, index) => (
                                                                    <Grid container item key={index} spacing={2}>
                                                                        <Grid item container spacing={2} xs={12}>
                                                                            <Grid item xs={12} style={index === 0 ? { display: "none" } : { display: "block" }}>
                                                                                <p
                                                                                    className="text-center mt-2"
                                                                                    style={
                                                                                        disable ? { fontWeight: "500", color: "#aaa" } : { fontWeight: "500" }
                                                                                    }
                                                                                >
                                                                                    {t("Contact")} {index + 1}
                                                                                </p>
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`contacts[${index}].first_name`}
                                                                                    pattern={"[0-9]"}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("First Name")}
                                                                                    required
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`contacts[${index}].last_name`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Last Name")}
                                                                                    required
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`contacts[${index}].email`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Email")}
                                                                                    required
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={6}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`contacts[${index}].phone`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Phone")}
                                                                                    required
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={6}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    select
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    required
                                                                                    name={`contacts[${index}].type_of_relationship`}
                                                                                    label={t("Relationship")}
                                                                                >
                                                                                    {/* to-do: translate */}
                                                                                    <MenuItem value="1">father</MenuItem>
                                                                                    <MenuItem value="2">mother</MenuItem>
                                                                                    <MenuItem value="3">brother</MenuItem>
                                                                                </Field>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                ))}

                                                                <Grid item>
                                                                    {typeof errors.contacts === "string" ? (
                                                                        <Typography color="error">{errors.contacts}</Typography>
                                                                    ) : null}
                                                                </Grid>
                                                            </React.Fragment>
                                                        )}
                                                    </FieldArray>
                                                </Grid>
                                            </Form>
                                        )}
                                    </Formik>
                                </CardContent>
                            </Card>
                        ) : (
                            <div className="w-100 all__center py-5 my-5">
                                <p style={{ color: "#1976d2" }}>{t("Contacts are empty")}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
