import React from "react";
import { Empty } from "antd";
import { useTranslation } from "react-i18next";

const Emptye = () => {
    const { t } = useTranslation();
    return (
        <div className="py-3">
            <Empty description={t("No data")} />
        </div>
    );
};

export default Emptye;
