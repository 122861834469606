import PositionTable from "./PositionTable";

const Index = () => {
    return (
        <div>
            <PositionTable />
        </div>
    );
};

export default Index;
