import { t } from "i18next";
import regex from "tts-hrp-val-lib";
import { array, boolean, number, object, string } from "yup";
import * as Yup from "yup";

export const validateSignIn = Yup.object({
    email: Yup.string().email(t("invalid value")).required(t("field is required")),
    password: Yup.string().min(6, t("invalid value")).required(t("field is required")),
});

export const validateSignUp = Yup.object({
    email: Yup.string().email(t("invalid value")).required(t("field is required")),
    password: Yup.string().min(6, t("invalid value")).required(t("field is required")),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], t("invalid value"))
        .required(t("field is required")),
});

// ---- ---- ---- PERSONAL ---- ---- ---- //

export const personalSchema = object({
    first_name: string()
        .matches(regex.first_name, { message: t("invalid value") })
        .required(t("field is required")),
    last_name: string()
        .matches(regex.last_name, { message: t("invalid value") })
        .required(t("field is required")),
    middle_name: string()
        .matches(regex.middle_name, { message: t("invalid value") })
        .nullable(),
    birth_date: string().required(t("field is required")),
    tin: string()
        .matches(regex.TIN, { message: t("invalid value") })
        .nullable(),

    gender: string().required(t("field is required")),
    nationality: string()
        .matches(regex.nationality, { message: t("invalid value") })
        .nullable(),
    citizenship: string()
        .matches(regex.citizenship, { message: t("invalid value") })
        .required(t("field is required")),
    home_phone: string()
        .matches(regex.phone, { message: t("invalid value") })
        .required(t("field is required")),
    cell_phone: string()
        .matches(regex.phone, { message: t("invalid value") })
        .required(t("field is required")),

    birth_place: object({
        country: string().required(t("field is required")),
        address1: string()
            .matches(regex.address1, { message: t("invalid value") })
            .required(t("field is required")),
        address2: string()
            .matches(regex.address2, { message: t("invalid value") })
            .nullable(),
        city: string()
            .matches(regex.city, { message: t("invalid value") })
            .required(t("field is required")),
        region: string().required(t("field is required")),
        zip_code: string()
            .matches(regex.zip_code, { message: t("invalid value") })
            .nullable(),
    }),

    addresses: array(
        object({
            country: string().required(t("field is required")),
            address1: string()
                .matches(regex.address1, { message: t("invalid value") })
                .required(t("field is required")),
            address2: string()
                .matches(regex.address2, { message: t("invalid value") })
                .nullable(),
            city: string()
                .matches(regex.city, { message: t("invalid value") })
                .required(t("field is required")),
            region: string().required(t("field is required")),
            zip_code: string()
                .matches(regex.zip_code, { message: t("invalid value") })
                .nullable(),
            current: string().required(t("field is required")),
            from_date: Yup.date().max(new Date(), t("invalid value")).typeError(t("invalid value")).required(t("field is required")),
            to_date: Yup.date()
                .nullable()
                .when("current", (current, field) => (current === "false" ? field.required(t("field is required")) : field))
                .when("from_date", (from_date, yup) => from_date && yup.min(from_date, t("invalid value"))),
        })
    ).min(1, "You need to provide at least 1 address"),

    dependency: array(
        object({
            first_name: string()
                .matches(regex.first_name, { message: t("invalid value") })
                .required(t("field is required")),
            last_name: string()
                .matches(regex.last_name, { message: t("invalid value") })
                .required(t("field is required")),
            middle_name: string()
                .matches(regex.middle_name, { message: t("invalid value") })
                .nullable(),
            birth_date: string().required(t("field is required")),
            tin: string()
                .matches(regex.TIN, { message: t("invalid value") })
                .nullable(),
            type_of_relationship: string().required(t("field is required")),
        })
    ).min(1, "You need to provide at least 1 dependency"),
});

// ---- ---- ---- WORK HISTORY ---- ---- ---- //

export const workHistorySchema = object({
    work: array(
        object({
            name: string()
                .matches(regex.company_name, { message: t("invalid value") })
                .required(t("field is required")),
            position: string()
                .matches(regex.work_position, { message: t("invalid value") })
                .required(t("field is required")),
            start_date: Yup.date().max(new Date(), t("invalid value")).typeError(t("invalid value")).required(t("field is required")),
            end_date: Yup.date()
                .required(t("field is required"))
                .when("start_date", (start_date, yup) => start_date && yup.min(start_date, t("invalid value"))),
            email: string().email(t("invalid value")).nullable(),
            phone: string()
                .matches(regex.phone, { message: t("invalid value") })
                .required(t("field is required")),
            website: string()
                .matches(regex.website, { message: t("invalid value") })
                .nullable(),

            supervisor: object({
                name: string()
                    .matches(regex.full_name, { message: t("invalid value") })
                    .required(t("field is required")),
                position: string()
                    .matches(regex.position, { message: t("invalid value") })
                    .required(t("field is required")),
                phone: string()
                    .matches(regex.phone, { message: t("invalid value") })
                    .required(t("field is required")),
                email: string().required(t("field is required")).email(t("invalid value")),
            }),

            references: array(
                object({
                    name: string()
                        .matches(regex.full_name, { message: t("invalid value") })
                        .required(t("field is required")),
                    position: string()
                        .matches(regex.work_position, { message: t("invalid value") })
                        .required(t("field is required")),
                    phone: string()
                        .matches(regex.phone, { message: t("invalid value") })
                        .required(t("field is required")),
                    email: string().required(t("field is required")).email(t("invalid value")),
                })
            ),

            address: object({
                country: string().required(t("field is required")),
                address1: string()
                    .matches(regex.address1, { message: t("invalid value") })
                    .required(t("field is required")),
                address2: string()
                    .matches(regex.address2, { message: t("invalid value") })
                    .nullable(),
                city: string()
                    .matches(regex.city, { message: t("invalid value") })
                    .required(t("field is required")),
                region: string().required(t("field is required")),
                zip_code: string()
                    .matches(regex.zip_code, { message: t("invalid value") })
                    .nullable(),
            }),
        })
    ).min(1, "You need to provide at least 1 Work history"),
});

// ---- ---- ---- EDUCATION ---- ---- ---- //

export const educationSchema = object({
    educations: array(
        object({
            university_id: string().required(t("field is required")),
            major: string()
                .matches(regex.major, { message: t("invalid value") })
                .required(t("field is required")),
            is_complete: boolean().required(),
            years_of_edu: number().required(t("field is required")),
            degree_type: string().required(t("field is required")),
            start_date: Yup.date().max(new Date(), t("invalid value")).typeError(t("invalid value")).required(t("field is required")),
            end_date: Yup.date()
                .when("is_complete", (val, schema) => {
                    if (val === true) return Yup.date().required(t("field is required"));
                    else return Yup.string().notRequired();
                })
                .when("start_date", (start_date, yup) => start_date && yup.min(start_date, t("invalid value"))),
            diploma: string()
                .when("is_complete", (is_complete, field) => (is_complete ? field.required(t("field is required")) : field))
                .nullable(),
        })
    ).min(1, "You need to provide at least 1 education"),
});

// ---- ---- ---- CONTACT ---- ---- ---- //

export const contactSchema = object({
    contacts: array(
        object({
            first_name: string()
                .matches(regex.first_name, { message: t("invalid value") })
                .required(t("field is required")),
            last_name: string()
                .matches(regex.last_name, { message: t("invalid value") })
                .required(t("field is required")),
            email: string().required(t("field is required")).email(t("invalid value")),
            phone: string()
                .matches(regex.phone, { message: t("invalid value") })
                .required(t("field is required")),
            type_of_relationship: string().required(t("field is required")),
        })
    ).min(1, "You need to provide at least 1 contact"),
});
