import { Button, CircularProgress, Grid } from "@material-ui/core";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import { toast } from "react-toastify";
import REACT_APP from "../../environment";
import { useEffect } from "react";
import axios from "axios";
import Loading from "../Loading";
import { useTranslation } from "react-i18next";

const SetAnnualSalary = () => {
    const { t } = useTranslation();
    const params = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [payTypes, setPayTypes] = useState([]);
    const [employee, setEmployee] = useState(null);
    const [firstloading, setFirstLoading] = useState(true);
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const getPayType = async () => {
        await axios(`${REACT_APP.URL}/api/hrp/v1/payroll/pay_types`, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => setPayTypes(res.data.data))
            .catch((err) => {});
    };

    const getEmployee = async () => {
        await axios(`${REACT_APP.URL}/api/hrp/v1/employee/edit/${params.id}`, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => setEmployee(res.data.data))
            .catch((err) => {});
    };

    const Submit = async (date) => {
        setLoading(true);
        date.employee_id = params.id;
        await axios
            .put(`${REACT_APP.URL}/api/hrp/v1/payroll/employee/rate`, date, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setLoading(false);
                toast.success(t("Success"));
                reset();
                navigate("/hr/salary");
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("Something went wrong"));
            });
    };

    const getAll = async () => {
        setFirstLoading(true);
        await getEmployee();
        await getPayType();
        setFirstLoading(false);
    };

    useEffect(() => {
        getAll();
    }, []);

    return (
        <>
            {firstloading ? (
                <Loading />
            ) : (
                <div className="container py-4">
                    <form onSubmit={handleSubmit(Submit)} className="py-2 row g-3">
                        <Grid className="pb-2">
                            <h5>
                                {employee?.first_name + " " + employee?.last_name} &nbsp; / &nbsp; {t("Set Annual Salary")}
                            </h5>
                        </Grid>
                        <Grid container direction="column" spacing={2}>
                            <Grid item container spacing={3} xs={12} sm="auto" className="pt-4 mt-0">
                                <Grid item xs={12} sm={6} className="pt-0">
                                    <label className="form-label">{t("Annual salary")}</label>
                                    <input
                                        disabled={loading}
                                        type="number"
                                        {...register("annual_salary", { required: true })}
                                        className={`form-control ${errors.annual_salary ? "is-invalid" : ""}`}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} className="pt-0">
                                    <label className="form-label">{t("Percentage")}</label>
                                    <input
                                        disabled={loading}
                                        type="number"
                                        step="0.01"
                                        {...register("percentage", { required: true, min: 0, max: 100 })}
                                        className={`form-control ${errors.percentage ? "is-invalid" : ""}`}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} className="pt-0">
                                    <label className="form-label">{t("Pay Type")}</label>
                                    <select
                                        className={`form-control ${errors.pay_type ? "is-invalid" : ""}`}
                                        disabled={loading}
                                        {...register("pay_type", { required: true })}
                                    >
                                        <option value="">{t("Select pay type")}</option>
                                        {payTypes.map((el) => (
                                            <option key={el.id} value={el.id}>
                                                {t(el.type)}
                                            </option>
                                        ))}
                                    </select>
                                </Grid>

                                <Grid className="mt-4 pt-3" item xs={12} sm={12}>
                                    <Button
                                        className="float-end"
                                        disabled={loading}
                                        color="primary"
                                        type="submit"
                                        variant="contained"
                                        startIcon={loading && <CircularProgress size="0.9rem" />}
                                    >
                                        {loading ? t("Saving") : t("Save")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            )}
        </>
    );
};

export default React.memo(SetAnnualSalary);
