import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import { useTranslation } from "react-i18next";
import { Paper } from "@mui/material";

const headCells = [
    { id: "name", numeric: true, label: "First Name", align: "left" },
    { id: "email", numeric: true, label: "Email", align: "left" },
    { id: "phone", numeric: true, label: "Phone", align: "left" },
    { id: "position", numeric: true, label: "Position", align: "left" },
];

function EnhancedTableHead(props) {
    const { t } = useTranslation();
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align={headCell.align} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {t(headCell.label)}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function ProviderContactTable({ contacts }) {
    const { t } = useTranslation();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    return (
        <div style={{ marginBottom: "24px" }}>
            {contacts?.length > 0 ? (
                <div>
                    <div style={{ paddingLeft: "16px" }}>
                        <h4 className="text-center text-sm-start">{t("Contacts")}</h4>
                    </div>
                    <Paper sx={{ width: "100%", bgcolor: "whitesmoke" }}>
                        <TableContainer>
                            <Table sx={{ minWidth: 750, px: 2 }} aria-labelledby="tableTitle" size="medium">
                                <EnhancedTableHead order={order} onRequestSort={handleRequestSort} orderBy={orderBy} rowCount={contacts.length} />
                                <TableBody>
                                    {contacts.map((row) => {
                                        return (
                                            <TableRow hover tabIndex={-1} key={row.id}>
                                                <TableCell align="left">{row.name}</TableCell>
                                                <TableCell align="left">{row.email}</TableCell>
                                                <TableCell align="left">{row.phone}</TableCell>
                                                <TableCell align="left">{row.position}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </div>
            ) : (
                <div style={{ paddingLeft: "16px" }}>
                    <h5>{t("No contacts available")}</h5>
                </div>
            )}
        </div>
    );
}

export default React.memo(ProviderContactTable);
