import * as React from "react";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import Button from "@mui/material/Button";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import WorkHistory from "./WorkHistory";
import Education from "./Education";
import Document from "./Document";
import Personal from "./Personal";
import Contact from "./Contact";
import Skill from "./Skill";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function OnboardingStepper() {
    const { t } = useTranslation();
    const steps = [t("Personal"), t("Work History"), t("Education"), t("Contacts"), t("Documents"), t("Skills")];
    const [activeStep, setActiveStep] = React.useState(0);
    const [components, setComponents] = React.useState([]);
    const [skipped, setSkipped] = React.useState(new Set());
    const navigate = useNavigate();

    React.useEffect(() => {
        setComponents([
            <Personal next={handleNext} />,
            <WorkHistory next={handleNext} />,
            <Education next={handleNext} />,
            <Contact next={handleNext} />,
            <Document next={handleNext} />,
            <Skill next={handleNext} />,
        ]);
    }, []);

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    return (
        <Box sx={{ width: "100%" }} className="container py-5">
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    labelProps.optional = <Typography variant="caption">{t("Optional")}</Typography>;
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{ my: 20, color: "success.main", fontWeight: "bold", fontSize: "18px" }} className="text-center">
                        {t("All steps completed - you&apos;re finished")}
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button
                            variant="contained"
                            onClick={() => {
                                navigate(`/onboarded`);
                            }}
                        >
                            {t("Go to Onboarded")}
                        </Button>
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>{components[activeStep]}</Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2, px: 1.5 }}>
                        <Button color="inherit" variant="contained" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                            {t("Back")}
                        </Button>

                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button color="inherit" variant="contained" onClick={handleSkip}>
                            {t("Skip")}
                        </Button>
                    </Box>
                </React.Fragment>
            )}
        </Box>
    );
}
