import { Button, CircularProgress } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";
import axios from "axios";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import REACT_APP from "../../environment";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

const Days = () => {
    const { t } = useTranslation();
    const qr = document.querySelector.bind(document);
    const qrAll = document.querySelectorAll.bind(document);

    const [loadingSpin, setLoadingSpin] = useState(true);
    const [loading, setLoading] = useState(false);
    const [listErr, setListErr] = useState(false);
    const [disable, setDisable] = useState(false);
    const [day, setDay] = useState(null);
    const [arr, setArr] = useState(null);
    const closeRef = useRef(null);
    const divRef = useRef(null);

    const [expanded, setExpanded] = React.useState(false);
    const handleChanged = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const Submit = async () => {
        const checked = [...qrAll(".qry")].some((el) => el.checked === true);
        if (!checked) {
            setListErr(true);
            return;
        }

        const body = {
            first_day: 1,
            monday: qr("#monday").checked,
            tuesday: qr("#tuesday").checked,
            wednesday: qr("#wednesday").checked,
            thursday: qr("#thursday").checked,
            friday: qr("#friday").checked,
            saturday: qr("#saturday").checked,
            sunday: qr("#sunday").checked,
        };

        setLoading(true);
        arr
            ? axios
                  .put(`${REACT_APP.URL}/api/hrp/v1/holiday/days`, body, { headers: { Authorization: sessionStorage.getItem("token") } })
                  .then((res) => {
                      setLoading(false);
                      closeRef.current.click();
                      toast.success(t("Success"));
                      getDays();
                  })
                  .catch((err) => {
                      setLoading(false);
                      toast.error(t("Something went wrong"));
                  })
            : axios
                  .post(`${REACT_APP.URL}/api/hrp/v1/holiday/days`, body, { headers: { Authorization: sessionStorage.getItem("token") } })
                  .then((res) => {
                      setLoading(false);
                      closeRef.current.click();
                      toast.success(t("Success"));
                      getDays();
                  })
                  .catch((err) => {
                      setLoading(false);
                      toast.error(t("Something went wrong"));
                  });
    };

    const handleChange = () => {
        const checked = [...qrAll(".qry")].some((el) => el.checked === true);
        if (checked) setListErr(false);
        else setListErr(true);
    };

    const getDays = () => {
        setLoadingSpin(false);
        axios(`${REACT_APP.URL}/api/hrp/v1/holiday/days`, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setDisable(true);
                setArr(Object.keys(res.data?.data).slice(3));
                setDay(res.data.data);
                setLoadingSpin(false);
            })
            .catch((err) => {
                setLoadingSpin(false);
                setDisable(false);
                toast.error(t("Something went wrong"));
            });
    };

    useEffect(() => {
        getDays();
    }, []);

    return (
        <div className="container">
            <Button className="float-start" variant="contained" color="primary" data-bs-toggle="modal" data-bs-target="#daysModal">
                {t("Work days")}
            </Button>

            <div className="modal fade" id="daysModal" tabIndex="-1" aria-labelledby="daysModalLabel" aria-hidden="true">
                {loading ? <div className="hrp-overlay"></div> : null}
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body p-3">
                            <div className=" flex-column container">
                                <form className="container">
                                    <Grid className="modal-header py-2 px-0">
                                        <h5>{t("Work days")}</h5>
                                    </Grid>
                                    <Grid container direction="column">
                                        <Grid item container spacing={2} xs={12} sm="auto" className="pt-4 mt-0">
                                            <Grid item xs={12} sm={12}>
                                                <Accordion expanded={expanded === "panel1"} onChange={handleChanged("panel1")}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1bh-content"
                                                        id="panel1bh-header"
                                                        className="d-flex px-3"
                                                    >
                                                        <Typography sx={{ width: "100%", flexShrink: 0 }}>{t("Select week days")}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        {loadingSpin ? (
                                                            loading
                                                        ) : (
                                                            <div className="d-grid" ref={divRef} onChange={handleChange}>
                                                                {[
                                                                    t("monday"),
                                                                    t("tuesday"),
                                                                    t("wednesday"),
                                                                    t("thursday"),
                                                                    t("friday"),
                                                                    t("saturday"),
                                                                    t("sunday"),
                                                                ].map((el) => (
                                                                    <div className="form-check form-check-inline" key={el}>
                                                                        {arr?.includes(el) ? (
                                                                            <input
                                                                                className="qry form-check-input"
                                                                                disabled={disable}
                                                                                type="checkbox"
                                                                                defaultChecked={day[el]}
                                                                                id={el}
                                                                            />
                                                                        ) : (
                                                                            <input
                                                                                className="qry form-check-input"
                                                                                disabled={disable}
                                                                                type="checkbox"
                                                                                id={el}
                                                                            />
                                                                        )}
                                                                        <label className="form-check-label" htmlFor={el}>
                                                                            {el}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </AccordionDetails>
                                                </Accordion>
                                                {listErr ? <p className="error_lbl">{t("Field Required!")}</p> : null}
                                            </Grid>
                                            <Grid className="modal-footer mt-5 pt-3 px-0" item xs={12} sm={12}>
                                                <Button
                                                    className="me-3"
                                                    data-bs-dismiss="modal"
                                                    variant="contained"
                                                    color={loading ? "inherit" : "secondary"}
                                                    ref={closeRef}
                                                >
                                                    {t("Cancel")}
                                                </Button>
                                                {disable ? (
                                                    <Button className="float-end" onClick={() => setDisable(false)} variant="contained" color="primary">
                                                        {t("Edit")}
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        className="float-end"
                                                        disabled={loading}
                                                        onClick={Submit}
                                                        variant="contained"
                                                        color="primary"
                                                        startIcon={loading && <CircularProgress size="0.9rem" />}
                                                    >
                                                        {disable ? t("Saving") : t("Save")}
                                                    </Button>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(Days);
