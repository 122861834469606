import { Spin } from "antd";
import "./loading.css";
export default function Loading() {
    return (
        <div id="body">
            <Spin tip="Loading" size="large">
                <div className="content" />
            </Spin>
        </div>
    );
}
