import React from "react";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const ViewModal = ({ data }) => {
    const { t } = useTranslation();

    return (
        <div className="modal fade" id="viewModal" tabIndex="-1" aria-labelledby="viewModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body p-2">
                        <div className=" flex-column container mb-3 mt-2">
                            <div className="main-body">
                                <div className="w-100 d-flex justify-content-end mt-0 mb-2">
                                    <button data-bs-dismiss="modal" className="btn btn-outline-danger btn-sm">
                                        <CloseIcon />
                                    </button>
                                </div>
                                <div className="row gutters-sm">
                                    <div className="col-md-4 mb-3">
                                        <div className="card">
                                            <div className="card-body py-4">
                                                <div className="d-flex flex-column align-items-center text-center">
                                                    <AccountBalanceIcon className="pr_icon" />
                                                    <div className="mt-3">
                                                        <h3 className="text-secondary">{data.name}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="card mb-3">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <h6 className="mb-0">{t("Name")}</h6>
                                                    </div>
                                                    <div className="col-sm-9 text-secondary">{data.name}</div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <h6 className="mb-0">{t("Country")}</h6>
                                                    </div>
                                                    <div className="col-sm-9 text-secondary">{data.branch_address?.country}</div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <h6 className="mb-0">{t("City")}</h6>
                                                    </div>
                                                    <div className="col-sm-9 text-secondary">{data.branch_address?.city}</div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <h6 className="mb-0">{t("Region")}</h6>
                                                    </div>
                                                    <div className="col-sm-9 text-secondary">{data.branch_address?.region}</div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <h6 className="mb-0">{t("Address 1")}</h6>
                                                    </div>
                                                    <div className="col-sm-9 text-secondary">{data.branch_address?.address1}</div>
                                                </div>
                                                {!data.branch_address?.address2 ? null : (
                                                    <>
                                                        <hr />
                                                        <div className="row">
                                                            <div className="col-sm-3">
                                                                <h6 className="mb-0">{t("Address 2")}</h6>
                                                            </div>
                                                            <div className="col-sm-9 text-secondary">{data.branch_address?.address2}</div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(ViewModal);
