import React, { useEffect, useState } from "react";
import { Card, CardContent, CircularProgress, FormControlLabel, FormLabel, Grid, MenuItem, Radio, Typography } from "@mui/material";
import { RadioGroup, TextField } from "formik-material-ui";
import { Field, FieldArray, Form, Formik } from "formik";
import { personalSchema } from "./validateSchema";
import REACT_APP from "../../../environment";
import { toast } from "react-toastify";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Personal({ next }) {
    const { t } = useTranslation();
    const [edit, setEdit] = useState(true);
    const [id, setId] = useState(useParams().id);
    const [errorMsg, setErrorMsg] = useState(false);
    const [disable, setDisable] = useState(true);
    const [loading, setLoading] = useState(true);
    const [personal, setPersonal] = useState([]);

    useEffect(() => {
        getPersonal();
    }, []);

    const getPersonal = () => {
        setLoading(true);
        axios(`${REACT_APP.ONB_URL}/api/hrp/v2/onb/adm/${id}/personal`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => {
                setPersonal(res.data.data.data);
                setLoading(false);
            })
            .catch((err) => {
                setErrorMsg(true);
                setLoading(false);
            });
    };

    const submit = (values) => {
        if (!values.middle_name) values.middle_name = null;
        if (!values.tin) values.tin = null;
        if (!values.nationality) values.nationality = null;
        if (!values.birth_place.address2) values.birth_place.address2 = null;
        if (!values.birth_place.zip_code) values.birth_place.zip_code = null;

        values.dependency.filter((el) => {
            if (!el.tin) el.tin = null;
            if (!el.middle_name) el.middle_name = null;
        });
        values.addresses.filter((el) => {
            if (!el.address2) el.address2 = null;
            if (!el.zip_code) el.zip_code = null;
            if (el.current) el.to_date = null;
        });

        setDisable(true);
        axios
            .put(`${REACT_APP.ONB_URL}/api/hrp/v2/onb/adm/${id}/personal`, values, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setDisable(true);
                next();
            })
            .catch((err) => {
                setDisable(false);
                toast.error(t("Something went wrong"));
            });
    };

    return (
        <>
            {
                <div className="d-flex justify-content-center flex-column container py-3">
                    <div className="py-2">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="text-center" style={disable ? { color: "#aaa" } : {}}>
                                    {t("Personal")}
                                </h5>
                            </div>
                            <div>
                                {loading ? (
                                    <div className="w-100 all__center py-5 my-5">
                                        <CircularProgress />
                                    </div>
                                ) : errorMsg ? (
                                    <div className="w-100 all__center py-5 my-5">
                                        <p style={{ color: "#ff0000", fontWeight: "550" }}>{t("An error occurred")}</p>
                                    </div>
                                ) : personal ? (
                                    <Card>
                                        <CardContent style={{ padding: "24px" }}>
                                            <Formik
                                                initialValues={personal}
                                                validationSchema={personalSchema}
                                                onSubmit={async (values) => {
                                                    submit(values);
                                                    return new Promise((res) => setTimeout(res, 100));
                                                }}
                                            >
                                                {({ values, errors, isSubmitting }) => (
                                                    <Form autoComplete="off">
                                                        <Grid container direction="column" spacing={2}>
                                                            <Grid item container spacing={2} xs={12}>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled={disable}
                                                                        name="first_name"
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label={t("First Name")}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled={disable}
                                                                        name="last_name"
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label={t("Last Name")}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled={disable}
                                                                        name="middle_name"
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label={t("Middle Name")}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <label className="mat_label">{t("Birth Date")}</label>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled={disable}
                                                                        type="date"
                                                                        name="birth_date"
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        format="dd/mm/yyyy"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled={disable}
                                                                        name="tin"
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label={t("TIN")}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled={disable}
                                                                        name="nationality"
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label={t("Nationality")}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} sm={3}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled={disable}
                                                                        name="citizenship"
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label={t("Citizenship")}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={3}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled={disable}
                                                                        name="home_phone"
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label={t("Home phone")}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={3}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled={disable}
                                                                        name="cell_phone"
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label={t("Cell phone")}
                                                                    />
                                                                </Grid>
                                                                <Field component={RadioGroup} name="gender" className="ps-sm-3 col-sm-3 mt-2">
                                                                    <FormLabel component="legend">{t("Gender")}</FormLabel>
                                                                    <Grid>
                                                                        <FormControlLabel
                                                                            value="male"
                                                                            label={t("male")}
                                                                            control={<Radio disabled={isSubmitting} />}
                                                                            disabled={disable}
                                                                        />
                                                                        <FormControlLabel
                                                                            value="female"
                                                                            label={t("female")}
                                                                            control={<Radio disabled={isSubmitting} />}
                                                                            disabled={disable}
                                                                        />
                                                                    </Grid>
                                                                </Field>

                                                                <Grid item xs={12}>
                                                                    <p className="text-center mt-3" style={disable ? { color: "#aaa" } : {}}>
                                                                        {t("Birth place")}
                                                                    </p>
                                                                </Grid>

                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled={disable}
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        select
                                                                        name="birth_place.country"
                                                                        label={t("Select country")}
                                                                    >
                                                                        {/* to-do: API */}
                                                                        <MenuItem value="value1">value1</MenuItem>
                                                                        <MenuItem value="value2">value2</MenuItem>
                                                                    </Field>
                                                                </Grid>

                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled={disable}
                                                                        name="birth_place.address1"
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label={t("Address 1")}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled={disable}
                                                                        name="birth_place.address2"
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label={t("Address 2")}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled={disable}
                                                                        name="birth_place.city"
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label={t("City")}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled={disable}
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        select
                                                                        name="birth_place.region"
                                                                        label={t("Select Region")}
                                                                    >
                                                                        {/* to-do: API */}
                                                                        <MenuItem value="value1">value1</MenuItem>
                                                                        <MenuItem value="value2">value2</MenuItem>
                                                                    </Field>
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled={disable}
                                                                        name="birth_place.zip_code"
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label={t("Zip code")}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12}>
                                                                    <p className="text-center mt-3" style={disable ? { color: "#aaa" } : {}}>
                                                                        {t("Addresses")}
                                                                    </p>
                                                                </Grid>

                                                                <FieldArray name="addresses">
                                                                    {({ push, remove }) => (
                                                                        <React.Fragment>
                                                                            {values.addresses?.map((address, index) => (
                                                                                <Grid container item key={index} spacing={2}>
                                                                                    <Grid item container spacing={2} xs={12}>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                name={`addresses[${index}].address1`}
                                                                                                component={TextField}
                                                                                                variant="standard"
                                                                                                label={t("Address 1")}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                name={`addresses[${index}].address2`}
                                                                                                component={TextField}
                                                                                                variant="standard"
                                                                                                label={t("Address 2")}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                name={`addresses[${index}].city`}
                                                                                                component={TextField}
                                                                                                variant="standard"
                                                                                                label={t("City")}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                component={TextField}
                                                                                                variant="standard"
                                                                                                select
                                                                                                name={`addresses[${index}].region`}
                                                                                                label={t("Select Region")}
                                                                                            >
                                                                                                {/* to-do: API */}
                                                                                                <MenuItem value="value1">value1</MenuItem>
                                                                                                <MenuItem value="value2">value2</MenuItem>
                                                                                            </Field>
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                name={`addresses[${index}].zip_code`}
                                                                                                component={TextField}
                                                                                                variant="standard"
                                                                                                label={t("Zip Code")}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                component={TextField}
                                                                                                variant="standard"
                                                                                                select
                                                                                                name={`addresses[${index}].country`}
                                                                                                label={t("Select country")}
                                                                                            >
                                                                                                {/* to-do: API */}
                                                                                                <MenuItem value="value1">value1</MenuItem>
                                                                                                <MenuItem value="value2">value2</MenuItem>
                                                                                            </Field>
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <Field
                                                                                                select
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                name={`addresses[${index}].current`}
                                                                                                component={TextField}
                                                                                                variant="standard"
                                                                                                label={t("Current")}
                                                                                            >
                                                                                                <MenuItem value={true}>{t("true")}</MenuItem>
                                                                                                <MenuItem value={false}>{t("false")}</MenuItem>
                                                                                            </Field>
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <label className="mat_label">{t("From date")}</label>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                type="date"
                                                                                                name={`addresses[${index}].from_date`}
                                                                                                component={TextField}
                                                                                                format="dd/mm/yyyy"
                                                                                                variant="standard"
                                                                                            />
                                                                                        </Grid>
                                                                                        {values.addresses[index].current === false && (
                                                                                            <Grid item xs={12} sm={4}>
                                                                                                <label className="mat_label">{t("To date")}</label>
                                                                                                <Field
                                                                                                    fullWidth
                                                                                                    disabled={disable}
                                                                                                    type="date"
                                                                                                    name={`addresses[${index}].to_date`}
                                                                                                    component={TextField}
                                                                                                    format="dd/mm/yyyy"
                                                                                                    variant="standard"
                                                                                                />
                                                                                            </Grid>
                                                                                        )}
                                                                                    </Grid>
                                                                                </Grid>
                                                                            ))}

                                                                            <Grid item>
                                                                                {typeof errors.addresses === "string" ? (
                                                                                    <Typography color="error">{errors.addresses}</Typography>
                                                                                ) : null}
                                                                            </Grid>
                                                                        </React.Fragment>
                                                                    )}
                                                                </FieldArray>

                                                                <Grid item xs={12}>
                                                                    <p className="text-center mt-3" style={disable ? { color: "#aaa" } : {}}>
                                                                        {t("Dependencies")}
                                                                    </p>
                                                                </Grid>

                                                                <FieldArray name="dependency">
                                                                    {({ push, remove }) => (
                                                                        <React.Fragment>
                                                                            {values.dependency?.map((_, index) => (
                                                                                <Grid container item key={index} spacing={2}>
                                                                                    <Grid item container spacing={2} xs={12}>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                name={`dependency[${index}].first_name`}
                                                                                                component={TextField}
                                                                                                variant="standard"
                                                                                                label={t("First Name")}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                name={`dependency[${index}].last_name`}
                                                                                                component={TextField}
                                                                                                variant="standard"
                                                                                                label={t("Last Name")}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                name={`dependency[${index}].middle_name`}
                                                                                                component={TextField}
                                                                                                variant="standard"
                                                                                                label={t("Middle Name")}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <label className="mat_label">{t("Birth Date")}</label>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                type="date"
                                                                                                format="dd/mm/yyyy"
                                                                                                name={`dependency[${index}].birth_date`}
                                                                                                component={TextField}
                                                                                                variant="standard"
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                name={`dependency[${index}].tin`}
                                                                                                component={TextField}
                                                                                                variant="standard"
                                                                                                label={t("TIN")}
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={4}>
                                                                                            <Field
                                                                                                fullWidth
                                                                                                disabled={disable}
                                                                                                name={`dependency[${index}].type_of_relationship`}
                                                                                                variant="standard"
                                                                                                select
                                                                                                component={TextField}
                                                                                                label={t("Relationship")}
                                                                                            >
                                                                                                {/* to-do: translate */}
                                                                                                <MenuItem value="1">Father</MenuItem>
                                                                                                <MenuItem value="2">Mother</MenuItem>
                                                                                                <MenuItem value="tog'a">Brother</MenuItem>
                                                                                            </Field>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            ))}

                                                                            <Grid item className="p-0 m-0">
                                                                                {typeof errors.dependency === "string" ? (
                                                                                    <Typography color="error">{errors.dependency}</Typography>
                                                                                ) : null}
                                                                            </Grid>
                                                                        </React.Fragment>
                                                                    )}
                                                                </FieldArray>
                                                            </Grid>
                                                        </Grid>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </CardContent>
                                    </Card>
                                ) : (
                                    <div className="w-100 all__center py-5 my-5">
                                        <p style={{ color: "#1976d2" }}>{t("Personal Field is Empty")}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}
