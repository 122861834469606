import React from "react";
import BillsIndex from "../components/BGD/Bills/Bills";

function Bills() {
    return (
        <div>
            <BillsIndex />
        </div>
    );
}

export default Bills;
