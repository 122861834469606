import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Empty } from "antd";

export default function Skills({ data }) {
    const [loading, setLoading] = useState(true);
    const [beginner, setBeginner] = useState([]);
    const [advanced, setAdvanced] = useState([]);
    const [medium, setMedium] = useState([]);
    const [proficient, setProficient] = useState([]);
    const [expert, setExpert] = useState([]);

    useEffect(() => {
        setLoading(true);
        data.forEach((el) => {
            if (el.level === "beginner") setBeginner((prev) => [...prev, el]);
            if (el.level === "advanced beginner") setAdvanced((prev) => [...prev, el]);
            if (el.level === "medium") setMedium((prev) => [...prev, el]);
            if (el.level === "proficient") setProficient((prev) => [...prev, el]);
            if (el.level === "expert") setExpert((prev) => [...prev, el]);
        });
        setLoading(false);
    }, []);

    return (
        <div className="d-flex justify-content-center flex-column container my-2">
            <div id="revSkills" className="row g-3">
                <div className="">
                    <div className="card form1">
                        <div className="card-header">
                            <h5 className="text-center" style={{ color: "#aaa" }}>
                                Skills
                            </h5>
                        </div>
                        <div className="card-body py-3 mb-4">
                            {loading ? (
                                <div className="w-100 all__center py-5 my-5">
                                    <CircularProgress />
                                </div>
                            ) : data.length === 0 ? (
                                <Empty />
                            ) : (
                                <div className="form-group row g-3">
                                    {beginner.length !== 0 && (
                                        <div className="col-md-6 my-4">
                                            <label className="form-label skill_lbl">Beginner</label>
                                            <div className="card-brd w-100 h-100 p-2">
                                                {beginner.map((el, i) => (
                                                    <span key={i} className="text-success m-1 rounded float-start d-flex align-items-center">
                                                        {el.name}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    {advanced.length !== 0 && (
                                        <div className="col-md-6 my-4">
                                            <label className="form-label skill_lbl">Advanced</label>
                                            <div className="card-brd w-100 h-100 p-2">
                                                {advanced.map((el, i) => (
                                                    <span key={i} className="text-success m-1 rounded float-start d-flex align-items-center">
                                                        {el.name}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    {medium.length !== 0 && (
                                        <div className="col-md-6 my-4">
                                            <label className="form-label skill_lbl">Medium</label>
                                            <div className="card-brd w-100 h-100 p-2">
                                                {medium.map((el, i) => (
                                                    <span key={i} className="text-success m-1 rounded float-start d-flex align-items-center">
                                                        {el.name}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    {proficient.length !== 0 && (
                                        <div className="col-md-6 my-4">
                                            <label className="form-label skill_lbl">Proficient</label>
                                            <div className="card-brd w-100 h-100 p-2">
                                                {proficient.map((el, i) => (
                                                    <span key={i} className="text-success m-1 rounded float-start d-flex align-items-center">
                                                        {el.name}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    {expert.length !== 0 && (
                                        <div className="col-md-6 my-4">
                                            <label className="form-label skill_lbl">Expert</label>
                                            <div className="card-brd w-100 h-100 p-2">
                                                {expert.map((el, i) => (
                                                    <span key={i} className="text-success m-1 rounded float-start d-flex align-items-center">
                                                        {el.name}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
