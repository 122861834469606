import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import Review from "./Settings.jsx";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function DepartmentTabs() {
    const { t } = useTranslation();
    const params = useParams();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();

    const handleChange = (event, newValue) => setValue(newValue);
    const back = () => navigate(`/department`);

    return (
        <div className="tabs_box">
            <Box>
                <Button onClick={back}>
                    <ArrowLeftIcon /> {t("Departments")}
                </Button>
                <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%", marginTop: "10px" }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" orientation={isSmallScreen ? "vertical" : "horizontal"}>
                        <Tab label={t("Settings")} {...a11yProps(0)} />
                        {/* <Tab label="development" {...a11yProps(1)} />
                        <Tab label="core" {...a11yProps(2)} /> */}
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Review id={params.id} />
                </TabPanel>
                {/* <TabPanel value={value} index={1}>
                    <DevelopmentGoals empId={params.id} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <CoreGoals empId={params.id} />
                </TabPanel> */}
            </Box>
        </div>
    );
}

export default React.memo(DepartmentTabs);
