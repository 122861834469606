import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { visuallyHidden } from "@mui/utils";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import REACT_APP from "../../environment";
import LinearProgress from "@mui/material/LinearProgress";
import AddPosition from "./AddPosition";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";

import ApplicantTable from "../Aplicants/Table";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: "id", numeric: false, label: "Id" },
    { id: "title", numeric: true, label: "Job title" },
    { id: "creation_date", numeric: true, label: "Created date" },
    { id: "expiration_date", numeric: true, label: "Expiration date" },
    { id: "status", numeric: true, label: "Status" },
    { id: "type", numeric: true, label: "Type" },
    { id: "action", numeric: true, label: "Action" },
];

function EnhancedTableHead(props) {
    const { t } = useTranslation();
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align="left" sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {t(headCell.label)}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [page, setPage] = React.useState(0);
    const [data, setData] = React.useState([]);
    const [idx, setIdx] = React.useState("");
    const [loading, setLoading] = React.useState(true);
    const [errorMsg, setErrorMsg] = React.useState(null);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [applicants, setApplicants] = React.useState([]);
    const { t } = useTranslation();
    const [show, setShow] = React.useState(false);

    React.useEffect(() => {
        getDate();
    }, []);

    const getDate = async () => {
        setErrorMsg(null);
        setLoading(true);
        axios
            .get(`${REACT_APP.URL}/api/hrp/v1/employee/openPosition`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setData(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                setErrorMsg(err.message);
                setLoading(false);
            });
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [anchor, setAnchor] = React.useState(null);
    const [anchor2, setAnchor2] = React.useState(null);
    const [anchor3, setAnchor3] = React.useState(null);

    const openMenu = (event, id) => {
        setIdx(id);
        setAnchor(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchor(null);
    };

    const changeType = () => {
        axios
            .put(
                `${REACT_APP.URL}/api/hrp/v1/employee/openPositionType/${idx.id}`,
                {},
                {
                    headers: { Authorization: sessionStorage.getItem("token") },
                }
            )
            .then((res) => {
                setAnchor2(null);
                setAnchor(null);
                getDate();
            })
            .catch((err) => toast.error(t("Something went wrong")));
    };

    const closeStatus = () => {
        axios
            .put(
                `${REACT_APP.URL}/api/hrp/v1/employee/openPositionStatus/close/${idx.id}`,
                {},
                {
                    headers: { Authorization: sessionStorage.getItem("token") },
                }
            )
            .then((res) => {
                setAnchor3(null);
                setAnchor(null);
                getDate();
            })
            .catch((err) => toast.error(t("Something went wrong")));
    };

    const submitStatus = () => {
        axios
            .put(
                `${REACT_APP.URL}/api/hrp/v1/employee/openPositionStatus/submit/${idx.id}`,
                {},
                {
                    headers: { Authorization: sessionStorage.getItem("token") },
                }
            )
            .then((res) => {
                setAnchor3(null);
                setAnchor(null);
                getDate();
            })
            .catch((err) => toast.error(t("Something went wrong")));
    };

    const deleteStatus = () => {
        axios
            .delete(`${REACT_APP.URL}/api/hrp/v1/employee/openPositionStatus/delete/${idx.id}`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setAnchor3(null);
                setAnchor(null);
                getDate();
            })
            .catch((err) => toast.error(t("Something went wrong")));
    };

    const getApplicant = () => {
        setApplicants([]);
        axios(`${REACT_APP.URL}/api/hrp/v1/employee/applicant/openPositionBy/${idx.id}`, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setAnchor(null);
                setApplicants(res.data.data);
            })
            .catch((err) => {
                setAnchor(null);
                toast.error(t("Something went wrong"))
            });
    };

    return (
        <>
            {loading ? (
                <Loading />
            ) : errorMsg ? (
                <Error message={errorMsg} />
            ) : (
                <div className="container py-5 op__position">
                    {show && <div className="hrp-overlay"></div>}
                    <h4 className="text-center pb-3">{t("Open positions")}</h4>
                    <Box sx={{ width: "100%" }}>
                        <Paper sx={{ width: "100%", mb: 2 }}>
                            <TableContainer>
                                <Table sx={{ minWidth: 750, px: 2 }} aria-labelledby="tableTitle" size="medium">
                                    <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={data.length} />
                                    {!loading && (
                                        <TableBody>
                                            {stableSort(data, getComparator(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row, index) => {
                                                    return (
                                                        <TableRow hover tabIndex={-1} key={row.id}>
                                                            <TableCell align="left">{row.id}</TableCell>
                                                            <TableCell align="left">{row.name}</TableCell>
                                                            <TableCell align="left">{row.created_date?.slice(0, 10)}</TableCell>
                                                            <TableCell align="left">{row.expiration_date?.slice(0, 10)}</TableCell>
                                                            <TableCell align="left">{t(row.status)}</TableCell>
                                                            <TableCell align="left">{t(row.type)}</TableCell>
                                                            <TableCell align="left" className="op_menu">
                                                                <Box sx={{ flexGrow: 0 }}>
                                                                    <Tooltip title={t("Action")}>
                                                                        <IconButton onClick={(e, id = row) => openMenu(e, id)} sx={{ p: 0 }}>
                                                                            <MoreVertIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Menu
                                                                        sx={{ mt: "23px" }}
                                                                        id="menu-appbar"
                                                                        anchorOrigin={{ vertical: "top", horizontal: "left" }}
                                                                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                                                                        anchorEl={anchor}
                                                                        open={Boolean(anchor)}
                                                                        onClose={closeMenu}
                                                                    >
                                                                        <MenuItem id="option">
                                                                            <Typography>{t("View")}</Typography>
                                                                        </MenuItem>
                                                                        <MenuItem onClick={(e) => setAnchor2(e.currentTarget)} id="option">
                                                                            <Typography>{t("Type")}</Typography>
                                                                        </MenuItem>
                                                                        <MenuItem onClick={(e) => setAnchor3(e.currentTarget)} id="option">
                                                                            <Typography>{t("Status")}</Typography>
                                                                        </MenuItem>
                                                                        <MenuItem id="option" onClick={() => getApplicant()}>
                                                                            <Typography>{t("Applicants")}</Typography>
                                                                        </MenuItem>
                                                                    </Menu>
                                                                    <Menu
                                                                        sx={{ ml: "-97px" }}
                                                                        id="menu-appbar"
                                                                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                                                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                                                                        anchorEl={anchor2}
                                                                        open={Boolean(anchor2)}
                                                                        onClose={() => setAnchor2(null)}
                                                                    >
                                                                        <MenuItem disabled={idx.type === "external"} onClick={() => changeType()} id="option">
                                                                            <Typography>{t("External")}</Typography>
                                                                        </MenuItem>
                                                                    </Menu>
                                                                    <Menu
                                                                        sx={{ ml: "-97px" }}
                                                                        id="menu-appbar"
                                                                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                                                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                                                                        anchorEl={anchor3}
                                                                        open={Boolean(anchor3)}
                                                                        onClose={() => setAnchor3(null)}
                                                                    >
                                                                        <MenuItem
                                                                            disabled={idx.status !== "submitted"}
                                                                            onClick={() => closeStatus()}
                                                                            id="option"
                                                                        >
                                                                            <Typography>{t("Close")}</Typography>
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                            disabled={idx.status === "closed" || idx.status === "submitted"}
                                                                            onClick={() => submitStatus()}
                                                                            id="option"
                                                                        >
                                                                            <Typography>{t("Submit")}</Typography>
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                            disabled={idx.status !== "new" && idx.status !== "updated"}
                                                                            onClick={() => deleteStatus()}
                                                                            id="option"
                                                                        >
                                                                            <Typography>{t("Delete")}</Typography>
                                                                        </MenuItem>
                                                                    </Menu>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    )}
                                </Table>
                                {loading && <LinearProgress />}
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 100]}
                                labelRowsPerPage={t("Rows per page")}
                                component="div"
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Box>
                    <AddPosition setShow={setShow} getDate={getDate} />
                    {applicants.length !== 0 && (
                        <>
                            <h4 className="text-center mt-5 pt-5">{t("Applicants")}</h4>
                            <ApplicantTable value={applicants} get={getApplicant} />
                        </>
                    )}
                </div>
            )}
        </>
    );
}
