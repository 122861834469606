import InsuranseProviderDetails from "../components/InsuranseProvider/Details";

const InsuranseProviderDetailsPage = () => {
    return (
        <div className="container">
            <InsuranseProviderDetails />
        </div>
    );
};

export default InsuranseProviderDetailsPage;
