import React, { useEffect, useState } from "react";
import { ListBtn, Wrapper } from "./ListStyle";
import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import REACT_APP from "../../environment";
import { useRef } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

const item_cl = "form_items px-2 px-md-5";
const select_cl = "form-select ms-sm-5";

const List = () => {
    const { t } = useTranslation();
    const fileRef = useRef("");
    const [file, setFile] = useState("");

    const [fBr, setFBr] = useState("");
    const [tBr, setTBr] = useState("");
    const [fDp, setFDp] = useState("");
    const [tDp, setTDp] = useState("");
    const [fRp, setFRp] = useState("");
    const [tRp, setTRp] = useState("");
    const [fPos, setFPos] = useState("");
    const [tPos, setTPos] = useState("");

    const [loading, setLoading] = useState(false);
    const [branchPresence, setBranchPresence] = useState(sessionStorage.getItem("branch_presence") === "true");
    const [branch, setBranch] = useState([]);
    const [fromReports, setFromReports] = useState([]);
    const [toReports, setToReports] = useState([]);
    const [position, setPosition] = useState([]);
    const [fromDepartment, setFromDepartment] = useState([]);
    const [toDepartment, setToDepartment] = useState([]);

    const [fromDate, setFromDate] = useState([]);
    const [toDate, setToDate] = useState([]);

    const [boards, setBoards] = useState([{ id: 1, title: "From", items: [] }]);
    const [boards2, setBoards2] = useState([{ id: 1, title: "To", items: [] }]);

    const [currentBoard, setCurrentBoard] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);

    const dragOverHandler = (e) => {
        e.preventDefault();
    };
    const dragLeaveHandler = (e) => {
        e.target.style.boxShadow = "none";
    };

    const dragStartHandler = (e, board, item) => {
        setCurrentBoard(board);
        setCurrentItem(item);
    };

    const DropHandler = (e, board, item) => {
        e.preventDefault();
        const currentIndex = currentBoard.indexOf(currentItem);
        currentBoard.splice(currentIndex, 1);
        setToDate([...toDate, currentItem]);
        setBoards2(
            boards.map((b) => {
                if (b.id === board.id) return board;
                if (b.id === currentBoard.id) return currentBoard;
                return b;
            })
        );
    };

    const DropHandler2 = (e, board, item) => {
        e.preventDefault();
        const currentIndex = currentBoard.indexOf(currentItem);
        currentBoard.splice(currentIndex, 1);
        setFromDate([...fromDate, currentItem]);
        setBoards(
            boards.map((b) => {
                if (b.id === board.id) return board;
                if (b.id === currentBoard.id) return currentBoard;
                return b;
            })
        );
    };

    const dragEndHandler = (e) => {
        e.target.style.boxShadow = "none";
    };

    const getFromEmployee = (b_id, d_id, r_id, p_id) => {
        setFromDate([]);
        axios(`${REACT_APP.URL}/api/hrp/v1/employee/transfer/employees?branch=${b_id}&department=${d_id}&reports_to=${r_id}&position=${p_id}`, {
            headers: { authorization: sessionStorage.getItem("token") },
        })
            .then((res) => setFromDate(res.data.data))
            .catch((err) => {});
    };

    const getBranch = () => {
        axios(`${REACT_APP.URL}/api/hrp/v1/organization/branches`, { headers: { authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                if (res.data.data.length === 1) {
                    const id = res.data.data[0].id;
                    setTBr(id);
                    setFBr(id);
                    getFromDepartment(id);
                    getToDepartment(id);
                }
                setBranch(res.data.data);
            })
            .catch((err) => {});
    };

    const handleClear = () => {
        setFromDate([]);
        setToDate([]);
        setFPos("");
        setTPos("");
        setFDp("");
        setTDp("");
        setFRp("");
        setTRp("");
        if (branch.length !== 1) {
            setFBr("");
            setTBr("");
        }
        setFile("");
    };

    const getFromDepartment = (branch_id) => {
        setFBr(branch_id);
        setFromDepartment([]);
        setFromDate([]);
        setFromReports([]);
        setFPos("");
        setFile("");
        if (branch_id) {
            axios(`${REACT_APP.URL}/api/hrp/v1/organization/${branch_id}/departments`, { headers: { authorization: sessionStorage.getItem("token") } })
                .then((res) => setFromDepartment(res.data.data))
                .catch((err) => {});
        }
    };
    const getFromReport = async (dep_id) => {
        setFDp(dep_id);
        setFPos("");
        setFromDate([]);
        setFromReports([]);
        setFile("");
        let data1;
        if (dep_id) {
            data1 = await axios(`${REACT_APP.URL}/api/hrp/v1/organization/reports_to/${dep_id}`, {
                headers: { authorization: sessionStorage.getItem("token") },
            }).catch((e) => console.log(e));
            setFromReports(data1.data.data);
        }

        const data2 = await axios(`${REACT_APP.URL}/api/hrp/v1/organization/reports_to`, { headers: { authorization: sessionStorage.getItem("token") } }).catch(
            (e) => console.log(e)
        );
        setFromReports([...data1.data.data, ...data2.data.data.filter((e) => !data1.data.data.find((i) => i.id === e.id))]);

        // agar head office bo'lsa
        const data3 = await axios(`${REACT_APP.URL}/api/hrp/v1/organization/reports_to/company`, {
            headers: { authorization: sessionStorage.getItem("token") },
        }).catch((e) => console.log(e));
        setFromReports([
            ...data1.data.data,
            ...data2.data.data.filter((e) => !data1.data.data.find((i) => i.id === e.id)),
            ...data3.data.data.filter((e) => !data1.data.data.find((i) => i.id === e.id) && !data2.data.data.find((i) => i.id === e.id)),
        ]);
    };

    const getToDepartment = (branch_id) => {
        setTBr(branch_id);
        setToDepartment([]);
        setToReports([]);
        setTPos("");
        setFile("");
        if (branch_id) {
            axios(`${REACT_APP.URL}/api/hrp/v1/organization/${branch_id}/departments`, { headers: { authorization: sessionStorage.getItem("token") } })
                .then((res) => setToDepartment(res.data.data))
                .catch((err) => {});
        }
    };
    const getToReport = async (dep_id) => {
        setTDp(dep_id);
        setTPos("");
        setFile("");
        let data1;
        if (dep_id) {
            data1 = await axios(`${REACT_APP.URL}/api/hrp/v1/organization/reports_to/${dep_id}`, {
                headers: { authorization: sessionStorage.getItem("token") },
            }).catch((e) => console.log(e));
            setToReports(data1.data.data);
        }

        const data2 = await axios(`${REACT_APP.URL}/api/hrp/v1/organization/reports_to`, { headers: { authorization: sessionStorage.getItem("token") } }).catch(
            (e) => console.log(e)
        );
        setToReports([...data1.data.data, ...data2.data.data.filter((e) => !data1.data.data.find((i) => i.id === e.id))]);

        // agar head office bo'lsa
        const data3 = await axios(`${REACT_APP.URL}/api/hrp/v1/organization/reports_to/company`, {
            headers: { authorization: sessionStorage.getItem("token") },
        }).catch((e) => console.log(e));
        setToReports([
            ...data1.data.data,
            ...data2.data.data.filter((e) => !data1.data.data.find((i) => i.id === e.id)),
            ...data3.data.data.filter((e) => !data1.data.data.find((i) => i.id === e.id) && !data2.data.data.find((i) => i.id === e.id)),
        ]);
    };
    const getPosition = () => {
        axios(`${REACT_APP.URL}/api/hrp/v1/employee/transfer/positions`, { headers: { authorization: sessionStorage.getItem("token") } })
            .then((res) => setPosition(res.data.data))
            .catch((err) => {});
    };

    const changeFromPosition = (value) => {
        setFromDate([]);
        setToDate([]);
        setFPos(value);
        if (value) getFromEmployee(fBr, fDp, fRp, value ? value : fPos);
    };

    const onSave = () => {
        if (!tBr || !tDp || !tRp || !tPos || !file) return 0;

        setLoading(true);
        const employees = toDate.map((el) => el.id);

        const data = new FormData();
        data.append("branch", tBr);
        data.append("department", tDp);
        data.append("reports_to", tRp);
        data.append("position", tPos);
        data.append("employees", employees);
        data.append("file", fileRef.current.files[0]);

        axios
            .put(`${REACT_APP.URL}/api/hrp/v1/employee/transfer/employees`, data, { headers: { authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setLoading(false);
                handleClear();
                toast.success(t("Success"));
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("Something went wrong"))
            });
    };

    useEffect(() => {
        getBranch();
        getPosition();
    }, []);

    return (
        <Wrapper>
            {loading && <div className="hrp-overlay"></div>}
            <h4 className="text-center mt-3">{t("Employee Transfer")}</h4>
            <div className="w-100 p-sm-3 d-flex justify-content-center align-items-center">
                <div className="row w-100 g-4">
                    <div className="col-xl-6 d-flex flex-column gap-4">
                        <h4 className="text-center">{t("From")}</h4>
                        {branchPresence && (
                            <div className={item_cl}>
                                <label className="form-label">{t("Branch")}</label>
                                <select value={fBr} onChange={(e) => getFromDepartment(e.target.value)} className={select_cl}>
                                    <option value="">{t("Select Branch")}</option>
                                    {branch?.map((el) => (
                                        <option key={el.id} value={el.id}>
                                            {el.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <div className={item_cl}>
                            <label className="form-label">{t("Department")}</label>
                            <select
                                value={fDp}
                                onChange={(e) => getFromReport(e.target.value)}
                                className={select_cl}
                                disabled={branch.length !== 1 && (!branch.length || !fBr)}
                            >
                                <option value="">{t("Select Department")}</option>
                                {fromDepartment.map((el) => (
                                    <option key={el.id} value={el.id}>
                                        {el.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={item_cl}>
                            <label className="form-label">{t("Reports To")}</label>
                            <select onChange={(e) => setFRp(e.target.value)} value={fRp} disabled={!fromDepartment.length || !fDp} className={select_cl}>
                                <option value="">{t("Select Reports To")}</option>
                                <option value="not">{t("Not Reports To")}</option>
                                {fromReports.map((el) => (
                                    <option key={el.id} value={el.id}>
                                        {el.first_name + " " + el.last_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={item_cl}>
                            <label className="form-label">{t("Position")}</label>
                            <select onChange={(e) => changeFromPosition(e.target.value)} value={fPos} className={select_cl}>
                                <option value="">{t("Select Position")}</option>
                                <option value="not">{t("Not Position")}</option>
                                {position.map((el) => (
                                    <option key={el.id} value={el.id}>
                                        {el.position}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="col-xl-6 d-flex flex-column gap-4">
                        <h4 className="text-center">{t("To")}</h4>
                        {branchPresence && (
                            <div className={item_cl}>
                                <label className="form-label">{t("Branch")}</label>
                                <select value={tBr} onChange={(e) => getToDepartment(e.target.value)} className={select_cl}>
                                    <option value="">{t("Select Branch")}</option>
                                    {branch?.map((el) => (
                                        <option key={el.id} value={el.id}>
                                            {el.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <div className={item_cl}>
                            <label className="form-label">{t("Department")}</label>
                            <select
                                value={tDp}
                                onChange={(e) => getToReport(e.target.value)}
                                disabled={branch.length !== 1 && (!branch?.length || !tBr)}
                                className={select_cl}
                            >
                                <option value="">{t("Select Department")}</option>
                                {toDepartment.map((el) => (
                                    <option key={el.id} value={el.id}>
                                        {el.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={item_cl}>
                            <label className="form-label">{t("Reports To")}</label>
                            <select value={tRp} onChange={(e) => setTRp(e.target.value)} disabled={!toDepartment.length || !tDp} className={select_cl}>
                                <option value="">{t("Select Reports To")}</option>
                                {toReports.map((el) => (
                                    <option key={el.id} value={el.id}>
                                        {el.first_name + " " + el.last_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={item_cl}>
                            <label className="form-label">{t("Position")}</label>
                            <select value={tPos} onChange={(e) => setTPos(e.target.value)} className={select_cl}>
                                <option value="">{t("Select Position")}</option>
                                {position.map((el) => (
                                    <option key={el.id} value={el.id}>
                                        {el.position}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12 flex-column gap-4">
                        <div className={item_cl}>
                            <label className="form-label">{t("Order File")}</label>
                            <div className="w-100 ms-sm-5">
                                <input ref={fileRef} type="file" value={file} onChange={(e) => setFile(e.target.value)} className="form-control" />
                                {toDate.length >= 1 && !file && <h6 className="text-danger mt-1 mb-0">File is required</h6>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="DropContainer">
                {boards.map((value) => {
                    return (
                        <div className="Board" key={value.id} onDragOver={(e) => dragOverHandler(e)} onDrop={(e) => DropHandler2(e, fromDate, 1)}>
                            <div className="Board_title" key={value.id}>
                                {/* {t(value.title)} */}
                            </div>
                            {fromDate.map((el) => {
                                return (
                                    <div
                                        className="drop_item"
                                        onDragLeave={(e) => dragLeaveHandler(e)}
                                        onDragStart={(e) => dragStartHandler(e, fromDate, el)}
                                        onDragEnd={(e) => dragEndHandler(e)}
                                        draggable={true}
                                        key={el.id}
                                    >
                                        {el.first_name + " " + el.last_name}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}

                {boards2.map((value) => {
                    return (
                        <div className="Board" key={value.id} onDragOver={(e) => dragOverHandler(e)} onDrop={(e) => DropHandler(e, value, 1)}>
                            <div className="Board_title" key={value.id}>
                                {/* {t(value.title)} */}
                            </div>
                            {toDate.map((el) => {
                                return (
                                    <div
                                        className="drop_item"
                                        onDragLeave={(e) => dragLeaveHandler(e)}
                                        onDragStart={(e) => dragStartHandler(e, toDate, el)}
                                        onDragEnd={(e) => dragEndHandler(e)}
                                        draggable={true}
                                        key={el.id}
                                    >
                                        {el.first_name + " " + el.last_name}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
            <ListBtn>
                {loading ? (
                    <LoadingButton loading loadingPosition="start" variant="outlined">
                        {t("Submit")}
                    </LoadingButton>
                ) : (
                    <Button onClick={onSave} variant="contained" color="success" disabled={toDate.length < 1 || !file || !tPos ? true : false}>
                        {t("Submit")}
                    </Button>
                )}
            </ListBtn>
        </Wrapper>
    );
};

export default List;
