import { Button, Grid } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { CircularProgress } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import "react-toastify/dist/ReactToastify.css";
import REACT_APP from "../../environment";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";
import { useMemo } from "react";
import regex from "tts-hrp-val-lib";
import { useTranslation } from "react-i18next";

const EditCoreValue = (props) => {
    const { t } = useTranslation();
    const qy = document.querySelector.bind(document);
    const { getDate, data } = props;
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState(null);
    const [image, setImage] = useState(false);
    const closeRef = useRef(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        defaultValues: useMemo(() => {
            return data;
        }, [data]),
    });

    const Submit = async (date) => {
        setLoading(true);
        date.id = undefined;
        date.company_id = undefined;
        if (image) {
            date.image = undefined;
        } else {
            const n_file = new FormData();
            n_file.append("file", qy("#nFile").files[0]);
            await axios
                .post(`${REACT_APP.HRP_DOC_API}/file`, n_file, {
                    headers: { "client-id": REACT_APP.CLIENT_ID, secret: REACT_APP.SECRET },
                })
                .then((res) => (date.image = res.data.uuid))
                .catch((date.image = undefined));
        }

        await axios
            .put(`${REACT_APP.URL}/api/hrp/v1/core-value/${data.id}`, date, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setLoading(false);
                getDate();
                closeRef.current.click();
                toast.success(t("Success"));
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("Something went wrong"));
            });
    };

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setValues(data);
            setImage(data.image);
            setLoading(false);
        });
    }, [data]);

    return (
        <div className="container">
            <div className="modal fade" id="editModal" tabIndex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
                {loading ? <div className="hrp-overlay"></div> : null}
                <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body p-3">
                            <div className=" flex-column container">
                                <form onSubmit={handleSubmit(Submit)} className="container py-2 example row g-3">
                                    <Grid className="modal-header py-2 px-0">
                                        <h5>{t("Update Core Value")}</h5>
                                    </Grid>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item container spacing={3} xs={12} sm="auto" className="pt-4 mt-0">
                                            <Grid item xs={12} sm={4}>
                                                <label className="form-label">{t("Name")}</label>
                                                <input
                                                    type="text"
                                                    defaultValue={values?.name ? values?.name : ""}
                                                    className={`form-control ${errors.name ? "is-invalid" : ""}`}
                                                    {...register("name", { required: true, pattern: regex.core_value_name })}
                                                    disabled={loading}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <label className="form-label">{t("Value")}</label>
                                                <input
                                                    type="number"
                                                    {...register("value", { required: true })}
                                                    defaultValue={values?.value ? values?.value : ""}
                                                    className={`form-control ${errors.value ? "is-invalid" : ""}`}
                                                    disabled={loading}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4} className="d-flex">
                                                <div className="w-100">
                                                    <label className="form-label">{t("Image")}</label>
                                                    <input
                                                        id="nFile"
                                                        type={!image ? "file" : "text"}
                                                        {...register("image", { required: true })}
                                                        defaultValue={image && values?.image ? values?.image : ""}
                                                        className={`form-control ${errors.image ? "is-invalid" : ""}`}
                                                        disabled={loading || image}
                                                    />
                                                </div>
                                                {!image ? null : (
                                                    <div className="ms-3 mt-3 pt-3 cursor">
                                                        <EditIcon onClick={() => setImage(false)} />
                                                    </div>
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <label className="form-label">{t("Description")}</label>
                                                <textarea
                                                    rows="2"
                                                    className={`form-control ${errors.description ? "is-invalid" : ""}`}
                                                    {...register("description", {
                                                        required: true,
                                                        pattern: regex.description,
                                                    })}
                                                    defaultValue={values?.description ? values?.description : ""}
                                                    disabled={loading}
                                                ></textarea>
                                            </Grid>

                                            <Grid className="modal-footer mt-4 pt-3" item xs={12} sm={12}>
                                                <Button
                                                    className="me-3"
                                                    color={loading ? "inherit" : "secondary"}
                                                    variant="contained"
                                                    data-bs-dismiss="modal"
                                                    ref={closeRef}
                                                    onClick={() => reset()}
                                                >
                                                    {t("Cancel")}
                                                </Button>

                                                <Button
                                                    className="float-end"
                                                    disabled={loading}
                                                    color="primary"
                                                    type="submit"
                                                    variant="contained"
                                                    startIcon={loading && <CircularProgress size="0.9rem" />}
                                                >
                                                    {loading ? t("Saving") : t("Save")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default React.memo(EditCoreValue);
