import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";

function ModalCalendar({
    modalStatus,
    handleClose,
    handleSave,
    handleChange,
    startDate,
    endDate,
    eventInput,
    handleEditEvent,
    handleEdited,
    editStatus,
    handleDelete,
}) {
    const { t } = useTranslation();
    return (
        <>
            <Modal show={modalStatus} onHide={handleClose} centered className="my-modal">
                <Modal.Header closeButton>
                    <Modal.Title>{t("event")}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ justifyContent: "space-between" }}>
                    <Form style={{ width: "calc(100% - 3rem)" }} className="mx-4">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>{t("Start time")}</Form.Label>
                            <Form.Control type="email" placeholder={startDate.toLocaleString("ru-RU")} disabled style={{ wordSpacing: "3px" }} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>{t("End Time")}</Form.Label>
                            <Form.Control type="email" placeholder={endDate.toLocaleString("ru-RU")} style={{ wordSpacing: "3px" }} disabled />
                        </Form.Group>

                        {!editStatus && (
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>{t("Event title")}</Form.Label>
                                <Form.Control as="textarea" rows={3} onChange={handleChange} style={{ boxShadow: "none" }} />
                            </Form.Group>
                        )}

                        {editStatus && (
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>{t("Event title")}</Form.Label>
                                <Form.Control as="textarea" rows={3} value={eventInput} onChange={handleEditEvent} style={{ boxShadow: "none" }} />
                            </Form.Group>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {editStatus && (
                        <Button variant="secondary" onClick={handleDelete} style={{ boxShadow: "none" }}>
                            <DeleteIcon fontSize="small" />
                        </Button>
                    )}

                    <Button variant="secondary" onClick={handleClose} style={{ boxShadow: "none" }}>
                        {t("Close")}
                    </Button>

                    {!editStatus && (
                        <Button variant="success" onClick={handleSave} style={{ boxShadow: "none" }}>
                            {t("Save")}
                        </Button>
                    )}

                    {editStatus && (
                        <Button variant="success" onClick={handleEdited} style={{ boxShadow: "none" }}>
                            {t("Save Changes")}
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalCalendar;
