import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Grid, MenuItem } from "@mui/material";
import Spinner from "react-spinner-material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import REACT_APP from "../../../environment";
import { Spin } from "antd";
import regex from "tts-hrp-val-lib";
import { useTranslation } from "react-i18next";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function TransitionsModal({ handleClosee, id, getDataa }) {
    const { t } = useTranslation();
    const [view, setView] = useState(false);
    const [edit, setEdit] = useState(false);
    const [data, setData] = useState();
    const [loading, setLoading] = React.useState(false);
    const [wait, setWait] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const getData = async () => {
        setWait(true);
        await axios
            .get(`${REACT_APP.URL}/api/hrp/v1/employee/edit/address/${id}`, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                },
            })
            .then((res) => {
                if (res?.data?.data?.zip_code === null) {
                    res.data.data.zip_code = "";
                }
                setWait(false);
                setData(res.data.data);
                setTimeout(() => {
                    setView(true);
                }, 1000);
            })
            .catch((err) => {
                toast.error(t("Something went wrong"));
                setWait(false);
            });
    };

    const validate = Yup.object({
        country: Yup.string()
            .required(t("field is required"))
            .matches(regex.country, { message: t("invalid value") }),
        city: Yup.string()
            .required(t("field is required"))
            .matches(regex.city, { message: t("invalid value") }),
        region: Yup.string()
            .required(t("field is required"))
            .matches(regex.region, { message: t("invalid value") }),
        address1: Yup.string()
            .required(t("field is required"))
            .matches(regex.address1, { message: t("invalid value") }),
        address2: Yup.string()
            .optional(t("field is required"))
            .matches(regex.address2, { message: t("invalid value") })
            .nullable(),
        zip_code: Yup.string()
            .optional()
            .nullable()
            .matches(regex.zip_code, { message: t("invalid value") }),
        current: Yup.string().required(t("field is required")),
        from_date: Yup.string().required(t("field is required")),
        to_date: Yup.date().when("current", (current, schema) => {
            if (current === false || current === "false") {
                return schema.required(t("field is required"));
            }
            return schema.optional();
        }),
    });

    const emptyData = data;
    if (emptyData?.to_date === null) {
        emptyData.to_date = "";
    }

    const onUpdate = async (values) => {
        if (values?.current === true) {
            delete values.to_date;
        }
        if (!values?.address2?.length) {
            values.address2 = null;
        }
        if (!values?.zip_code.length) {
            values.zip_code = null;
        }
        setLoading(true);
        await axios
            .put(`${REACT_APP.URL}/api/hrp/v1/employee/edit/address`, values, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: sessionStorage.getItem("token"),
                },
            })
            .then((res) => {
                setLoading(false);
                toast.success(t("Success"));
                getDataa();
                handleClose();
                handleClosee();
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("Something went wrong"));
            });
    };
    useEffect(() => {
        getData();
        // eslint-disable-next-line
    }, []);
    return (
        <div className="addcompany">
            <Button onClick={handleOpen}>{t("Review")}</Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{ backdrop: { timeout: 500 } }}
            >
                <Fade in={open}>
                    <Box sx={style} className="res_modal">
                        <Button onClick={handleClose} variant="contained" color="primary" style={{ float: "right" }}>
                            <CloseIcon />
                        </Button>
                        <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                            {t("Address info")}
                        </Typography>
                        {view ? (
                            <Formik
                                initialValues={emptyData}
                                validationSchema={validate}
                                onSubmit={async (values) => {
                                    onUpdate(values);
                                    return new Promise((res) => setTimeout(res, 500));
                                }}
                            >
                                {({ values, errors }) => (
                                    <>
                                        {wait ? (
                                            <div style={{ marginTop: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                                            </div>
                                        ) : (
                                            <Form autoComplete="off" className="mt-4">
                                                <Grid item xs={12} className="marginBottom">
                                                    <Field
                                                        fullWidth
                                                        disabled={edit ? false : true}
                                                        label={t("Country")}
                                                        name="country"
                                                        component={TextField}
                                                        autoComplete="country"
                                                        type="text"
                                                        size="small"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} className="marginBottom">
                                                    <Field
                                                        fullWidth
                                                        disabled={edit ? false : true}
                                                        label={t("City")}
                                                        name="city"
                                                        component={TextField}
                                                        autoComplete="city"
                                                        type="text"
                                                        size="small"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} className="marginBottom">
                                                    <Field
                                                        fullWidth
                                                        disabled={edit ? false : true}
                                                        name="region"
                                                        label={t("Region")}
                                                        autoComplete="region"
                                                        component={TextField}
                                                        size="small"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} className="marginBottom">
                                                    <Field
                                                        fullWidth
                                                        disabled={edit ? false : true}
                                                        label={t("Address1")}
                                                        name="address1"
                                                        component={TextField}
                                                        type="text"
                                                        size="small"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} className="marginBottom">
                                                    <Field
                                                        fullWidth
                                                        disabled={edit ? false : true}
                                                        label={t("Address2")}
                                                        name="address2"
                                                        component={TextField}
                                                        type="text"
                                                        size="small"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} className="marginBottom">
                                                    <Field
                                                        fullWidth
                                                        disabled={edit ? false : true}
                                                        label={t("Zip code")}
                                                        name="zip_code"
                                                        component={TextField}
                                                        type="text"
                                                        size="small"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} className="marginBottom">
                                                    <Field
                                                        fullWidth
                                                        select
                                                        name="current"
                                                        component={TextField}
                                                        label={t("Select current")}
                                                        disabled={edit ? false : true}
                                                        size="small"
                                                    >
                                                        <MenuItem value={true}>true</MenuItem>
                                                        <MenuItem value={false}>false</MenuItem>
                                                    </Field>
                                                </Grid>
                                                {edit ? (
                                                    <>
                                                        <Grid item xs={12} className="marginBottom">
                                                            <label>{t("from date")}</label>
                                                            <Field
                                                                fullWidth
                                                                name="from_date"
                                                                component={TextField}
                                                                autoComplete="from_date"
                                                                disabled={edit ? false : true}
                                                                type="date"
                                                                size="small"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} className="marginBottom">
                                                            <label>{t("to date")}</label>
                                                            <Field
                                                                fullWidth
                                                                name="to_date"
                                                                component={TextField}
                                                                autoComplete="to_date"
                                                                disabled={edit ? false : true}
                                                                type="date"
                                                                size="small"
                                                            />
                                                        </Grid>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Grid item xs={12} className="marginBottom">
                                                            <label>{t("from date")}</label>
                                                            <Field
                                                                fullWidth
                                                                name="from_date"
                                                                component={TextField}
                                                                autoComplete="from_date"
                                                                disabled={edit ? false : true}
                                                                size="small"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} className="marginBottom">
                                                            <label>{t("to date")}</label>
                                                            <Field
                                                                fullWidth
                                                                name="to_date"
                                                                component={TextField}
                                                                autoComplete="to_date"
                                                                disabled={edit ? false : true}
                                                                size="small"
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                                <div className="left_right">
                                                    <Button
                                                        variant="contained"
                                                        sx={{ mt: 3, mb: 2 }}
                                                        color="warning"
                                                        onClick={() => setEdit((prev) => !prev)}
                                                        size="small"
                                                    >
                                                        {edit ? <span>{t("Cancel edit")}</span> : <span>{t("Edit")}</span>}
                                                    </Button>
                                                    {loading ? (
                                                        <LoadingButton loading variant="contained" sx={{ mt: 3, mb: 2 }} color="success" size="small">
                                                            {t("Saving")}
                                                        </LoadingButton>
                                                    ) : (
                                                        <Button
                                                            disabled={!edit ? true : false}
                                                            type="submit"
                                                            variant="contained"
                                                            sx={{ mt: 3, mb: 2 }}
                                                            color="success"
                                                            size="small"
                                                        >
                                                            {t("Save")}
                                                        </Button>
                                                    )}
                                                </div>
                                            </Form>
                                        )}
                                    </>
                                )}
                            </Formik>
                        ) : (
                            <div>
                                <Spin tip="Loading" size="large">
                                    <div className="content" />
                                </Spin>
                            </div>
                        )}
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
