import { useNavigate } from "react-router-dom";
import Header from "./components/Header.js";
import { ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import SpinnerTTS from "./components/spinner/Spinner";
import getQueryParam from "./assets/getQueryParam";
import REACT_APP from "./environment";
import "./App.css";

function App() {
    const [wait, setWait] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            setWait(false);
        }, 1500);
        navigate(`${window.location.pathname}`);
        // eslint-disable-next-line
    }, []);

    const token = sessionStorage.getItem("token") || getQueryParam("user");
    if (!token) {
        window.onbeforeunload = {};
        window.location.replace(`${REACT_APP.LOGIN}/login?url=${window.location.href}`);
    } else sessionStorage.setItem("token", token);

    return wait ? (
        <SpinnerTTS />
    ) : (
        <div className="App">
            <ToastContainer />
            <Header />
        </div>
    );
}

export default App;
