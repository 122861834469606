import { IconButton, Tooltip, Menu, MenuItem, Typography } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableContainer from "@mui/material/TableContainer";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import REACT_APP from "../../environment";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Loading from "../Loading";
import * as React from "react";
import Error from "../Error";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: "id", numeric: false, label: "Id" },
    { id: "name", numeric: true, label: "Full Name" },
    { id: "department_name", numeric: true, label: "Department" },
    { id: "rate", numeric: true, label: "Rate" },
    { id: "annual_salary", numeric: true, label: "Annual Salary" },
    { id: "percentage", numeric: true, label: "Percentage" },
    { id: "_pay_type", numeric: true, label: "Pay Type" },
    { id: "actions", numeric: true, label: "Action" },
];

function EnhancedTableHead(props) {
    const { t } = useTranslation();
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => onRequestSort(event, property);

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align="left" sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {t(headCell.label)}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function SalaryTable() {
    const { t } = useTranslation();
    const [id, setId] = React.useState(null);
    const [idx, setIdx] = React.useState("");
    const [page, setPage] = React.useState(0);
    const [data, setData] = React.useState([]);
    const [order, setOrder] = React.useState("asc");
    const [loading, setLoading] = React.useState(true);
    const [errorMsg, setErrorMsg] = React.useState(null);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [orderBy, setOrderBy] = React.useState("calories");

    const [anchor, setAnchor] = React.useState(null);

    const openMenu = (event, elem) => {
        setIdx(elem);
        setAnchor(event.currentTarget);
    };

    const closeMenu = () => setAnchor(null);

    const getData = async () => {
        setErrorMsg(null);
        setLoading(true);
        axios(`${REACT_APP.URL}/api/hrp/v1/payroll/employees`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => {
                setData(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                setErrorMsg(err.message);
                setLoading(false);
            });
    };

    const getDate = async () => {
        setErrorMsg(null);
        axios(`${REACT_APP.URL}/api/hrp/v1/payroll/employees`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => setData(res.data.data))
            .catch((err) => setErrorMsg(err.message));
    };

    const increaseSalary = () => {
        axios
            .patch(
                `${REACT_APP.URL}/api/hrp/v1/payroll/employee/rate/increase`,
                { employee_id: idx.id },
                { headers: { Authorization: sessionStorage.getItem("token") } }
            )
            .then((res) => {
                setAnchor(null);
                toast.success(t("Success"));
                getDate();
            })
            .catch((err) => {
                toast.error(t("Something went wrong"));
            });
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const navigate = useNavigate();
    const onRate = () => navigate(`${idx.id}/rate`);
    const onPayroll = () => navigate(`${idx.id}/payroll`);
    const onSetSalary = () => navigate(`${idx.id}/salary`);

    React.useEffect(() => {
        getData();
    }, []);

    return (
        <>
            {loading ? (
                <Loading />
            ) : errorMsg ? (
                <Error message={errorMsg} />
            ) : (
                <div className="py-5 px-2">
                    <div className="d-flex justify-content-between row pb-3">
                        <div className="col-sm-4">
                            <h4 className="text-center text-sm-start">{t("Salary")}</h4>
                        </div>
                    </div>
                    <Box sx={{ width: "100%" }}>
                        <Paper sx={{ width: "100%", mb: 2 }}>
                            <TableContainer>
                                <Table sx={{ minWidth: 750, px: 2 }} aria-labelledby="tableTitle" size="medium">
                                    <EnhancedTableHead order={order} onRequestSort={handleRequestSort} orderBy={orderBy} rowCount={data.length} />
                                    <TableBody>
                                        {stableSort(data, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                return (
                                                    <TableRow hover tabIndex={-1} key={row.id}>
                                                        <TableCell align="left">{row.id}</TableCell>
                                                        <TableCell align="left">{row.first_name + " " + row.last_name}</TableCell>
                                                        <TableCell align="left">{row.department_name}</TableCell>
                                                        <TableCell align="left">{row.rate}</TableCell>
                                                        <TableCell align="left">{row.annual_salary}</TableCell>
                                                        <TableCell align="left">
                                                            {row.percentage}
                                                            {row.percentage ? " %" : null}
                                                        </TableCell>
                                                        <TableCell align="left">{t(row._pay_type)}</TableCell>
                                                        <TableCell align="left">
                                                            <Box sx={{ flexGrow: 0 }}>
                                                                <Tooltip title={t("Action")}>
                                                                    <IconButton
                                                                        style={{ borderRadius: "50%", padding: "2px" }}
                                                                        onClick={(e, elem = row) => openMenu(e, elem)}
                                                                        sx={{ p: 0 }}
                                                                    >
                                                                        <MoreVertIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Menu
                                                                    onClick={() => setId(row.id)}
                                                                    sx={{ mt: "23px" }}
                                                                    id="menu-appbar"
                                                                    anchorOrigin={{ vertical: "top", horizontal: "left" }}
                                                                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                                                                    anchorEl={anchor}
                                                                    open={Boolean(anchor)}
                                                                    onClose={closeMenu}
                                                                >
                                                                    <MenuItem disabled={idx.annual_salary} onClick={() => onSetSalary(id)}>
                                                                        <Typography>{t("Set Annual Salary")}</Typography>
                                                                    </MenuItem>
                                                                    <MenuItem onClick={increaseSalary}>
                                                                        <Typography>{t("Increase Salary")}</Typography>
                                                                    </MenuItem>
                                                                    <MenuItem onClick={(e) => onRate(id)}>
                                                                        <Typography>{t("Rate History")}</Typography>
                                                                    </MenuItem>
                                                                    <MenuItem onClick={(e) => onPayroll(id)}>
                                                                        <Typography>{t("Payroll History")}</Typography>
                                                                    </MenuItem>
                                                                </Menu>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                component="div"
                                labelRowsPerPage={t("Rows per page")}
                                rowsPerPageOptions={[5, 10, 25, 100]}
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Box>
                </div>
            )}
        </>
    );
}

export default React.memo(SalaryTable);
