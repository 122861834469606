import { Button, CircularProgress, Grid } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import REACT_APP from "../../environment";
import { toast } from "react-toastify";
import axios from "axios";
import regex from "tts-hrp-val-lib";
import { useTranslation } from "react-i18next";

const AddEmployees = ({ getDate }) => {
    const { t } = useTranslation();
    // const [countryValue, setCountryValue] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [branchPresence, setBranchPresence] = useState(sessionStorage.getItem("branch_presence") === "true");
    const [branchs, setBranchs] = useState([]);
    const [bramch_id, setBranch_id] = useState(undefined);
    const [department, setDepartment] = useState([]);
    const closeRef = useRef(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const getBranch = () => {
        axios(`${REACT_APP.URL}/api/hrp/v1/organization/branches`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => {
                if (!branchPresence) handleChange({ target: { value: res.data.data[0].id } });
                setBranchs(res.data.data);
            })
            .catch((err) => {});
    };

    const handleChange = (event) => {
        setDisabled(true);
        setBranch_id(event.target.value);
        axios(`${REACT_APP.URL}/api/hrp/v1/organization/${event.target.value}/departments`, {
            headers: {
                Authorization: sessionStorage.getItem("token"),
            },
        })
            .then((res) => {
                setDepartment(res.data.data);
                setDisabled(false);
            })
            .catch((err) => {
                toast.error(t("Something went wrong"))
                setDisabled(true);
            });
    };
    const Submit = async (date) => {
        let body = {
            email: date.email,
            department_id: date.department_id,
        };
        setLoading(true);
        axios
            .post(`${REACT_APP.AUTH}/api/hrp/v2/admin/employee/onboarded`, body, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setLoading(false);
                reset();
                getDate();
                closeRef.current.click();
                toast.success(t("Success"));
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("Something went wrong"));
            });
    };

    useEffect(() => {
        getBranch();
    }, []);

    return (
        <div className="container">
            <Button className="float-end" variant="contained" color="primary" data-bs-toggle="modal" data-bs-target="#addModal">
                {t("Add Onboarded")}
            </Button>

            <div className="modal fade" id="addModal" tabIndex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
                {loading ? <div className="hrp-overlay"></div> : null}
                <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body p-3">
                            <div className=" flex-column container">
                                <form onSubmit={handleSubmit(Submit)} className="container py-2 example row g-3">
                                    <Grid className="modal-header py-2 px-0">
                                        <h5>{t("Add Onboarded")}</h5>
                                    </Grid>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item container spacing={3} xs={12} sm="auto" className="pt-4 mt-0">
                                            <Grid item xs={12} sm={branchPresence ? 4 : 6}>
                                                <input
                                                    type="text"
                                                    placeholder={t("Email")}
                                                    className={errors.email ? "is-invalid form-control" : "form-control"}
                                                    {...register("email", { required: true, pattern: regex.email })}
                                                />
                                            </Grid>
                                            {branchPresence && (
                                                <Grid item xs={12} sm={4}>
                                                    <select
                                                        value={bramch_id}
                                                        onChange={handleChange}
                                                        className={errors.country ? "is-invalid form-select" : "form-select"}
                                                    >
                                                        <option value="">{t("Select Branch")}</option>
                                                        {branchs.map((el) => (
                                                            <option key={el.id} value={el.id}>
                                                                {el.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </Grid>
                                            )}
                                            <Grid item xs={12} sm={branchPresence ? 4 : 6}>
                                                <select
                                                    disabled={disabled}
                                                    className={errors.department_id ? "is-invalid form-select" : "form-select"}
                                                    {...register("department_id", { required: true })}
                                                >
                                                    <option value="">{t("Select Department")}</option>
                                                    {department.map((el) => (
                                                        <option key={el.id} value={el.id}>
                                                            {el.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </Grid>

                                            <Grid className="modal-footer mt-4 pt-3" item xs={12} sm={12}>
                                                <Button
                                                    className="me-3"
                                                    variant="contained"
                                                    data-bs-dismiss="modal"
                                                    color={loading ? "inherit" : "secondary"}
                                                    ref={closeRef}
                                                    onClick={() => reset()}
                                                >
                                                    {t("Cancel")}
                                                </Button>

                                                <Button
                                                    className="float-end"
                                                    disabled={loading}
                                                    color="primary"
                                                    type="submit"
                                                    variant="contained"
                                                    startIcon={loading && <CircularProgress size="0.9rem" />}
                                                >
                                                    {loading ? t("Saving") : t("Save")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(AddEmployees);
