import { Button, CircularProgress } from "@material-ui/core";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import REACT_APP from "../../environment";
import { Grid } from "@mui/material";
import axios from "axios";
import regex from "tts-hrp-val-lib";
import { useTranslation } from "react-i18next";

const CreateHoliday = ({ getDate }) => {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const [loading, setLoading] = useState(false);
    const closeRef = useRef(null);

    const Submit = async (data) => {
        const body = {
            dates: [data.date],
            name: data.name,
        };

        setLoading(true);
        axios
            .post(`${REACT_APP.URL}/api/hrp/v1/holiday`, body, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setLoading(false);
                closeRef.current.click();
                toast.success(t("Success"));
                reset();
                getDate();
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("Something went wrong"));
            });
    };

    return (
        <div className="container">
            <Button className="float-end" variant="contained" color="primary" data-bs-toggle="modal" data-bs-target="#addModal">
                {t("Add Holiday")}
            </Button>
            <div className="modal fade" id="addModal" tabIndex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
                {loading && <div className="hrp-overlay"></div>}
                <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body p-3">
                            <div className=" flex-column container">
                                <form onSubmit={handleSubmit(Submit)} className="container">
                                    <Grid className="modal-header py-2 px-0">
                                        <h5>{t("Add Holiday")}</h5>
                                    </Grid>
                                    <Grid container direction="column">
                                        <Grid item container spacing={2} xs={12} sm="auto" className="pt-4 mt-0">
                                            <Grid item xs={12} sm={6}>
                                                <input
                                                    type="text"
                                                    placeholder={t("Name")}
                                                    className={errors.name ? "is-invalid form-control" : "form-control"}
                                                    {...register("name", {
                                                        required: true,
                                                        pattern: regex.holiday_name,
                                                    })}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <input
                                                    type="date"
                                                    placeholder={t("Date")}
                                                    {...register("date", { required: true })}
                                                    className={errors.date ? "is-invalid form-control" : "form-control"}
                                                />
                                            </Grid>

                                            <Grid className="modal-footer mt-5 pt-4 px-0" item xs={12} sm={12}>
                                                <Button
                                                    color={loading ? "inherit" : "secondary"}
                                                    className="me-3"
                                                    data-bs-dismiss="modal"
                                                    onClick={() => reset()}
                                                    variant="contained"
                                                    ref={closeRef}
                                                >
                                                    {t("Cancel")}
                                                </Button>

                                                <Button
                                                    className="float-end"
                                                    disabled={loading}
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={loading && <CircularProgress size="0.9rem" />}
                                                >
                                                    {loading ? t("Saving") : t("Save")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(CreateHoliday);
