import CoreValueTable from "./CoreValueTable";

const Index = () => {
    return (
        <div className="container">
            <CoreValueTable />
        </div>
    );
};

export default Index;
