import React from "react";
import axios from "axios";
import REACT_APP from "../../../environment";
import Loading from "../../Loading";
import Error from "../../Error";
import { useEffect } from "react";
import { useState } from "react";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Settings = ({ id }) => {
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const [errorMsg, setErrorMsg] = useState(null);
    const [loading, setLoading] = useState(true);
    const [setting, setSetting] = useState(false);
    const [managers, setManagers] = useState([]);
    const [body, setBody] = useState({ head: "", acting_head: "" });

    const getDepartment = async () => {
        setLoading(true);
        axios
            .get(`${REACT_APP.URL}/api/hrp/v1/hr/department/${id}`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setData(res.data.data);
                setBody({ head: res.data.data.head?.id || "", acting_head: res.data.data.acting_head?.id || "" });
                setLoading(false);
            })
            .catch((err) => {
                setErrorMsg(err.message);
                setLoading(false);
            });
    };

    const getManagers = async () => {
        try {
            const mans = await axios.get(`${REACT_APP.URL}/api/hrp/v1/hr/department/${id}/managers`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            });
            setManagers(mans.data?.data);
            const emps = await axios.get(`${REACT_APP.URL}/api/hrp/v1/hr/department/${id}/employees`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            });
            setManagers([...mans.data?.data, ...emps.data?.data.filter((e) => !mans.data?.data.find((i) => i.id === e.id))]);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getDepartment();
        getManagers();
    }, []);

    const cancel = () => {
        setBody({ head: data.head?.id || "", acting_head: data.acting_head?.id || "" });
        setSetting(false);
    };

    const submit = async () => {
        if (!body.head) toast.error(t("Something went wrong"));
        const data = { head: body.head, acting_head: body.acting_head || null, id };
        await axios
            .patch(`${REACT_APP.URL}/api/hrp/v1/hr/department`, data, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then(() => getDepartment())
            .catch((err) => {
                setBody({ head: data.head?.id || "", acting_head: data.acting_head?.id || "" });
                toast.error(t("Something went wrong"));
            });
        setSetting(false);
    };

    return loading ? (
        <Loading />
    ) : errorMsg ? (
        <Error message={errorMsg} />
    ) : (
        <div>
            <Button color="primary" style={{ float: "right" }} disabled={setting} onClick={() => setSetting(true)}>
                {t("Update")}
            </Button>
            <br />
            <div style={{ marginTop: "15px" }}>
                <div>
                    <b style={{ color: "#555" }}>{t("Name")}:</b>
                    <span className="mx-2">{data.department_name}</span>
                </div>
            </div>
            <div style={{ marginTop: "15px" }}>
                <div>
                    <b style={{ color: "#555" }}>{t("Head")}:</b>
                    {setting ? (
                        <select
                            defaultValue={body.head}
                            onChange={(e) => setBody({ head: e.target.value || "", acting_head: body.acting_head })}
                            className="mx-2 p-1"
                        >
                            <option value="" style={{ display: "none" }}>
                                {t("Select employee")}
                            </option>
                            {managers.map((el) => (
                                <option key={el.id} value={el.id}>
                                    {el.first_name} {el.last_name}
                                </option>
                            ))}
                        </select>
                    ) : (
                        <span className="mx-2">{data.head ? `${data.head.first_name} ${data.head.last_name}` : t("not assigned")}</span>
                    )}
                </div>
            </div>
            <div style={{ marginTop: "15px" }}>
                <div>
                    <b style={{ color: "#555" }}>{t("Acting head")}:</b>
                    {setting ? (
                        <select
                            defaultValue={body.acting_head}
                            onChange={(e) => setBody({ acting_head: e.target.value || "", head: body.head })}
                            className="mx-2 p-1"
                        >
                            <option value="" style={{ display: "none" }}>
                                {t("Select employee")}
                            </option>
                            <option value="">{t("Release")}</option>
                            {managers.map((el) => (
                                <option key={el.id} value={el.id}>
                                    {el.first_name} {el.last_name}
                                </option>
                            ))}
                        </select>
                    ) : (
                        <span className="mx-2">{data.acting_head ? `${data.acting_head.first_name} ${data.acting_head.last_name}` : t("not assigned")}</span>
                    )}
                </div>
                {setting && (
                    <div style={{ marginTop: "25px" }}>
                        <Button color="secondary" variant="outlined" onClick={cancel} style={{ marginRight: "20px" }}>
                            {t("Cancel")}
                        </Button>
                        <Button color="primary" variant="outlined" onClick={submit}>
                            {t("Submit")}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Settings;
