import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Button, Layout, Menu, theme } from "antd";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import styles from "./MainNavigation.module.css";
import avatarImg from "../assets/avatar.jpg";

import Holidays from "../pages/Holidays";
import Department from "../pages/Department";
import Home from "../pages/Home";
import Positions from "../pages/Positions";
import Performance from "../pages/Performance";
import OnboardedEmployees from "../pages/Onboarded";
import OpenPosition from "../pages/OpenPosition";
import Branches from "../pages/Branches";
import EmergencyTable from "../components/Employees/Emergency/EmergencyTable";
import EducationTable from "../components/Employees/Educations/EducationTable";
import AddressTable from "../components/Employees/Address/AddressTable";
import DependencyTable from "../components/Employees/Dependency/DependencyTable";
import TimeOff from "../components/TimeOff/TimeOff";
import WorkHistoryTable from "../components/Employees/Work/WorkHistoryTable";
import OnboardingStepper from "../components/Onboarding/Stepper/OnboardingStepper";
import EmployeesPage from "../pages/EmployeesPage";
import TimeOffHrPage from "../pages/TimeOffHrPage";
import WorkIcon from "@mui/icons-material/Work";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import HomeIcon from "@mui/icons-material/Home";
import EmployeeTransfer from "./EmployeeTransfer/EmployeeTransfer";
import RoleAssignment from "../pages/RoleAssignment";
import CoreValue from "../pages/CoreValue";
import Notifications from "./Notifications";
import axios from "axios";
import TimeOffTable from "./Time_off/TimeOffTable";
import Salary from "../pages/Salary";
import RateTable from "./Salary/Rate/RateTable";
import PayrollTable from "./Salary/Payroll/PayrollTable";
import SetAnualSalary from "./Salary/SetAnualSalary";
import SettingsIcon from "@mui/icons-material/Settings";
import GridViewIcon from "@mui/icons-material/GridView";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import Requests from "../pages/Requests";
import Bills from "../pages/Bills";
import Consumer from "../pages/Consumer";
import REACT_APP from "../environment";
import AppSettings from "../pages/AppSettings";
import Goals from "../pages/Goals";
import ArticleIcon from "@mui/icons-material/Article";
import LibraryFiles from "../pages/LibraryFiles";
import Logo from "../pages/Logo";
import PerformanceComments from "./CoreValue/Comments";
import DevGoalComments from "./Performance/Comments";
import DepartmentTabs from "../pages/DepartmentDetails";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import BigCalendar from "../pages/BigCalendar";
import EmployeeSkillPage from "../pages/EmployeeSkill";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import NotificationsIcon from "@mui/icons-material/Notifications";
import en from "../assets/en.png";
import uz from "../assets/uz.png";
import ru from "../assets/ru.png";
import InsuranseProviderPage from "../pages/InsuranceProvider";
import InsuranseProviderDetailsPage from "../pages/InsuranceProviderDetails";

const { Header, Sider, Content } = Layout;
function getItem(label, key, icon, children, type) {
    return { key, icon, children, label, type };
}

const App = () => {
    const [items, setItems] = useState([]);
    const [collapsed, setCollapsed] = useState(false);
    const [windowSize, setWindowSize] = useState([window.innerWidth]);
    const [logo, setLogo] = useState(null);
    const [branchPresence, setBranchPresence] = useState(false);
    const [accesses, setAccesses] = useState(null);
    const [messages, setMessages] = useState([]);
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const [img, setImg] = useState(null);
    const navigate = useNavigate();

    const { t, i18n } = useTranslation();
    const [lang, setlang] = useState(Cookies.get("i18next"));
    const onLanguage = (e) => {
        const lng = e.target.value;
        i18n.changeLanguage(lng);
        setlang(lng);
    };

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth]);
            if (window.innerWidth <= 700) {
                setCollapsed(true);
            } else {
                setCollapsed(false);
            }
        };
        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    async function getLogo() {
        await fetch(`${REACT_APP.AUTH}/api/hrp/v2/employee/logo`, {
            headers: { Authorization: sessionStorage.getItem("token") },
            method: "GET",
        })
            .then((res) => res.blob())
            .then((blob) => {
                if (blob.type?.slice(0, 5) === "image") {
                    setLogo(window.URL.createObjectURL(blob));
                }
            })
            .catch((err) => console.log(err));
    }

    const getAccesses = async () => {
        await axios
            .get(`${REACT_APP.AUTH}/api/hrp/v2/employee/accesses`, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setAccesses(res.data.data);
                setBranchPresence(res.data.data.branch_presence);
                sessionStorage.setItem("branch_presence", res.data.data.branch_presence);
                updateMenu(res.data.data);
            })
            .catch((err) => console.log(err));
    };

    i18n.on("languageChanged", () => {
        if (accesses) updateMenu(accesses);
    });

    const updateMenu = ({ accesses, home, branch_presence, apps }) => {
        const menuItems = [];
        menuItems.push(getItem(home || t("Home"), "/", <HomeIcon className="m_icons" />));
        if (accesses.find((element) => element === "HR")) {
            menuItems.push(
                getItem(t("HR"), "sub1", <ManageAccountsIcon className="m_icons" />, [
                    getItem(t("Employees"), "/employees"),
                    getItem(t("Employee Transfer"), "/transfer"),
                    branch_presence && getItem(t("Branches"), "/branches"),
                    getItem(t("Departments"), "/department"),
                    getItem(t("Open position"), "/open-position"),
                    getItem(t("Skills"), "/hr/skill"),
                    getItem(t("Onboarded"), "/onboarded"),
                    getItem(t("Time Off"), "/hr/time-off"),
                    getItem(t("Salary"), "/hr/salary"),
                    getItem(t("Insurance"), "/hr/insurance/provider"),
                ])
            );
        }
        if (accesses.find((element) => element === "manager")) {
            menuItems.push(
                getItem(t("Manager"), "sub2", <WorkIcon className="m_icons" />, [
                    getItem(t("Performance"), "/performance"),
                    getItem(t("Time Off"), "/time-off"),
                ])
            );
        }
        if (accesses.find((element) => element === "admin")) {
            menuItems.push(
                getItem(t("Admin"), "sub3", <MiscellaneousServicesIcon className="m_icons" />, [
                    getItem(t("Role Assignment"), "/role-assignment"),
                    getItem(t("Holidays"), "/holidays"),
                    getItem(t("Time Off"), "/vacation"),
                    getItem(t("Positions"), "/position"),
                    getItem(t("Core Value"), "/core-value"),
                    getItem(t("Logo"), "/logo"),
                ])
            );
        }
        menuItems.push(
            getItem(t("Applications"), "sub4", <GridViewIcon className="m_icons" />, [
                getItem(t("BGD"), "/bgd", <HowToRegIcon className="m_icons" />, [
                    getItem(t("Consumer"), "/background-check"),
                    getItem(t("Requests"), "/background-check/requests"),
                    getItem(t("Bills"), "/background-check/bills"),
                ]),
                apps.find((e) => e === "timesheet") && getItem(t("Timesheet"), "/timesheet", <EventAvailableIcon className="m_icons" />),
                getItem(t("Settings"), "/settings", <SettingsIcon className="m_icons" />),
            ])
        );
        menuItems.push(getItem(t("Calendar"), "/big-calendar", <EditCalendarIcon className="m_icons" />));
        menuItems.push(getItem(t("Library"), "/library", <ArticleIcon className="m_icons" />));
        setItems(menuItems);
    };

    const gotoNotificationPage = () => {
        navigate("/notifications");
    };

    const logout = () => {
        sessionStorage.clear();
        window.onbeforeunload = {};
        window.location.replace(`${REACT_APP.LOGIN}/login?url=${window.location.href}/logout`);
    };

    useEffect(() => {
        getLogo();
        getAccesses();
    }, []);

    return (
        <>
            <Layout>
                <Sider trigger={null} collapsible collapsed={collapsed ? 1 : 0}>
                    <div className="logo" />
                    <Menu
                        onClick={(item) => {
                            if (item.key === "/timesheet")
                                setTimeout(() => {
                                    window.open(`${REACT_APP.LOGIN}/login?url=${REACT_APP.TIMESHEET_URL}`, "_blank");
                                    navigate("/");
                                }, 300);
                            else navigate(item.key);
                        }}
                        mode="inline"
                        theme="dark"
                        collapsed={collapsed ? 1 : 0}
                        items={items}
                    />
                </Sider>

                <Layout>
                    <Header
                        theme="dark"
                        className="d-flex justify-content-between align-items-center header"
                        style={{ padding: 0, background: colorBgContainer }}
                    >
                        <div>
                            <Button
                                type="text"
                                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                                onClick={() => setCollapsed(!collapsed)}
                                style={{ fontSize: "16px", width: 64, height: 64, marginRight: "5px" }}
                            />
                            {logo && <img src={logo} alt="logo" height={64} />}
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="me-4">
                                <Select value={lang} size="small" onChange={onLanguage} className="bg-slate-300 mr-4">
                                    <MenuItem value="en">
                                        <img style={{ width: "23px", marginRight: "7px" }} src={en} alt="en" /> en
                                    </MenuItem>
                                    <MenuItem value="uz">
                                        <img style={{ width: "23px", marginRight: "7px" }} src={uz} alt="uz" /> uz
                                    </MenuItem>
                                    <MenuItem value="ru">
                                        <img style={{ width: "23px", marginRight: "7px" }} src={ru} alt="ru" /> ru
                                    </MenuItem>
                                </Select>
                            </div>
                            <div className="notification-icon" onClick={gotoNotificationPage}>
                                <NotificationsIcon sx={{ fontSize: "28px" }} />
                                {messages.length > 0 && <div className="notification-tooltip">{messages.length}</div>}
                            </div>
                            <div className="dropdown me-4">
                                <a
                                    className="dropdown-toggle d-flex align-items-center hidden-arrow"
                                    href="/"
                                    id="navbarDropdownMenuAvatar"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <img src={img ? img : avatarImg} className="rounded-circle" style={{ width: "35px", height: "35px" }} alt="avatar" />
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuAvatar">
                                    <li className="dropdownNav pointer">
                                        <div className={styles.dropdownItem} onClick={logout}>
                                            {t("Logout")}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Header>
                    <Content id="head_content" style={{ background: colorBgContainer }}>
                        <Routes>
                            <Route path="/" exact element={<Home />} />
                            <Route path="/open-position" element={<OpenPosition />} />
                            <Route path="/onboarded" element={<OnboardedEmployees />} />
                            <Route path="/onboarded/:id" element={<OnboardingStepper />} />
                            <Route path="/performance" element={<Performance />} />
                            <Route path="/review/:id" element={<Goals />} />
                            <Route path="/performance/:id/details" element={<PerformanceComments />} />
                            <Route path="/development/:id/details" element={<DevGoalComments />} />
                            <Route path="/employees" element={<EmployeesPage />} />
                            <Route path="/transfer" element={<EmployeeTransfer />} />
                            <Route path="/role-assignment" element={<RoleAssignment />} />
                            <Route path="/employees/:id/contacts" element={<EmergencyTable />} />
                            <Route path="/employees/:id/educations" element={<EducationTable />} />
                            <Route path="/employees/:id/address" element={<AddressTable />} />
                            <Route path="/employees/:id/dependency" element={<DependencyTable />} />
                            <Route path="/employees/:id/work" element={<WorkHistoryTable />} />
                            {branchPresence && <Route path="/branches" element={<Branches />} />}
                            <Route path="/holidays" element={<Holidays />} />
                            <Route path="/time-off" element={<TimeOff />} />
                            <Route path="/department" element={<Department />} />
                            <Route path="/department/:id" element={<DepartmentTabs />} />
                            <Route path="/position" element={<Positions />} />
                            <Route path="/core-value" element={<CoreValue />} />
                            <Route path="/logo" element={<Logo />} />
                            <Route path="/vacation" element={<TimeOffHrPage />} />
                            <Route path="/hr/time-off" element={<TimeOffTable />} />
                            <Route path="/hr/salary" element={<Salary />} />
                            <Route path="/hr/salary/:id/rate" element={<RateTable />} />
                            <Route path="/hr/salary/:id/payroll" element={<PayrollTable />} />
                            <Route path="/hr/salary/:id/salary" element={<SetAnualSalary />} />
                            <Route path="/background-check/requests" element={<Requests />} />
                            <Route path="/background-check/bills" element={<Bills />} />
                            <Route path="/background-check" element={<Consumer />} />
                            <Route path="/settings" element={<AppSettings />} />
                            <Route path="/notifications" element={<Notifications />} />
                            <Route path="/big-calendar" element={<BigCalendar />} />
                            <Route path="/library" element={<LibraryFiles />} />
                            <Route path="/hr/skill" element={<EmployeeSkillPage />} />
                            <Route path="/hr/insurance/provider" element={<InsuranseProviderPage />} />
                            <Route path="/hr/insurance/provider/:id" element={<InsuranseProviderDetailsPage />} />
                        </Routes>
                    </Content>
                </Layout>
            </Layout>
        </>
    );
};
export default App;
