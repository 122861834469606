import React from "react";
import Index from "../components/Performance/Tabs.js";

const Goals = () => {
    return (
        <>
            <Index />
        </>
    );
};

export default Goals;
