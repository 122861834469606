import { Button, CircularProgress } from "@material-ui/core";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import REACT_APP from "../../environment";
import { Grid } from "@mui/material";
import axios from "axios";
import regex from "tts-hrp-val-lib";
import { useTranslation } from "react-i18next";

const UpdateHoliday = ({ getDate, value, setIdx }) => {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();
    const [loading, setLoading] = useState(false);
    const closeRef = useRef(null);

    const Submit = async (data) => {
        const body = {
            date: data.date,
            name: data.name,
        };

        setLoading(true);
        axios
            .put(`${REACT_APP.URL}/api/hrp/v1/holiday/${value.id}`, body, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setLoading(false);
                closeRef.current.click();
                toast.success(t("Success"));
                reset();
                getDate();
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("Something went wrong"));
            });
    };

    const handleCancel = () => {
        setTimeout(() => {
            setIdx("");
        }, 200);
    };

    return (
        <div className="container">
            <div className="modal fade" id="updateModal" tabIndex="-1" aria-labelledby="updateModalLabel" aria-hidden="true">
                {loading ? <div className="hrp-overlay"></div> : null}
                <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body p-3">
                            <div className=" flex-column container">
                                <form onSubmit={handleSubmit(Submit)} className="container">
                                    <Grid className="modal-header py-2 px-0">
                                        <h5>{t("Update Holiday")}</h5>
                                    </Grid>
                                    <Grid container direction="column">
                                        <Grid item container spacing={2} xs={12} sm="auto" className="pt-4 mt-0">
                                            <Grid item xs={12} sm={6}>
                                                <input
                                                    type="text"
                                                    placeholder={t("Name")}
                                                    defaultValue={value?.name ? value?.name : ""}
                                                    className={errors.name ? "is-invalid form-control" : "form-control"}
                                                    {...register("name", { required: true, pattern: regex.holiday_name })}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <input
                                                    type="date"
                                                    placeholder={t("Date")}
                                                    {...register("date", { required: true })}
                                                    defaultValue={value?.date ? new Date(value.date).toISOString().substr(0, 10) : ""}
                                                    className={errors.date ? "is-invalid form-control" : "form-control"}
                                                    id="idd"
                                                />
                                            </Grid>

                                            <Grid className="modal-footer mt-5 pt-4 px-0" item xs={12} sm={12}>
                                                <Button
                                                    className="me-3"
                                                    data-bs-dismiss="modal"
                                                    onClick={handleCancel}
                                                    variant="contained"
                                                    color={loading ? "inherit" : "secondary"}
                                                    ref={closeRef}
                                                >
                                                    {t("Cancel")}
                                                </Button>

                                                <Button
                                                    className="float-end"
                                                    disabled={loading}
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={loading && <CircularProgress size="0.9rem" />}
                                                >
                                                    {loading ? t("Updating") : t("Update")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(UpdateHoliday);
