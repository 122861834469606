import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import { useNavigate } from "react-router-dom";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import Paper from "@mui/material/Paper";
import REACT_APP from "../../../environment";
import { useParams } from "react-router-dom";
import Loading from "../../Loading";
import Error from "../../Error";
import axios from "axios";
import Action from "./Action";
import { Alert, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: "id", numeric: false, label: "Id" },
    { id: "name", numeric: true, label: "Name" },
    { id: "position", numeric: true, label: "Position" },
    { id: "email", numeric: true, label: "Email" },
    { id: "phone", numeric: true, label: "Phone" },
    { id: "start_date", numeric: true, label: "Start date" },
    { id: "end_date", numeric: true, label: "End date" },
    { id: "action", numeric: true, label: "Action" },
];

function EnhancedTableHead(props) {
    const { t } = useTranslation();
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align="left" sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {t(headCell.label)}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function PerformanceTable() {
    const { t } = useTranslation();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [page, setPage] = React.useState(0);
    const [data, setData] = React.useState([]);
    const [idx, setIdx] = React.useState("");
    const [loading, setLoading] = React.useState(true);
    const [errorMsg, setErrorMsg] = React.useState(null);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const params = useParams();

    React.useEffect(() => {
        getDate();
    }, []);

    const getDate = async () => {
        setErrorMsg(null);
        setLoading(true);
        axios
            .get(`${REACT_APP.URL}/api/hrp/v1/employee/edit/${params.id}/work`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setData(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                setErrorMsg(err.message);
                setLoading(false);
            });
    };
    const getDataa = async () => {
        axios
            .get(`${REACT_APP.URL}/api/hrp/v1/employee/edit/${params.id}/work`, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => setData(res.data.data))
            .catch((err) => {});
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const navigate = useNavigate();
    const onBack = () => {
        navigate("/employees");
    };
    return (
        <div className="container">
            <Button className="mt-3" variant="contained" color="primary" onClick={onBack}>
                {t("Back")}
            </Button>
            {loading ? (
                <Loading />
            ) : errorMsg ? (
                <Error message={errorMsg} />
            ) : (
                <div className="py-5">
                    <div className="d-flex justify-content-between row pb-3">
                        <div className="col-sm-4">
                            <h4 className="text-center text-sm-start">{t("Work History")}</h4>
                        </div>
                    </div>
                    {!data.length && !loading && !errorMsg && <Alert severity="warning">{t("No Data")}</Alert>}
                    {data.length ? (
                        <Box sx={{ width: "100%" }}>
                            <Paper sx={{ width: "100%", mb: 2 }}>
                                <TableContainer>
                                    <Table sx={{ minWidth: 750, px: 2 }} aria-labelledby="tableTitle" size="medium">
                                        <EnhancedTableHead order={order} onRequestSort={handleRequestSort} orderBy={orderBy} rowCount={data.length} />
                                        <TableBody>
                                            {stableSort(data, getComparator(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row, index) => {
                                                    return (
                                                        <TableRow
                                                            className={idx === row.id ? "table__bg" : ""}
                                                            style={{ cursor: "pointer" }}
                                                            hover
                                                            tabIndex={-1}
                                                            key={row.id}
                                                        >
                                                            <TableCell align="left">{row.id}</TableCell>
                                                            <TableCell align="left">{row.name}</TableCell>
                                                            <TableCell align="left">{row.position}</TableCell>
                                                            <TableCell align="left">{row.email}</TableCell>
                                                            <TableCell align="left">{row.phone}</TableCell>
                                                            <TableCell align="left">{row.start_date.slice(0, 10)}</TableCell>
                                                            <TableCell align="left">{row.end_date.slice(0, 10)}</TableCell>
                                                            <TableCell align="left">
                                                                <Action contactId={row.id} getData={getDataa} />
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    component="div"
                                    labelRowsPerPage={t("Rows per page")}
                                    rowsPerPageOptions={[5, 10, 25, 100]}
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </Box>
                    ) : null}
                </div>
            )}
        </div>
    );
}

export default React.memo(PerformanceTable);
