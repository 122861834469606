import Education from "./AplicantData/Education";
import WorkHistory from "./AplicantData/WorkHistory";
import Skills from "./AplicantData/Skills";

const ViewModal = ({ value }) => {
    return (
        <div className="w-100">
            {value ? (
                <>
                    <Education data={value.educations} />
                    <WorkHistory data={value.workHistories} />
                    <Skills data={value.skills} />
                </>
            ) : (
                <p className="error">An error occurred</p>
            )}
        </div>
    );
};

export default ViewModal;
