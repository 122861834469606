import axios from "axios";
import React from "react";
import REACT_APP from "../../environment";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ViewReport = ({ id }) => {
    const { t } = useTranslation();
    const qy = document.querySelector.bind(document);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const getFile = async () => {
        setLoading(true);
        setError(false);
        await axios(`${REACT_APP.URL}/api/hrp/v1/bgd/consumer/request/employee/${id}/report`, {
            headers: { Authorization: sessionStorage.getItem("token") },
            responseType: "blob",
        })
            .then((blob) => {
                setLoading(false);
                if (!blob) setError(true);
                else
                    setTimeout(() => {
                        qy("#v_report").src = window.URL.createObjectURL(blob.data);
                    });
            })
            .catch((err) => {
                setError(true);
                setLoading(false);
            });
    };

    useEffect(() => {
        getFile();
    }, [id]);

    return (
        <>
            <div className="modal fade" id="viewReport" tabIndex="-1" aria-labelledby="viewReportLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content bg-white">
                        <div className="modal-header py-1">
                            <h5 className="modal-title" id="viewReportLabel">
                                {t("File")}
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body file_md img-fluid">
                            {loading ? (
                                <div className="spinner-border text-info my-5" role="status">
                                    <span className="visually-hidden">{t("Loading...")}</span>
                                </div>
                            ) : error ? (
                                <h3 className="text-center py-5">{t("File not found")}</h3>
                            ) : (
                                // eslint-disable-next-line jsx-a11y/iframe-has-title
                                <iframe id="v_report" src="" alt="file" height="750" width="100%" />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ViewReport;
