import { Button, Typography, Card, CardContent, Alert } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import REACT_APP from "../environment";
import Spinner from "react-spinner-material";
import { useTranslation } from "react-i18next";

const Notifications = () => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const getData = async () => {
        setLoading(true);
        await axios
            .get(`${REACT_APP.URL}/api/hrp/v1/notification`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: sessionStorage.getItem("token"),
                },
            })
            .then((res) => {
                console.log(res.data.data);
                setLoading(false);
                setData(res.data.data);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
                setError(true);
                toast.error(t("Something went wrong"));
            });
    };

    useEffect(() => {
        getData();
    }, []);
    return (
        <>
            {!error && data.length === 0 && !loading && (
                <Alert variant="outlined" severity="warning">
                    No data yet
                </Alert>
            )}
            {error && (
                <Alert variant="outlined" severity="error">
                    An error occurred
                </Alert>
            )}
            {loading ? (
                <div
                    style={{
                        marginTop: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                </div>
            ) : (
                <div
                    style={{
                        background: "gray",
                        padding: "10px",
                        display: data.length ? "flex" : "none",
                        flexDirection: "column",
                    }}
                >
                    {data.length ? (
                        <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom" color={"ghostwhite"}>
                            Notifications
                        </Typography>
                    ) : null}
                    <div>
                        {data.map((value, index) => {
                            return (
                                <div item container spacing={2} xs={12} key={index}>
                                    <Card className="timeoff_card" sx={{ minWidth: 200 }}>
                                        <CardContent>
                                            <Typography gutterBottom variant="h6" component="div">
                                                {value.message}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {value.error}
                                            </Typography>
                                        </CardContent>
                                        <CardContent>
                                            <Typography gutterBottom variant="h6" component="div">
                                                {value.full?.first_name ? `${value.full.first_name} ${value.full.last_name} ${value?.full?.middle_name}` : null}
                                            </Typography>
                                        </CardContent>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            style={{
                                                float: "right",
                                                marginRight: "20px",
                                                marginBottom: "20px",
                                            }}
                                        >
                                            Go to
                                            <Link className="notes_link" to={value.page}>
                                                {/* {value.page} */}
                                                URL
                                            </Link>
                                        </Button>
                                    </Card>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </>
    );
};

export default Notifications;
