import React from "react";
import ConsumerHome from "../components/BGD/Home/Home";

function Consumer() {
    return (
        <div>
            <ConsumerHome />
        </div>
    );
}

export default Consumer;
