import Table from "./Table";

const Index = () => {
    return (
        <div>
            <Table />
        </div>
    );
};

export default Index;
