import React from "react";
import Index from "../components/Performance/Index";

const Performance = () => {
    return (
        <>
            <Index />
        </>
    );
};

export default Performance;
