import InsuranseProvider from "../components/InsuranseProvider/Index";

const InsuranseProviderPage = () => {
    return (
        <div className="container">
            <InsuranseProvider />
        </div>
    );
};

export default InsuranseProviderPage;
