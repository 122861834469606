import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EditDependencyModal from "./EditDependencyModal";
import { Button } from "@mui/material";
import axios from "axios";
import REACT_APP from "../../../environment";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function AccountMenu({ id, status, getData }) {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onApprove = () => {
        axios
            .post(`${REACT_APP.URL}/api/hrp/v1/dependents/approve/${id}`, {}, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                getData();
                handleClose();
                toast.success(t("Success"));
            })
            .catch((err) => toast.error(t("Something went wrong")));
    };

    const onReject = () => {
        axios
            .post(`${REACT_APP.URL}/api/hrp/v1/dependents/cancel/${id}`, {}, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                getData();
                handleClose();
                toast.success(t("Success"));
            })
            .catch((err) => toast.error(t("Something went wrong")));
    };

    return (
        <React.Fragment>
            <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                <Tooltip title="Account settings">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                    >
                        <Avatar sx={{ width: 32, height: 32 }}>A</Avatar>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                // onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <MenuItem>
                    <EditDependencyModal handleClosee={handleClose} id={id} getDataa={getData} />
                </MenuItem>
                <MenuItem>
                    <Button disabled={status !== 1} onClick={onApprove}>
                        Approve
                    </Button>
                </MenuItem>
                <MenuItem>
                    <Button disabled={status !== 1} onClick={onReject}>
                        Reject
                    </Button>
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}
