import React, { useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Grid, MenuItem } from "@mui/material";
import axios from "axios";
import REACT_APP from "../../../environment";
import { LoadingButton } from "@mui/lab";
import Diploma from "./Diploma";
import { Spin } from "antd";
import regex from "tts-hrp-val-lib";
import { useTranslation } from "react-i18next";
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

function TransitionsModal({ handleClosee, edu_id, getDataa }) {
    const { t } = useTranslation();
    const [uuid, setUuid] = React.useState("");
    const [view, setView] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [universitys, setUniversitys] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [empty, setEmpty] = React.useState();

    const validate = Yup.object({
        university_id: Yup.string().required(t("field is required")),
        major: Yup.string()
            .required(t("field is required"))
            .matches(regex.major, { message: t("invalid value") }),
        is_complete: Yup.string().required(t("field is required")),
        years_of_edu: Yup.string().required(t("field is required")),
        degree_type: Yup.string()
            .required(t("field is required"))
            .matches(regex.degree_type, { message: t("invalid value") }),
        diploma: Yup.string().optional().nullable(true),
        start_date: Yup.string().required(t("field is required")),
        end_date: Yup.date().when("is_complete", (is_complete, schema) => {
            if (is_complete === true || is_complete === "true") return schema.required(t("field is required")).nullable(true);
            return schema.optional().nullable(true);
        }),
    });

    const onSubmit = async (values) => {
        if (values?.is_complete === false) {
            values.end_date = null;
        }
        setLoading(true);
        await axios
            .put(`${REACT_APP.URL}/api/hrp/v1/employee/edit/education`, values, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: sessionStorage.getItem("token"),
                },
            })
            .then((res) => {
                setLoading(false);
                toast.success(t("Success"));
                handleClose();
                getDataa();
                handleClosee();
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("Something went wrong"));
            });
    };

    const getUniver = async () => {
        await axios
            .get(`${REACT_APP.URL}/api/hrp/v1/university`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => setUniversitys(res.data.data))
            .catch((err) => toast.error(t("Something went wrong")));
    };
    const getData = async () => {
        await axios
            .get(`${REACT_APP.URL}/api/hrp/v1/employee/edit/education/${edu_id}`, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setUuid(res.data.data.diploma);
                setEmpty(res.data.data);
                setTimeout(() => {
                    setView(true);
                }, 1000);
            })
            .catch((err) => toast.error(t("Something went wrong")));
    };
    useEffect(() => {
        getUniver();
        getData();
    }, []);

    return (
        <div className="addcompany">
            <Button onClick={handleOpen}>{t("Review")}</Button>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{ backdrop: { timeout: 500 } }}
            >
                <Fade in={open}>
                    <Box sx={style} className="res_modal">
                        <Button onClick={handleClose} variant="contained" color="primary" style={{ float: "right" }}>
                            <CloseIcon />
                        </Button>
                        <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                            {t("Education info")}
                        </Typography>
                        {view ? (
                            <Formik
                                initialValues={empty}
                                validationSchema={validate}
                                onSubmit={async (values) => {
                                    onSubmit(values);
                                    return new Promise((res) => setTimeout(res, 500));
                                }}
                            >
                                {({ values, errors }) => (
                                    <Form autoComplete="off" className="mt-4">
                                        <Grid item xs={12} className="marginBottom">
                                            <Field
                                                fullWidth
                                                select
                                                name="university_id"
                                                component={TextField}
                                                label={t("Select University")}
                                                disabled={edit ? false : true}
                                                size="small"
                                            >
                                                {universitys.map((item, index) => {
                                                    return (
                                                        <MenuItem value={item.id} key={index}>
                                                            {item.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Field>
                                        </Grid>
                                        <Grid item xs={12} className="marginBottom">
                                            <Field
                                                fullWidth
                                                label={t("Major")}
                                                name="major"
                                                component={TextField}
                                                autoComplete="major"
                                                type="text"
                                                disabled={edit ? false : true}
                                                size="small"
                                            />
                                        </Grid>
                                        <Grid item xs={12} className="marginBottom">
                                            <Field
                                                fullWidth
                                                select
                                                name="is_complete"
                                                component={TextField}
                                                label={t("is complete")}
                                                disabled={edit ? false : true}
                                                size="small"
                                            >
                                                <MenuItem value={true}>true</MenuItem>
                                                <MenuItem value={false}>false</MenuItem>
                                            </Field>
                                        </Grid>
                                        <Grid item xs={12} className="marginBottom">
                                            <Field
                                                fullWidth
                                                label={t("Years of edu")}
                                                name="years_of_edu"
                                                component={TextField}
                                                autoComplete="years_of_edu"
                                                type="number"
                                                disabled={edit ? false : true}
                                                size="small"
                                            />
                                        </Grid>
                                        <Grid item xs={12} className="marginBottom">
                                            <Field
                                                fullWidth
                                                label={t("Degree type")}
                                                name="degree_type"
                                                component={TextField}
                                                autoComplete="degree_type"
                                                type="text"
                                                disabled={edit ? false : true}
                                                size="small"
                                            />
                                        </Grid>
                                        <Grid item xs={12} className="marginBottom">
                                            {/* <Field
                        fullWidth
                        label={t("Diploma")}
                        name="diploma"
                        component={TextField}
                        autoComplete="diploma"
                        type="text"
                        disabled={edit ? false : true}
                      /> */}
                                            <Diploma uuid={uuid} empty={empty} getData={getData} />
                                        </Grid>
                                        {edit ? (
                                            <>
                                                <Grid item xs={12} className="marginBottom">
                                                    <label>{t("Start date")}</label>
                                                    <Field
                                                        fullWidth
                                                        name="start_date"
                                                        component={TextField}
                                                        autoComplete="start_date"
                                                        type="date"
                                                        size="small"
                                                    />
                                                </Grid>

                                                <Grid item xs={12} className="marginBottom">
                                                    <label>{t("End date")}</label>
                                                    <Field
                                                        fullWidth
                                                        name="end_date"
                                                        autoComplete="end_date"
                                                        component={TextField}
                                                        type="date"
                                                        disabled={edit ? false : true}
                                                        size="small"
                                                    />
                                                </Grid>
                                            </>
                                        ) : (
                                            <>
                                                <Grid item xs={12} className="marginBottom">
                                                    <label>{t("Start date")}</label>
                                                    <Field
                                                        fullWidth
                                                        name="start_date"
                                                        component={TextField}
                                                        autoComplete="start_date"
                                                        // type="date"
                                                        disabled={edit ? false : true}
                                                        size="small"
                                                    />
                                                </Grid>

                                                <Grid item xs={12} className="marginBottom">
                                                    <label>{t("End date")}</label>
                                                    <Field
                                                        fullWidth
                                                        name="end_date"
                                                        autoComplete="end_date"
                                                        component={TextField}
                                                        // type="date"
                                                        disabled={edit ? false : true}
                                                        size="small"
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                        <div className="left_right">
                                            <Button
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2 }}
                                                color="warning"
                                                onClick={() => setEdit((prev) => !prev)}
                                                size="small"
                                            >
                                                {edit ? <span>{t("Cancel edit")}</span> : <span>{t("Edit")}</span>}
                                            </Button>
                                            {loading ? (
                                                <LoadingButton loading variant="contained" sx={{ mt: 3, mb: 2 }} color="success" size="small">
                                                    {t("Saving")}
                                                </LoadingButton>
                                            ) : (
                                                <Button
                                                    disabled={!edit ? true : false}
                                                    type="submit"
                                                    variant="contained"
                                                    sx={{ mt: 3, mb: 2 }}
                                                    color="success"
                                                    size="small"
                                                >
                                                    {t("Save")}
                                                </Button>
                                            )}
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        ) : (
                            <div>
                                <Spin tip="Loading" size="large">
                                    <div className="content" />
                                </Spin>
                            </div>
                        )}
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default React.memo(TransitionsModal);
