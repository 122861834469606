import { useEffect, useState } from "react";
import REACT_APP from "../../environment";
import { toast } from "react-toastify";
import SetLogo from "./set-logo";
import Loading from "../Loading";
import { useTranslation } from "react-i18next";

const Index = () => {
    const { t } = useTranslation();
    const [src, setSrc] = useState(null);
    const [exist, setExist] = useState(null);
    const [loading, setLoading] = useState(true);

    async function getLogo() {
        setLoading(true);
        await fetch(`${REACT_APP.URL}/api/hrp/v1/company/logo`, {
            headers: { Authorization: sessionStorage.getItem("token") },
            method: "GET",
        })
            .then((res) => res.blob())
            .then((blob) => {
                if (blob.type === "application/json" && blob.size === 76) setExist(false);
                else {
                    setExist(true);
                    setSrc(window.URL.createObjectURL(blob));
                }
                setLoading(false);
            })
            .catch((err) => {
                toast.error(t("Something went wrong"))
                console.log(err);
                setLoading(false);
            });
    }
    useEffect(() => {
        getLogo();
    }, []);

    return (
        <div className="container">
            {loading ? (
                <Loading />
            ) : (
                <div>
                    <h1>{t("Company logo")}</h1>
                    <SetLogo exist={exist} getLogo={getLogo} />
                    <div className="logo-image">{exist && <img src={src} alt="logo" width="100%" />}</div>
                </div>
            )}
        </div>
    );
};

export default Index;
