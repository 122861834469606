import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Stepper from "./Stepper";

const ViewModal = ({ data }) => {
    return (
        <div className="modal fade" id="viewModal" tabIndex="-1" aria-labelledby="viewModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body p-2">
                        <div className="flex-column container mb-3 mt-2">
                            <div className="main-body">
                                <div className="w-100 d-flex justify-content-end mt-0 mb-2">
                                    <button data-bs-dismiss="modal" className="btn btn-outline-danger btn-sm">
                                        <CloseIcon />
                                    </button>
                                </div>
                                <div className="row gutters-sm">
                                    <div className="col-md-12">
                                        <div className="card mb-3">
                                            <div className="card-body">
                                                <div className="row lg-status">
                                                    <div className="col-sm-12">
                                                        <Stepper status={data.status} />
                                                    </div>
                                                </div>
                                                <hr className="lg-status" />
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <h6 className="mb-0">First Name</h6>
                                                    </div>
                                                    <div className="col-sm-8 text-secondary">{data.first_name}</div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <h6 className="mb-0">Last Name</h6>
                                                    </div>
                                                    <div className="col-sm-8 text-secondary">{data.last_name}</div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <h6 className="mb-0">Middle Name</h6>
                                                    </div>
                                                    <div className="col-sm-8 text-secondary">{data.middle_name}</div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <h6 className="mb-0">Birth Date</h6>
                                                    </div>
                                                    <div className="col-sm-8 text-secondary">{data.birth_date?.slice(0, 10)}</div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <h6 className="mb-0">Email</h6>
                                                    </div>
                                                    <div className="col-sm-8 text-secondary">{data.email}</div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <h6 className="mb-0">Phone Number</h6>
                                                    </div>
                                                    <div className="col-sm-8 text-secondary">{data.phone_number}</div>
                                                </div>
                                                {!data.INN ? null : (
                                                    <>
                                                        <hr />
                                                        <div className="row">
                                                            <div className="col-sm-4">
                                                                <h6 className="mb-0">INN</h6>
                                                            </div>
                                                            <div className="col-sm-8 text-secondary">{data.INN}</div>
                                                        </div>
                                                    </>
                                                )}
                                                <hr />
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <h6 className="mb-0">Report Languages</h6>
                                                    </div>
                                                    <div className="col-sm-8 text-secondary">
                                                        {data.language1}
                                                        {data.language2 && `, ${data.language2}`}
                                                        {data.language3 && `, ${data.language3}`}
                                                    </div>
                                                </div>
                                                <hr className="sm-status" />
                                                <div className="row sm-status">
                                                    <div className="col-sm-4">
                                                        <h6 className="mb-0">Status</h6>
                                                    </div>
                                                    <div className="col-sm-8 text-secondary">{data.status}</div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <h6 className="mb-0">Created Date</h6>
                                                    </div>
                                                    <div className="col-sm-8 text-secondary">{data.created_date?.slice(0, 10)}</div>
                                                </div>
                                                {!data.completed_date ? null : (
                                                    <>
                                                        <hr />
                                                        <div className="row">
                                                            <div className="col-sm-4">
                                                                <h6 className="mb-0">Completed Date</h6>
                                                            </div>
                                                            <div className="col-sm-8 text-secondary">{data.completed_date?.slice(0, 10)}</div>
                                                        </div>
                                                    </>
                                                )}
                                                {!data.report_generated_date ? null : (
                                                    <>
                                                        <hr />
                                                        <div className="row">
                                                            <div className="col-sm-4">
                                                                <h6 className="mb-0">Report Completed Date</h6>
                                                            </div>
                                                            <div className="col-sm-8 text-secondary">{data.report_generated_date?.slice(0, 10)}</div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(ViewModal);
