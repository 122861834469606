import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import REACT_APP from "../../environment";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import Paper from "@mui/material/Paper";
import Error from "../Error";
import axios from "axios";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: "id", numeric: false, label: "Id" },
    { id: "employee_id", numeric: true, label: "Employee ID" },
    { id: "first_name", numeric: true, label: "First Name" },
    { id: "last_name", numeric: true, label: "Last Name" },
    { id: "middle_name", numeric: true, label: "Middle Name" },
];

function EnhancedTableHead(props) {
    const { t } = useTranslation();
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells
                    .filter((headCell) => sessionStorage.getItem("branch_presence") === "true" || headCell.id !== "branch_name")
                    .map((headCell) => (
                        <TableCell key={headCell.id} align="left" sortDirection={orderBy === headCell.id ? order : false}>
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {t(headCell.label)}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EmployeesTable({ id }) {
    const { t } = useTranslation();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [page, setPage] = React.useState(0);
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [errorMsg, setErrorMsg] = React.useState(null);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    React.useEffect(() => {
        getDate();
    }, [id]);

    const getDate = async () => {
        setErrorMsg(null);
        setLoading(true);
        axios(`${REACT_APP.URL}/api/hrp/v1/hr/department/${id}/employees`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => {
                console.log(res.data);
                setData(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                setErrorMsg(err.message);
                setLoading(false);
            });
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            {loading ? (
                <div>
                    <Spin tip="Loading" size="large" style={{ marginTop: "150px" }}>
                        <div className="content" />
                    </Spin>
                </div>
            ) : errorMsg ? (
                <Error message={errorMsg} />
            ) : (
                <div className="py-5">
                    <Box sx={{ width: "100%" }}>
                        <Paper sx={{ width: "100%", mb: 2 }}>
                            <TableContainer>
                                <Table sx={{ minWidth: 750, px: 2 }} aria-labelledby="tableTitle" size="medium">
                                    <EnhancedTableHead order={order} onRequestSort={handleRequestSort} orderBy={orderBy} rowCount={data.length} />
                                    <TableBody>
                                        {stableSort(data, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                return (
                                                    <TableRow hover tabIndex={-1} key={row.id}>
                                                        <TableCell align="left">{row.id}</TableCell>
                                                        <TableCell align="left">{row.employee_id}</TableCell>
                                                        <TableCell align="left">{row.first_name}</TableCell>
                                                        <TableCell align="left">{row.last_name}</TableCell>
                                                        <TableCell align="left">{row.middle_name}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                component="div"
                                labelRowsPerPage={t("Rows per page")}
                                rowsPerPageOptions={[5, 10, 25, 100]}
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Box>
                </div>
            )}
        </>
    );
}

export default React.memo(EmployeesTable);
