import EmployeeSkill from "../components/EmployeeSkill/index";

const EmployeeSkillPage = () => {
    return (
        <div className="container">
            <EmployeeSkill />
        </div>
    );
};

export default EmployeeSkillPage;
