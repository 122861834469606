import { Button, Card, CardContent, CircularProgress, Grid } from "@mui/material";
import React, { useRef, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import REACT_APP from "../../environment";
import { toast } from "react-toastify";
import axios from "axios";
import "./style.css";
import { useTranslation } from "react-i18next";

const SetLogo = ({ exist, getLogo }) => {
    const { t } = useTranslation();
    const [file, setFile] = useState("");
    const [loading, setLoading] = useState(false);
    const closeRef = useRef(null);

    const submit = async (date) => {
        setLoading(true);
        const data = new FormData();
        data.append("file", file);
        axios
            .put(`${REACT_APP.URL}/api/hrp/v1/company/logo`, data, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setLoading(false);
                closeRef.current.click();
                getLogo();
                toast.success(t("Success"));
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("Something went wrong"));
            });
    };

    return (
        <div className="container">
            <Button className="float-end" variant="contained" color="primary" data-bs-toggle="modal" data-bs-target="#addModal">
                {exist ? t("Update logo") : t("Add logo")}
            </Button>

            <div className="modal fade" id="addModal" tabIndex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
                {loading ? <div className="hrp-overlay"></div> : null}
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5>{t("Logo")}</h5>
                        </div>
                        <div className="modal-body">
                            <Card style={{ width: "100%" }}>
                                <CardContent style={{ padding: "24px" }}>
                                    <div>
                                        <span htmlFor="file">{t("Choose logo to upload")}</span>
                                        <Grid item container spacing={2} xs={12}>
                                            <Grid item xs={12} sm={4}>
                                                <Button type="submit" variant="contained" disabled={loading}>
                                                    {t("Choose")}
                                                    <input
                                                        disabled={loading}
                                                        type="file"
                                                        id="il-file"
                                                        name="file"
                                                        accept="image/*"
                                                        onChange={(e) => setFile(e.target.files[0])}
                                                    />
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} sm={2}></Grid>
                                            <Grid item xs={12} sm={6}>
                                                <div className="li-div">
                                                    {!!file && <img alt="logo" src={window.URL.createObjectURL(file)} id="l-image" height={300} />}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div className="l-page">
                                        <Grid item xs={12}>
                                            <Button type="submit" variant="contained" className="float-end" disabled={!file || loading} onClick={submit}>
                                                {loading && <CircularProgress size="0.9rem" />}
                                                {t("Upload")}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                type="button"
                                                variant="contained"
                                                className="float-start"
                                                color="error"
                                                ref={closeRef}
                                                data-bs-dismiss="modal"
                                            >
                                                {t("Close")}
                                            </Button>
                                        </Grid>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(SetLogo);
