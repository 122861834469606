import React, { useEffect, useState } from "react";
import { Card, CardContent, CircularProgress, Grid, MenuItem, Typography } from "@mui/material";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import { Field, FieldArray, Form, Formik } from "formik";
import { educationSchema } from "./validateSchema";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import REACT_APP from "../../../environment";
import FileSaver from "file-saver";
import axios from "axios";
import { useTranslation } from "react-i18next";

export default function Education({ next }) {
    const { t } = useTranslation();
    const [edit, setEdit] = useState(true);
    const [id, setId] = useState(useParams().id);
    const [errorMsg, setErrorMsg] = useState(false);

    const [disable, setDisable] = useState(true);
    const [loading, setLoading] = useState(true);
    const [docType, setDocType] = useState(false);
    const [education, setEducation] = useState([]);
    const [loadingModal, setLoadingModal] = useState(false);
    const [imageLoading, setImageLoading] = useState(false);

    useEffect(() => {
        getEducation();
    }, []);

    const getEducation = () => {
        setLoading(true);
        axios(`${REACT_APP.ONB_URL}/api/hrp/v2/onb/adm/${id}/education`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => {
                setEducation(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                setErrorMsg(true);
                setLoading(false);
            });
    };

    const submit = (value, errors) => {
        setDisable(true);
        value.educations.forEach((el, i) => {
            const body = new FormData();
            body.append("id", el.id);
            body.append("university_id", el.university_id);
            body.append("major", el.major);
            body.append("is_complete", el.is_complete);
            body.append("years_of_edu", el.years_of_edu);
            body.append("degree_type", el.degree_type);
            body.append("start_date", el.start_date);
            body.append("end_date", el.end_date);
            body.append("diploma", el.diploma);
            if (!el.is_complete) {
                body.delete("end_date");
                body.delete("diploma");
            }

            axios
                .post(`${REACT_APP.ONB_URL}/api/hrp/v2/onb/edu/update`, body, {
                    headers: { Authorization: sessionStorage.getItem("token") },
                })
                .then((res) => {
                    setDisable(true);
                    next();
                })
                .catch((err) => {
                    setDisable(false);
                    toast.error(t("Something went wrong"));
                    return 0;
                });
        });
    };

    const getFile = (id) => {
        const response = async () => {
            setLoadingModal(true);
            setDocType(false);
            await fetch(`${REACT_APP.HRP_DOC_API}/file/${id}`, {
                headers: {
                    "client-id": REACT_APP.CLIENT_ID,
                    secret: REACT_APP.SECRET,
                    "Content-type": "application/pdf",
                },
            })
                .then((res) => res.blob())
                .then((blob) => {
                    if (blob.type === "application/pdf" || /image/.test(blob.type)) {
                        setLoadingModal(false);
                        setTimeout(() => {
                            document.querySelector("#education_doc").src = window.URL.createObjectURL(blob);
                        });
                    } else {
                        setLoadingModal(false);
                        setDocType(true);
                        setTimeout(() => {
                            FileSaver.saveAs(blob, "diploma");
                        });
                    }
                })
                .catch((err) => {});
        };
        response();
    };

    const deleteFile = (values, idx) => {
        if (values.educations[idx].diploma && values.educations[idx].is_complete) {
            setDisable(true);
            setImageLoading(true);
            axios
                .delete(`${REACT_APP.ONB_URL}/api/hrp/v2/onb/education/${values.educations[idx].id}`, {
                    headers: { Authorization: sessionStorage.getItem("token") },
                })
                .then((res) => {
                    setImageLoading(false);
                    setDisable(false);
                    getEducation();
                })
                .catch((err) => {
                    toast.error(t("Something went wrong"));
                    setImageLoading(false);
                    setDisable(false);
                    return 0;
                });
        }
    };

    const handleDiploma = (e, end_date, id) => {
        if (e.target.value) {
            setImageLoading(true);
            setDisable(true);
            const file = new FormData();
            file.append("id", id);
            file.append("end_date", end_date);
            file.append("diploma", e.target.files[0]);
            axios
                .patch(`${REACT_APP.ONB_URL}/api/hrp/v2/onb/education`, file, { headers: { Authorization: sessionStorage.getItem("token") } })
                .then((res) => {
                    setImageLoading(false);
                    setDisable(false);
                    getEducation();
                })
                .catch((err) => {
                    toast.error(t("Something went wrong"));
                    setImageLoading(false);
                    setDisable(false);
                    e.target.files[0] = "";
                    return 0;
                });
        }
    };

    return (
        <>
            <div className="d-flex justify-content-center flex-column container py-3">
                <div className="py-2">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="text-center" style={disable ? { color: "#aaa" } : {}}>
                                {t("Education")}
                            </h5>
                        </div>
                        {loading ? (
                            <div className="w-100 all__center py-5 my-5">
                                <CircularProgress />
                            </div>
                        ) : errorMsg ? (
                            <div className="w-100 all__center py-5 my-5">
                                <p style={{ color: "#ff0000", fontWeight: "550" }}>{t("An error occurred")}</p>
                            </div>
                        ) : education.length !== 0 ? (
                            <Card>
                                <CardContent style={{ padding: "24px" }}>
                                    <Formik
                                        initialValues={{ educations: education }}
                                        validationSchema={educationSchema}
                                        onSubmit={async (values) => {
                                            submit(values);
                                            return new Promise((res) => setTimeout(res, 500));
                                        }}
                                    >
                                        {({ values, errors, isSubmitting }) => (
                                            <Form autoComplete="off">
                                                <Grid container direction="column" spacing={2}>
                                                    <FieldArray name="educations">
                                                        {({ push, remove }) => (
                                                            <React.Fragment>
                                                                {values.educations?.map((_, index) => (
                                                                    <Grid container item key={index} spacing={2}>
                                                                        <Grid item container spacing={2} xs={12}>
                                                                            <Grid item xs={12} style={index === 0 ? { display: "none" } : { display: "block" }}>
                                                                                <p
                                                                                    className="text-center mt-2"
                                                                                    style={
                                                                                        disable ? { fontWeight: "500", color: "#aaa" } : { fontWeight: "500" }
                                                                                    }
                                                                                >
                                                                                    {t("Education")} {index + 1}
                                                                                </p>
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    select
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`educations.${index}.university_id`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Select University")}
                                                                                >
                                                                                    {/* to-do: translate */}
                                                                                    <MenuItem value="1">TATU</MenuItem>
                                                                                    <MenuItem value="2">aaa</MenuItem>
                                                                                    <MenuItem value="3">INHA</MenuItem>
                                                                                </Field>
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`educations[${index}].major`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Major")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`educations[${index}].years_of_edu`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Years of education")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={6} className="mt-sm-3">
                                                                                <label className="mat_label">{t("Date of enrollment")}</label>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    type="date"
                                                                                    name={`educations[${index}].start_date`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    format="dd/MM/yyyy"
                                                                                />
                                                                            </Grid>
                                                                            {_.is_complete && (
                                                                                <Grid item xs={12} sm={6} className="mt-sm-3">
                                                                                    <label className="mat_label">{t("Date of graduation")}</label>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        disabled={disable}
                                                                                        name={`educations[${index}].end_date`}
                                                                                        type="date"
                                                                                        variant="standard"
                                                                                        component={TextField}
                                                                                        format="dd/MM/yyyy"
                                                                                    />
                                                                                </Grid>
                                                                            )}

                                                                            <Grid item xs={12} sm={6} className="mt-sm-4">
                                                                                <Field
                                                                                    className="mt-sm-1"
                                                                                    select
                                                                                    fullWidth
                                                                                    disabled={disable}
                                                                                    name={`educations[${index}].degree_type`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label={t("Select your degree")}
                                                                                >
                                                                                    {/* to-do: translate */}
                                                                                    <MenuItem value="bachelor">Bachelor's</MenuItem>
                                                                                    <MenuItem value="master">Master's</MenuItem>
                                                                                    <MenuItem value="phd">PHD</MenuItem>
                                                                                </Field>
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={6} className="d-grid col-sm-8 col-md-6">
                                                                                <Field
                                                                                    name={`educations[${index}].is_complete`}
                                                                                    defaultValue={_.is_complete}
                                                                                    type="checkbox"
                                                                                    disabled={disable}
                                                                                    component={CheckboxWithLabel}
                                                                                    onClick={() => deleteFile(values, index)}
                                                                                    Label={{ label: t("Have you completed your education?") }}
                                                                                />
                                                                                {_.diploma && _.is_complete && (
                                                                                    <div className="w-100 d-flex align-items-end">
                                                                                        <div id="img_span" className="m-0">
                                                                                            <span
                                                                                                data-bs-toggle="modal"
                                                                                                data-bs-target="#eduDocModal"
                                                                                                onClick={() => getFile(_.diploma)}
                                                                                            >
                                                                                                {t("diploma")}
                                                                                            </span>
                                                                                            {imageLoading && <CircularProgress size="0.9rem" />}
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                                {!_.diploma && _.is_complete && _.end_date && (
                                                                                    <Field
                                                                                        fullWidth
                                                                                        className="col-md-6"
                                                                                        name={`educations[${index}].diploma`}
                                                                                        component={TextField}
                                                                                        type="file"
                                                                                        variant="standard"
                                                                                        disabled={disable}
                                                                                        id={`educations${index}diploma`}
                                                                                        startIcon={imageLoading && <CircularProgress size="0.9rem" />}
                                                                                        inputProps={{ accept: "application/msword, application/pdf" }}
                                                                                        onChange={(e) => handleDiploma(e, _.end_date, _.id)}
                                                                                    />
                                                                                )}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                ))}

                                                                <Grid item>
                                                                    {typeof errors.educations === "string" ? (
                                                                        <Typography color="error">{errors.educations}</Typography>
                                                                    ) : null}
                                                                </Grid>
                                                            </React.Fragment>
                                                        )}
                                                    </FieldArray>
                                                </Grid>
                                            </Form>
                                        )}
                                    </Formik>
                                </CardContent>
                            </Card>
                        ) : (
                            <div className="w-100 all__center py-5 my-5">
                                <p style={{ color: "#1976d2" }}>{t("Education is empty")}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="modal fade" id="eduDocModal" tabIndex="-1" aria-labelledby="eduDocModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content bg-white">
                        <div className="modal-header py-1">
                            <h5 className="modal-title" id="eduDocModalLabel">
                                {t("Diploma")}
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body img-fluid">
                            {loadingModal ? (
                                <div className="spinner-border text-info my-5" role="status">
                                    <span className="visually-hidden">{t("Loading...")}</span>
                                </div>
                            ) : docType ? (
                                <h2 className="m-auto my-5">{t("File downloaded")}!</h2>
                            ) : (
                                <iframe id="education_doc" src="" alt="" height="750" width="900" />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
