import React, { useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import REACT_APP from "../../environment";
import FileSaver from "file-saver";
import Spinner from "react-spinner-material";
import { useTranslation } from "react-i18next";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function ViewFile({ id, name }) {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [src, setSrc] = React.useState(null);
    const [file, setFile] = React.useState(null);
    const handleOpen = () => setOpen(true);
    const [device, setDevice] = React.useState(navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i));
    const handleClose = () => {
        setOpen(false);
    };

    function getLogo() {
        if (open)
            fetch(`${REACT_APP.URL}/api/hrp/v1/library/${id}`, {
                method: "GET",
                headers: { Authorization: sessionStorage.getItem("token") },
            })
                .then((res) => res.blob())
                .then((blob) => {
                    setFile(blob);
                    setSrc(window.URL.createObjectURL(blob));
                })
                .catch((err) => {
                    console.log(err);
                });
    }

    useEffect(() => {
        getLogo();
    }, [open]);

    function download() {
        FileSaver.saveAs(file, `${name}_${new Date().getTime()}`);
    }

    function downloadPhone() {
        fetch(`${REACT_APP.URL}/api/hrp/v1/library/${id}`, { method: "GET", headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => res.blob())
            .then((blob) => FileSaver.saveAs(blob, `${name}_${new Date().getTime()}`))
            .catch((err) => console.log(err));
    }
    window.addEventListener("resize", () => setDevice(navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i)));

    return (
        <div className="addhotel">
            {!device ? <Button onClick={handleOpen}>{t("View File")}</Button> : <Button onClick={downloadPhone}>{t("Download")}</Button>}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{ backdrop: { timeout: 500 } }}
            >
                <Fade in={open}>
                    <Box sx={style} className="addhotelmodal" style={{ height: "fit-content", width: "800px" }}>
                        {!src ? (
                            <div style={{ margin: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                            </div>
                        ) : (
                            <>
                                <iframe src={src} title="library" frameborder="0" style={{ width: "100%", height: "600px" }} />
                                <div className="left_right">
                                    <Button onClick={handleClose} variant="contained" sx={{ mt: 3, mb: 2 }} color="error">
                                        {t("Close")}
                                    </Button>
                                    <Button onClick={download} variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                        {t("Download")}
                                    </Button>
                                </div>
                            </>
                        )}
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
