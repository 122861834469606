import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import REACT_APP from "../../environment";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import axios from "axios";
import Spinner from "react-spinner-material";
import Alert from "@mui/material/Alert";
import AddModalTimeOff from "./AddModalTimeOff";
import TimeOffHistoryList from "./TimeOffHistoryList";
import { IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { t } = useTranslation();
    const headCells = [
        { id: "id", numeric: true, label: "Id" },
        { id: "name", numeric: true, label: "Vacation type" },
        { id: "paid", numeric: true, label: "Paid" },
        { id: "description", numeric: true, label: "Description" },
        { id: "action", numeric: true, label: "Action" },
    ];
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align="left" sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {t(headCell.label)}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTable() {
    const [data, setData] = React.useState([]);
    const [types, setTypes] = React.useState([]);
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [page, setPage] = React.useState(0);
    const [idx, setIdx] = React.useState(null);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const { t } = useTranslation();

    const getData = async () => {
        await axios
            .get(`${REACT_APP.URL}/api/hrp/v1/timeoff/types/active`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setLoading(false);
                setData(res.data.data);
            })
            .catch((err) => {
                setLoading(false);
                setError(true);
            });
    };

    const getTypes = async () => {
        await axios
            .get(`${REACT_APP.URL}/api/hrp/v1/timeoff/types`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => setTypes(res.data.data))
            .catch((err) => {});
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [anchor, setAnchor] = React.useState(null);

    const openMenu = (event, id) => {
        setIdx(id);
        setAnchor(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchor(null);
    };

    const handleDelete = async () => {
        await axios
            .delete(`${REACT_APP.URL}/api/hrp/v1/timeoff/types/active`, {
                headers: { Authorization: sessionStorage.getItem("token") },
                data: { id: idx.id },
            })
            .then((res) => {
                setAnchor(null);
                toast.success(t("Success"));
                getData();
            })
            .catch((error) => toast.error(t("Something went wrong")));
    };

    React.useEffect(() => {
        getData();
        getTypes();
    }, []);
    return (
        <>
            <div className="container">
                <h3>{t("Active types of time off")}</h3>
                <AddModalTimeOff getData={getData} types={types} />
                {loading ? (
                    <div style={{ marginTop: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                    </div>
                ) : (
                    <Box sx={{ width: "100%", marginTop: "20px" }}>
                        {!error && data.length === 0 && (
                            <Alert variant="outlined" severity="warning">
                                {t("No data yet")}
                            </Alert>
                        )}
                        {error && (
                            <Alert variant="outlined" severity="error">
                                {t("An error occurred")}
                            </Alert>
                        )}
                        {data.length >= 1 && (
                            <Paper sx={{ width: "100%", mb: 2 }}>
                                <TableContainer>
                                    <Table sx={{ minWidth: 750, px: 2 }} aria-labelledby="tableTitle" size="medium">
                                        <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={data.length} />
                                        <TableBody>
                                            {stableSort(data, getComparator(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row, index) => {
                                                    return (
                                                        <TableRow hover tabIndex={-1} key={row.id}>
                                                            <TableCell align="left">{row.id}</TableCell>
                                                            <TableCell align="left">{t(row.name)}</TableCell>
                                                            <TableCell align="left">{`${row.paid}`}</TableCell>
                                                            <TableCell align="left">{t(row.description)}</TableCell>
                                                            <TableCell>
                                                                <Box sx={{ flexGrow: 0 }}>
                                                                    <Tooltip title={t("Action")}>
                                                                        <IconButton onClick={(e, id = row) => openMenu(e, id)} sx={{ p: 0 }}>
                                                                            <MoreVertIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Menu
                                                                        sx={{ mt: "23px" }}
                                                                        id="menu-appbar"
                                                                        anchorOrigin={{ vertical: "top", horizontal: "left" }}
                                                                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                                                                        anchorEl={anchor}
                                                                        open={Boolean(anchor)}
                                                                        onClose={closeMenu}
                                                                    >
                                                                        <MenuItem onClick={handleDelete} id="option">
                                                                            <Typography>{t("Delete")}</Typography>
                                                                        </MenuItem>
                                                                    </Menu>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 100]}
                                    component="div"
                                    labelRowsPerPage={t("Rows per page")}
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        )}
                    </Box>
                )}
            </div>
            <TimeOffHistoryList />
        </>
    );
}

export default React.memo(EnhancedTable);
