import * as React from "react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { Button } from "@material-ui/core";
import Box from "@mui/material/Box";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import REACT_APP from "../../environment";
import Empty from "../Emptye";
import FolderIcon from "@mui/icons-material/Folder";
import Error from "../Error";
import { Spin } from "antd";
import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";

import { Avatar, Card, CardContent, CardMedia, CircularProgress, Divider, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const PerformanceComments = () => {
    const { t } = useTranslation();
    const [imgLoading, setImgLoading] = React.useState(true);
    const [error, setError] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState(null);
    const [goal, setGoal] = React.useState(null);
    const params = useParams();
    const navigate = useNavigate();
    const [comment, setComment] = React.useState([]);
    const [commentLoading, setCommentLoading] = React.useState(false);

    const getData = async () => {
        try {
            setLoading(true);
            const headers = { Authorization: sessionStorage.getItem("token") };
            const res = await axios(`${REACT_APP.URL}/api/hrp/v1/goal/performance/${params.id}`, { headers });
            const elem = res?.data?.data || {};

            setGoal(elem);
            const res2 = await axios(`${REACT_APP.URL}/api/hrp/v1/core-value/${elem.core_value_id}`, { headers });

            setData(res2?.data?.data || {});
            setImgLoading(true);
            setLoading(false);

            await axios(`${REACT_APP.URL}/api/hrp/v1/core-value/image/${res2.data.data.image}`, { responseType: "blob", headers })
                .then((response) => {
                    setImgLoading(false);
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        if (/image/.test(response.data.type)) window.document.getElementById("testt_img").src = `${event.target.result}`;
                    };
                    reader.readAsDataURL(response.data);
                })
                .catch((err) => {
                    toast.error(t("Something went wrong"));
                    setError(true);
                    setImgLoading(false);
                });
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };
    const gotoPerformance = () => navigate(`/performance`);
    const gotoGoals = () => navigate(`/review/${goal.employee_id}`);

    const getComment = () => {
        setCommentLoading(true);
        axios(`${REACT_APP.URL}/api/hrp/v1/goal/performance/${params.id}/comment`, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setComment(res.data.data);
                setCommentLoading(false);
            })
            .catch((err) => {
                setComment([]);
                setCommentLoading(false);
                toast.error(t("Something went wrong"));
            });
    };

    useEffect(() => {
        getData();
        getComment();
    }, []);

    return (
        <>
            <div className="tabs_box">
                <Box>
                    <Button onClick={gotoPerformance}>
                        <ArrowLeftIcon /> {t("Performance")}
                    </Button>

                    <Button onClick={goal ? gotoGoals : gotoPerformance}>
                        <ArrowLeftIcon /> {goal ? `${goal.first_name} ${goal.last_name}` : t("Back")}
                    </Button>
                    <br />
                    {loading ? (
                        <Spin style={{ marginTop: "65px" }} tip="Loading" size="large">
                            <div className="content" />
                        </Spin>
                    ) : errorMsg ? (
                        <Error message={errorMsg} />
                    ) : (
                        <div>
                            <Card sx={{ maxWidth: 345 }}>
                                <div className="p-3">
                                    {imgLoading ? (
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                                            <CircularProgress />
                                        </div>
                                    ) : error ? (
                                        <Alert variant="outlined" severity="error">
                                            {t("There was an error taking the picture")}
                                        </Alert>
                                    ) : (
                                        <CardMedia component="img" width="300px" id="testt_img" alt="img" />
                                    )}
                                </div>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {data?.name}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {data?.description}
                                    </Typography>
                                </CardContent>
                            </Card>
                            <List className="core_list" style={{ marginTop: "20px" }}>
                                <Divider />
                                <ListItem className="list_item">
                                    <ListItemAvatar>
                                        <Avatar>
                                            <FolderIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={goal?.goal_name} />
                                    <ListItemText primary={goal?.goal_details} />
                                    <ListItemText primary={t(goal?.status)} />
                                </ListItem>
                            </List>
                            <List className="core_list" style={{ marginTop: "20px" }}>
                                {comment.map((el) => (
                                    <>
                                        <Divider />
                                        <ListItem key={el.id} className="list_item">
                                            <p>{el.text}</p>
                                        </ListItem>
                                    </>
                                ))}
                            </List>
                        </div>
                    )}
                </Box>
            </div>
        </>
    );
};

export default PerformanceComments;
