import React, { useEffect, Fragment, useState } from "react";
import Loading from "../components/Loading";
import Error from "../components/Error";
import REACT_APP from "../environment";
import randomcolor from "randomcolor";
import axios from "axios";
import "./Home.css";
import Tooltip from "@mui/material/Tooltip";

const Card1 = (props) => {
    const levelColor = randomcolor();
    const avatarImg = "https://www.shalukparamadrasah.com/images/employess.jpg";
    const [data, setData] = useState(props.data);
    const getImage = (elem) => {
        fetch(`${REACT_APP.HRP_DOC_API}/file/${elem.image}`, {
            method: "GET",
            headers: {
                "client-id": REACT_APP.CLIENT_ID,
                secret: REACT_APP.SECRET,
                "Content-type": "application/pdf",
            },
        })
            .then((res) => res.blob())
            .then((blob) => {
                document.querySelector("#badge" + elem.id).src = window.URL.createObjectURL(blob);
            })
            .catch((err) => {});
    };

    const getEmployees = async (id) => {
        const item = data.find((e) => e.id === id);
        if (item && !item.employees)
            await axios(`${REACT_APP.URL}/api/hrp/v1/organization/head/${id}`, { headers: { Authorization: sessionStorage.getItem("token") } })
                .then((res) => {
                    res.data.data.employees.forEach((item) => (item.color = levelColor));
                    item.employees = res.data.data.employees;
                    const x = [...data];
                    setData(x);
                })
                .catch((err) => console.log(err));
    };

    // useEffect(() => {
    //     getEmployees()
    // }, []);

    return (
        <ul className="d-flex p-0">
            {data?.map((item, index) => (
                <Fragment key={index}>
                    <li className="noselect" style={{ cursor: item.employees ? "default" : "pointer" }} onDoubleClick={() => getEmployees(item.id)}>
                        <Tooltip title={`${item.employees ? item.employees.length : "↻"}`}>
                            <div className="card1">
                                <div className="image">
                                    {item.image ? getImage(item) : null}
                                    <img id={"badge" + item.id} src={avatarImg} alt="Profile" style={{ borderColor: item.color }} />
                                </div>
                                <div className="card1-body">
                                    <h4 className="H4">{item.first_name + " " + item.last_name}</h4>
                                </div>
                                <div className="card1-footer" style={{ background: item.color }}>
                                    <p style={{ padding: "0px", margin: "0px" }}>
                                        {item.position?.length > 13 ? <Tooltip title={item.position}>{item.position?.slice(0, 12)}..</Tooltip> : item?.position}
                                    </p>
                                </div>
                            </div>
                        </Tooltip>
                        {item?.employees?.length ? <Card1 data={item.employees} /> : null}
                    </li>
                </Fragment>
            ))}
        </ul>
    );
};

const Home = () => {
    const [loading, setLoading] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState(false);
    const [data, setData] = React.useState([]);

    const getEmployees = async () => {
        setLoading(true);
        await axios(`${REACT_APP.URL}/api/hrp/v1/organization/head`, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                res.data.data.head.color = randomcolor();
                setData([res.data.data.head]);
                setLoading(false);
            })
            .catch((err) => {
                setErrorMsg(true);
                setLoading(false);
            });
    };

    useEffect(() => {
        getEmployees();
    }, []);

    return (
        // <h1>Home</h1>
        <div className="d-flex justify-content-center">
            <div className="org-tree overflow-auto">
                {loading ? <Loading /> : errorMsg ? <Error message="Ann error occurred getting Organization" /> : <Card1 data={data}></Card1>}
            </div>
        </div>
    );
};

export default Home;
