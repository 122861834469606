import { Button, CircularProgress, Grid } from "@material-ui/core";
import React, { useRef, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import REACT_APP from "../../environment";
import { toast } from "react-toastify";
import axios from "axios";
import regex from "tts-hrp-val-lib";
import { useTranslation } from "react-i18next";

const AddPosition = ({ getDate }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const closeRef = useRef(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const Submit = async (date) => {
        setLoading(true);
        axios
            .post(`${REACT_APP.URL}/api/hrp/v1/position`, date, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setLoading(false);
                reset();
                getDate();
                closeRef.current.click();
                toast.success(t("Success"));
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("Something went wrong"));
            });
    };

    return (
        <div className="container">
            <Button className="float-end" variant="contained" color="primary" data-bs-toggle="modal" data-bs-target="#addPosition">
                {t("Add Position")}
            </Button>

            <div className="modal fade" id="addPosition" tabIndex="-1" aria-labelledby="addPositionLabel" aria-hidden="true">
                {loading ? <div className="hrp-overlay"></div> : null}
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body p-3">
                            <div className=" flex-column container">
                                <form onSubmit={handleSubmit(Submit)} className="container py-2 example row g-3">
                                    <Grid className="modal-header py-2 px-0">
                                        <h5>{t("Add Position")}</h5>
                                    </Grid>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item container spacing={3} xs={12} sm="auto" className="pt-4 mt-0">
                                            <Grid item xs={12} sm={12}>
                                                <label className="form-label mb-1 ms-1">{t("Position name")}</label>
                                                <input
                                                    type="text"
                                                    className={`${errors.position && "is-invalid"} form-control`}
                                                    {...register("position", { required: true, pattern: regex.work_position })}
                                                />
                                            </Grid>

                                            <Grid className="modal-footer mt-4 pt-3" item xs={12} sm={12}>
                                                <Button
                                                    className="me-3"
                                                    color={loading ? "inherit" : "secondary"}
                                                    variant="contained"
                                                    data-bs-dismiss="modal"
                                                    ref={closeRef}
                                                    onClick={() => reset()}
                                                >
                                                    {t("Cancel")}
                                                </Button>

                                                <Button
                                                    className="float-end"
                                                    disabled={loading}
                                                    color="primary"
                                                    type="submit"
                                                    variant="contained"
                                                    startIcon={loading && <CircularProgress size="0.9rem" />}
                                                >
                                                    {loading ? t("Saving") : t("Save")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(AddPosition);
