import React, { useState } from "react";
import { Card, CardContent, CircularProgress, Grid, MenuItem } from "@mui/material";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import { Field, FieldArray, Form, Formik } from "formik";
import { Empty } from "antd";

export default function Education({ data }) {
    const [loading, setLoading] = useState(true);

    return (
        <>
            <div className="d-flex justify-content-center flex-column container py-3">
                <div className="py-2">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="text-center" style={{ color: "#aaa" }}>
                                Education
                            </h5>
                        </div>
                        {data.length === 0 ? (
                            <div className="py-4">
                                <Empty />
                            </div>
                        ) : (
                            <Card>
                                <CardContent style={{ padding: "24px" }}>
                                    <Formik initialValues={{ educations: data }}>
                                        {({ values, errors, isSubmitting }) => (
                                            <Form autoComplete="off">
                                                <Grid container direction="column" spacing={2}>
                                                    <FieldArray name="educations">
                                                        {({ push, remove }) => (
                                                            <React.Fragment>
                                                                {values.educations?.map((_, index) => (
                                                                    <Grid container item key={index} spacing={2}>
                                                                        <Grid item container spacing={2} xs={12}>
                                                                            <Grid item xs={12} style={index === 0 ? { display: "none" } : { display: "block" }}>
                                                                                <p className="text-center mt-2" style={{ fontWeight: "500", color: "#aaa" }}>
                                                                                    Education {index + 1}
                                                                                </p>
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled
                                                                                    name={`educations[${index}].university`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label="University"
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled
                                                                                    name={`educations[${index}].major`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label="Major"
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={4}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled
                                                                                    name={`educations[${index}].years_of_edu`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label="Years of education"
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={6} className="mt-sm-3">
                                                                                <label className="mat_label">Date of enrollment</label>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled
                                                                                    type="date"
                                                                                    name={`educations[${index}].start_date`}
                                                                                    value={_.start_date?.slice(0, 10)}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    format="dd/MM/yyyy"
                                                                                />
                                                                            </Grid>
                                                                            {_.is_complete && (
                                                                                <Grid item xs={12} sm={6} className="mt-sm-3">
                                                                                    <label className="mat_label">Date of graduation</label>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        disabled
                                                                                        name={`educations[${index}].end_date`}
                                                                                        value={_.end_date?.slice(0, 10)}
                                                                                        type="date"
                                                                                        variant="standard"
                                                                                        component={TextField}
                                                                                        format="dd/MM/yyyy"
                                                                                    />
                                                                                </Grid>
                                                                            )}

                                                                            <Grid item xs={12} sm={6} className="mt-sm-4">
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled
                                                                                    name={`educations[${index}].degree_type`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label="Degree"
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={6} className="d-grid col-sm-8 col-md-6">
                                                                                <Field
                                                                                    name={`educations[${index}].is_complete`}
                                                                                    defaultValue={_.is_complete}
                                                                                    type="checkbox"
                                                                                    disabled
                                                                                    component={CheckboxWithLabel}
                                                                                    Label={{ label: "Have you completed your education?" }}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                ))}
                                                            </React.Fragment>
                                                        )}
                                                    </FieldArray>
                                                </Grid>
                                            </Form>
                                        )}
                                    </Formik>
                                </CardContent>
                            </Card>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
