import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import REACT_APP from "../../environment";
import CardContent from "@mui/material/CardContent";
import { toast } from "react-toastify";
import axios from "axios";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

function TransitionsModal({ getData, types, getHistory }) {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const onSubmit = async (id) => {
        setLoading(true);
        await axios
            .patch(
                `${REACT_APP.URL}/api/hrp/v1/timeoff/types/active`,
                { id },
                { headers: { "Content-Type": "application/json", Authorization: sessionStorage.getItem("token") } }
            )
            .then((res) => {
                setLoading(false);
                toast.success(t("Success"));
                handleClose();
                getData();
                getHistory();
            })
            .catch((error) => {
                setLoading(false);
                toast.error(t("Something went wrong"))
            });
    };

    return (
        <div className="addcompany">
            <Button onClick={handleOpen} variant="contained" style={{ float: "right" }}>
                {t("Add Time off")}
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{ backdrop: { timeout: 500 } }}
            >
                <Fade in={open}>
                    <Box sx={style} className="addcompanymodal res_modal overflow_scroll">
                        <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                            {t("All time off types")}
                        </Typography>
                        {types.map((value, index) => {
                            return (
                                <Grid item container spacing={2} xs={12} key={index}>
                                    {loading && <div className="screen_timeOff"></div>}
                                    <Card className="timeoff_card" sx={{ minWidth: 200 }}>
                                        <CardContent>
                                            <Typography gutterBottom variant="h6" component="div">
                                                {t(value.name)}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {t(value.description)}
                                            </Typography>
                                        </CardContent>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            style={{ float: "right", marginRight: "20px", marginBottom: "20px" }}
                                            onClick={() => onSubmit(value.id)}
                                        >
                                            {t("Add")}
                                        </Button>
                                    </Card>
                                </Grid>
                            );
                        })}
                        <div className="right_left">
                            <Button onClick={handleClose} variant="contained" sx={{ mt: 3, mb: 2 }} color="error" className="mt-5">
                                {t("Close")}
                            </Button>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default React.memo(TransitionsModal);
