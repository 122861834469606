import * as React from "react";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import { useParams } from "react-router-dom";
import Loading from "../Loading";
import Error from "../Error";
import REACT_APP from "../../environment";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import ProviderContactTable from "./DetailsContact";
import axios from "axios";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function PerformanceTable() {
    const { t } = useTranslation();
    const [data, setData] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [errorMsg, setErrorMsg] = React.useState(null);
    const [isWaiting, setIsWaiting] = React.useState(false);
    const params = useParams();

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setErrorMsg(null);
        setLoading(true);
        axios
            .get(`${REACT_APP.URL}/api/hrp/v1/insurance/provider/${params.id}`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setData(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                setErrorMsg(err.message);
                setLoading(false);
            });
    };

    const navigate = useNavigate();
    const onBack = () => {
        navigate("/hr/insurance/provider");
    };

    // subscribe
    const [openSub, setOpenSub] = React.useState(false);
    const handleClickOpenSub = () => {
        setOpenSub(true);
    };
    const handleCloseSub = () => {
        setOpenSub(false);
    };

    const subscribe = async () => {
        handleCloseSub();
        setIsWaiting(true);
        await axios
            .patch(`${REACT_APP.URL}/api/hrp/v1/insurance/provider/subscribe`, { id: data.id }, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then(() => {
                toast.success(t("Success"));
                getData();
            })
            .catch((err) => {
                console.log(err);
                toast.error(t("Something went wrong"));
            });
        setIsWaiting(false);
    };

    // unsubscribe
    const [openUnSub, setOpenUnSub] = React.useState(false);
    const handleClickOpenUnSub = () => {
        setOpenUnSub(true);
    };
    const handleCloseUnSub = () => {
        setOpenUnSub(false);
    };

    const unsubscribe = async () => {
        handleCloseUnSub();
        setIsWaiting(true);
        await axios
            .patch(
                `${REACT_APP.URL}/api/hrp/v1/insurance/provider/unsubscribe`,
                { id: data.id },
                { headers: { Authorization: sessionStorage.getItem("token") } }
            )
            .then(() => {
                toast.success(t("Success"));
                getData();
            })
            .catch((err) => {
                console.log(err);
                toast.error(t("Something went wrong"));
            });
        setIsWaiting(false);
    };

    return (
        <div className="container">
            {isWaiting && <div className="hrp-overlay"></div>}
            <Button className="mt-3" style={{ marginLeft: "16px" }} variant="contained" color="primary" onClick={onBack}>
                {t("Back")}
            </Button>
            {data.id && !data.subscribe && (
                <Button className="mt-3" style={{ marginRight: "16px", float: "right" }} variant="outlined" color="success" onClick={handleClickOpenSub}>
                    {t("Enroll")}
                </Button>
            )}
            {data.id && data.subscribe && (
                <Button className="mt-3" style={{ marginRight: "16px", float: "right" }} variant="outlined" color="success" onClick={handleClickOpenUnSub}>
                    {t("Unenroll")}
                </Button>
            )}
            {loading ? (
                <Loading />
            ) : errorMsg ? (
                <Error message={errorMsg} />
            ) : (
                <div className="pt-3">
                    <div className="d-flex justify-content-between row mb-2">
                        <div style={{ paddingLeft: "28px" }}>
                            <h1 className="text-center text-sm-start">{data.name}</h1>
                        </div>
                    </div>
                    <div>
                        {!(data.products?.length > 0) && (
                            <div style={{ paddingLeft: "16px" }}>
                                <h5>{t("No products available")}</h5>
                            </div>
                        )}
                        {data.products.map((product) => (
                            <Paper sx={{ width: "100%", p: 2, mb: 4, bgcolor: "whitesmoke" }}>
                                <h3>{product.name}</h3>
                                <p>{product.description}</p>
                                {product.types.map((type, index) => (
                                    <div className="my-2">
                                        <h5>{type.name}</h5>
                                        <p>{type.description}</p>
                                        <div>
                                            <span>{t("deductable amount")}: </span>
                                            <span>{type.deductable_amount}</span>
                                        </div>
                                        <div>
                                            <span>{t("insurance amount")}: </span>
                                            <span>{type.insurance_amount}</span>
                                        </div>
                                        {product.types.length !== index + 1 && <hr />}
                                    </div>
                                ))}
                            </Paper>
                        ))}
                    </div>
                    <div>
                        <ProviderContactTable contacts={data.contacts} />
                    </div>
                </div>
            )}
            <Dialog open={openSub} onClose={handleCloseSub} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{t("Enroll")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t("Are you sure you want to enroll?")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSub}>{t("Cancel")}</Button>
                    <Button onClick={subscribe}>{t("Enroll")}</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openUnSub} onClose={handleCloseUnSub} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{t("Unenroll")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t("Are you sure you want to unenroll?")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseUnSub}>{t("Cancel")}</Button>
                    <Button onClick={unsubscribe}>{t("Unenroll")}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default React.memo(PerformanceTable);
