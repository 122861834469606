import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Grid, MenuItem } from "@mui/material";
import Spinner from "react-spinner-material";
import REACT_APP from "../../../environment";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { Spin } from "antd";
import regex from "tts-hrp-val-lib";
import { useTranslation } from "react-i18next";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function TransitionsModal({ handleClosee, id, getDataa }) {
    const { t } = useTranslation();
    const [view, setView] = React.useState(false);
    const [edit, setEdit] = useState(false);
    const [data, setData] = useState();
    const [relationship, setRelationship] = React.useState([]);
    // eslint-disable-next-line
    const [loading, setLoading] = React.useState(false);
    const [wait, setWait] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const getRelationship = async () => {
        setWait(true);
        await axios
            .get(`${REACT_APP.URL}/api/hrp/v1/types/contact`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => setRelationship(res.data.data))
            .catch((err) => toast.error(t("Something went wrong")));
    };
    const getData = async () => {
        setWait(true);
        await axios
            .get(`${REACT_APP.URL}/api/hrp/v1/employee/edit/contact/${id}`, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setWait(false);
                setData(res.data.data);
                setTimeout(() => {
                    setView(true);
                }, 1000);
            })
            .catch((err) => {
                toast.error(t("Something went wrong"));
                setWait(false);
            });
    };

    const validateCompany = Yup.object({
        first_name: Yup.string()
            .required(t("field is required"))
            .matches(regex.file_name, { message: t("invalid value") }),
        last_name: Yup.string()
            .required(t("field is required"))
            .matches(regex.last_name, { message: t("invalid value") }),
        email: Yup.string().email(t("invalid value")).required(t("field is required")),
        phone: Yup.string()
            .required(t("field is required"))
            .matches(regex.phone, { message: t("invalid value") }),
        type_of_relationship: Yup.number().required(t("field is required")),
    });

    const emptyData = data;

    const onUpdate = async (values) => {
        // delete values.agreement_date;
        // if (!values?.website?.length) {
        //   delete values.website;
        // }
        setLoading(true);
        await axios
            .put(`${REACT_APP.URL}/api/hrp/v1/employee/edit/contact`, values, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: sessionStorage.getItem("token"),
                },
            })
            .then((res) => {
                setLoading(false);
                toast.success(t("Success"));
                getDataa();
                handleClose();
                handleClosee();
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("Something went wrong"));
            });
    };
    useEffect(() => {
        getData();
        getRelationship();
        // eslint-disable-next-line
    }, []);
    return (
        <div className="addcompany">
            <Button onClick={handleOpen}>{t("Review")}</Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} className="res_modal">
                        <Button onClick={handleClose} variant="contained" color="primary" style={{ float: "right" }}>
                            <CloseIcon />
                        </Button>
                        <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                            {t("Emergency contact info")}
                        </Typography>
                        {view ? (
                            <Formik
                                initialValues={emptyData}
                                validationSchema={validateCompany}
                                onSubmit={async (values) => {
                                    onUpdate(values);
                                    return new Promise((res) => setTimeout(res, 500));
                                }}
                            >
                                {({ values, errors }) => (
                                    <>
                                        {wait ? (
                                            <div style={{ marginTop: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                                            </div>
                                        ) : (
                                            <Form autoComplete="off" className="mt-4">
                                                <Grid item xs={12} className="marginBottom">
                                                    <Field
                                                        fullWidth
                                                        disabled={edit ? false : true}
                                                        label={t("First Name")}
                                                        name="first_name"
                                                        component={TextField}
                                                        autoComplete="first_name"
                                                        type="text"
                                                        size="small"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} className="marginBottom">
                                                    <Field
                                                        fullWidth
                                                        disabled={edit ? false : true}
                                                        label={t("Last Name")}
                                                        name="last_name"
                                                        component={TextField}
                                                        autoComplete="password"
                                                        type="text"
                                                        size="small"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} className="marginBottom">
                                                    <Field
                                                        fullWidth
                                                        disabled={edit ? false : true}
                                                        name="email"
                                                        label={t("Email")}
                                                        autoComplete="email"
                                                        component={TextField}
                                                        size="small"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} className="marginBottom">
                                                    <Field
                                                        fullWidth
                                                        disabled={edit ? false : true}
                                                        label={t("Phone")}
                                                        name="phone"
                                                        component={TextField}
                                                        type="text"
                                                        size="small"
                                                    />
                                                </Grid>
                                                {edit === true ? (
                                                    <Grid item xs={12} className="marginBottom">
                                                        <Field
                                                            fullWidth
                                                            select
                                                            name="type_of_relationship"
                                                            component={TextField}
                                                            label={t("Select Relationship")}
                                                            size="small"
                                                        >
                                                            {relationship.map((item, index) => {
                                                                return (
                                                                    <MenuItem defaultValue={data.relationship} value={item.id} key={index}>
                                                                        {item.relationship}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </Field>
                                                    </Grid>
                                                ) : (
                                                    <Grid item xs={12} className="marginBottom">
                                                        <Field
                                                            disabled={edit ? false : true}
                                                            fullWidth
                                                            name="relationship"
                                                            component={TextField}
                                                            label={t("Select Relationship")}
                                                            size="small"
                                                        ></Field>
                                                    </Grid>
                                                )}
                                                <div className="left_right">
                                                    <Button
                                                        variant="contained"
                                                        sx={{ mt: 3, mb: 2 }}
                                                        color="warning"
                                                        onClick={() => setEdit((prev) => !prev)}
                                                        size="small"
                                                    >
                                                        {edit ? <span>{t("Cancel edit")}</span> : <span>{t("Edit")}</span>}
                                                    </Button>
                                                    {loading ? (
                                                        <LoadingButton loading variant="contained" sx={{ mt: 3, mb: 2 }} color="success" size="small">
                                                            {t("Saving")}
                                                        </LoadingButton>
                                                    ) : (
                                                        <Button
                                                            disabled={!edit ? true : false}
                                                            type="submit"
                                                            variant="contained"
                                                            sx={{ mt: 3, mb: 2 }}
                                                            color="success"
                                                            size="small"
                                                        >
                                                            {t("Save")}
                                                        </Button>
                                                    )}
                                                </div>
                                            </Form>
                                        )}
                                    </>
                                )}
                            </Formik>
                        ) : (
                            <div>
                                <Spin tip="Loading" size="large">
                                    <div className="content" />
                                </Spin>
                            </div>
                        )}
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
