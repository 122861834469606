import React, { useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Button, CircularProgress, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import axios from "axios";
import REACT_APP from "../../environment";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const TerminateModal = ({ data, getDate }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const sendRef = useRef(null);
    const closeRef = useRef(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm();

    const Submit = async (date) => {
        const body = new FormData();
        body.append("image", date.file[0]);

        setLoading(true);
        axios
            .post(`${REACT_APP.URL}/api/hrp/v1/employee/terminate/${data.id}`, body, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                getDate();
                toast.success(t("Success"));
                closeRef.current.click();
                setLoading(false);
            })
            .catch((err) => {
                toast.error(t("Something went wrong"));
                setLoading(false);
            });
    };

    return (
        <div className="modal fade" id="viewModal" tabIndex="-1" aria-labelledby="viewModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body p-3">
                        <div className=" flex-column container">
                            <div className="w-100 d-flex justify-content-between my-0">
                                <h5 className="mt-1">{t("Termination evidence")}</h5>
                                <button data-bs-dismiss="modal" className="btn btn-outline-danger btn-sm">
                                    <CloseIcon />
                                </button>
                            </div>
                            <form onSubmit={handleSubmit(Submit)} className="pt-2 pb-3 row d-flex justify-content-center">
                                <div className="col-12 col-md-8 my-4">
                                    <input
                                        type="file"
                                        id="file"
                                        {...register("file", { required: true })}
                                        disabled={loading}
                                        className={errors.file ? "is-invalid form-control" : "form-control"}
                                    />
                                    <p className={errors.file ? "text-danger d-block mt-1" : "d-none"}>{t("File required")}</p>
                                </div>
                                <button type="submit" ref={sendRef} className="d-none"></button>
                            </form>
                            <div className="col-12">
                                <Button className="me-3" data-bs-dismiss="modal" onClick={() => reset()} variant="contained" color="secondary" ref={closeRef}>
                                    {t("Cancel")}
                                </Button>

                                <Button
                                    className="float-end"
                                    disabled={loading}
                                    color="primary"
                                    onClick={() => sendRef.current.click()}
                                    variant="contained"
                                    startIcon={loading && <CircularProgress size="0.9rem" />}
                                >
                                    {loading ? t("terminating") : t("terminate")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TerminateModal;
