import React from "react";
import cn from "classnames";
import "./stepper.css";

const Stepper = ({ status }) => {
    return (
        <div className="mdl-card mdl-shadow--2dp">
            <div className="mdl-card__supporting-text">
                <div className="mdl-stepper-horizontal-alternative">
                    <div className={cn("mdl-stepper-step step-done")}>
                        <div className="mdl-stepper-circle">
                            <span>1</span>
                        </div>
                        <div className="mdl-stepper-title">Registered</div>
                        <div className="mdl-stepper-bar-left"></div>
                        <div className="mdl-stepper-bar-right"></div>
                    </div>
                    <div className={cn("mdl-stepper-step", { "active-step": status === "registered" }, { "step-done": status === "completed" })}>
                        <div className="mdl-stepper-circle">
                            <span>2</span>
                        </div>
                        <div className="mdl-stepper-title">Pending</div>
                        <div className="mdl-stepper-bar-left"></div>
                        <div className="mdl-stepper-bar-right"></div>
                    </div>
                    <div className={cn("mdl-stepper-step", { "active-step": status === "completed" })}>
                        <div className="mdl-stepper-circle">
                            <span>{status === "completed" ? "✓" : "3"}</span>
                        </div>
                        <div className="mdl-stepper-title">Completed</div>
                        <div className="mdl-stepper-bar-left"></div>
                        <div className="mdl-stepper-bar-right"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(Stepper);
