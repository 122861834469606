import HolidaysTable from "./HolidaysTable";

const Index = () => {
    return (
        <div className="container my-5">
            <HolidaysTable />
        </div>
    );
};

export default Index;
