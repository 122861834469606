import React from "react";
import Employees from "../components/Employees";

const EmployeesPage = () => {
    return (
        <>
            <Employees />
        </>
    );
};

export default EmployeesPage;
