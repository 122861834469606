import { Box, Button, Modal } from "@mui/material";
import axios from "axios";
import React from "react";
import saveAs from "file-saver";
import { Spin } from "antd";
import REACT_APP from "../../../environment";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const style = {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    maxHeight: "95%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const Diploma = ({ uuid, empty, getData }) => {
    const { t } = useTranslation();
    const [file, setFile] = React.useState(null);
    const [unexpectedfile, setUnexpectedfile] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [opened, setOpened] = React.useState(false);
    const handleOpened = () => setOpened(!opened);
    const handleClosed = () => {
        setOpened(!opened);
    };

    const getFile = () => {
        setLoading(true);
        setError(false);
        setUnexpectedfile(false);
        axios
            .get(`https://tts-hrp-api-doc.herokuapp.com/file/${uuid}`, {
                responseType: "blob",
                headers: {
                    "client-id": "398970e4cfa7449eb02f68c7d181412343e4b82f838b5aa72691584e958c41c2",
                    secret: `1~BNt'[TN!1|%1W71*54>=S9&^AYaG(k,}'B6C8),Yj=zMkeCdHu8[H"|GBE;'du`,
                },
            })
            .then((response) => {
                setError(false);
                setLoading(false);
                const reader = new FileReader();
                reader.onload = (event) => {
                    if (response.data.type === "application/pdf" || /image/.test(response.data.type)) {
                        window.document.getElementById("test").src = `${event.target.result}`;
                    } else {
                        setUnexpectedfile(true);
                        saveAs(response.data);
                    }
                };
                reader.readAsDataURL(response.data);
            })
            .catch((err) => {
                setError(true);
                setLoading(false);
            });
    };

    const onSaveUuid = async (newuuid) => {
        if (empty?.diploma || !empty?.diploma) {
            empty.diploma = newuuid;
        }
        await axios
            .put(`${REACT_APP.URL}/api/hrp/v1/employee/edit/education`, empty, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: sessionStorage.getItem("token"),
                },
            })
            .then((res) => {
                getFile();
                getData();
                toast.success(t("Success"));
            })
            .catch((err) => {});
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!file) {
            toast.warning(t("Choose file!"));
            return;
        } else if (file) {
            const formData = new FormData();
            formData.append("file", file);
            axios
                .post(`https://tts-hrp-api-doc.herokuapp.com/file`, formData, {
                    headers: {
                        "client-id": "398970e4cfa7449eb02f68c7d181412343e4b82f838b5aa72691584e958c41c2",
                        secret: `1~BNt'[TN!1|%1W71*54>=S9&^AYaG(k,}'B6C8),Yj=zMkeCdHu8[H"|GBE;'du`,
                    },
                })
                .then((res) => {
                    setFile(null);
                    onSaveUuid(res.data.uuid);
                })
                .catch((err) => toast.error(t("Something went wrong")));
        } else {
            return toast.error(t("Something went wrong"));
        }
    };
    return (
        <div>
            <div className="container_diploma d-flex justify-content-between">
                <div className="container_item">
                    <Button
                        variant="contained"
                        color="success"
                        disabled={uuid?.length ? false : true}
                        onClick={() => {
                            handleOpened();
                            getFile();
                        }}
                    >
                        {t("View Diploma")}
                    </Button>
                    <Modal
                        keepMounted
                        open={opened}
                        onClose={handleClosed}
                        aria-labelledby="keep-mounted-modal-title"
                        aria-describedby="keep-mounted-modal-description"
                    >
                        <Box className="res_modal" sx={style} style={{ display: "flex", flexDirection: "column" }}>
                            {unexpectedfile && <h2 className="title_download">{t("File downloaded")}</h2>}
                            {error && <h2 className="title_download">{t("An error occurred")}</h2>}
                            {loading ? (
                                <div>
                                    <Spin className="mt-20" tip="Loading" size="large">
                                        <div className="content" />
                                    </Spin>
                                </div>
                            ) : (
                                <iframe style={{ height: "calc(100% - 30px)", minHeight: "80vh" }} id="test" title="test"></iframe>
                            )}

                            <button onClick={handleClosed} className="btn btn-danger">
                                {t("Close")}
                            </button>
                        </Box>
                    </Modal>
                </div>
                <div className="container_item">
                    <div>
                        <Button variant="contained" component="label">
                            {t("Select Diploma")}
                            <input onChange={(e) => setFile(e.target.files[0])} hidden accept="image/*" type="file" />
                        </Button>
                        <span>{file?.name}</span>
                    </div>
                </div>
                <div className="container_item">
                    <Button onClick={onSubmit} variant="contained" color="success" disabled={file ? false : true}>
                        {t("Submit Diploma")}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Diploma;
