import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import REACT_APP from "../../environment";
import { visuallyHidden } from "@mui/utils";
import Paper from "@mui/material/Paper";
import Error from "../Error";
import axios from "axios";
import Loading from "../Loading";
import ViewFile from "./ViewFile";
import SearchIcon from "@mui/icons-material/Search";
import Emptye from "../Emptye";
import { useTranslation } from "react-i18next";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { t } = useTranslation();
    const headCells = [
        { id: "id", numeric: false, label: t("Id") },
        { id: "name", numeric: true, label: t("Name") },
        { id: "acceptance_date", numeric: true, label: t("Issued Date") },
        { id: "action", numeric: true, label: t("Action") },
    ];
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align="left" sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label === "Action" && "      "}
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function LibraryFiles() {
    const [orderBy, setOrderBy] = React.useState("calories");
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [errorMsg, setErrorMsg] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [order, setOrder] = React.useState("asc");
    const [data, setData] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const { t } = useTranslation();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const getData = async (txt) => {
        setErrorMsg(null);
        setLoading(true);
        const search = txt ? "?search=" + txt : "";
        axios
            .get(`${REACT_APP.URL}/api/hrp/v1/library${search}`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setData(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                setErrorMsg(err.message);
                setLoading(false);
            });
    };

    const handleSearch = async (event) => {
        const x = event.target.value;
        setTimeout(() => {
            if (x === event.target.value) getData(x);
        }, 1000);
    };

    React.useEffect(() => {
        getData();
    }, []);

    return (
        <div className="container my-5">
            <div className="d-flex justify-content-between row pb-3">
                <div className="col-sm-4">
                    <h4 className="text-center text-sm-start">{t("Library")}</h4>
                </div>
                <div className="col-sm-4 position-relative">
                    <input onChange={handleSearch} style={{ paddingLeft: "35px" }} type="text" className="form-control" placeholder={t("Search")} />
                    <SearchIcon sx={{ position: "absolute", top: "8px", left: "20px", color: "#aaa", fontSize: "23px" }} />
                </div>
            </div>
            {loading ? (
                <Loading />
            ) : errorMsg ? (
                <Error message={errorMsg} />
            ) : data.length === 0 ? (
                <Emptye />
            ) : (
                <div>
                    <Box sx={{ width: "100%" }}>
                        <Paper sx={{ width: "100%", mb: 2 }}>
                            <TableContainer>
                                <Table sx={{ minWidth: 750, px: 2 }} aria-labelledby="tableTitle" size="medium">
                                    <EnhancedTableHead order={order} onRequestSort={handleRequestSort} orderBy={orderBy} rowCount={data.length} />
                                    <TableBody>
                                        {stableSort(data, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                return (
                                                    <TableRow hover tabIndex={-1} key={row.id}>
                                                        <TableCell align="left">{row.id}</TableCell>
                                                        <TableCell align="left">{row.name}</TableCell>
                                                        <TableCell align="left">&nbsp;{row.acceptance_date?.slice(0, 10)}</TableCell>
                                                        <TableCell align="left" className="op_menu">
                                                            <ViewFile id={row.id} name={row.name} />
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                labelRowsPerPage={t("Rows per page")}
                                component="div"
                                rowsPerPageOptions={[5, 10, 25, 100]}
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Box>
                </div>
            )}
        </div>
    );
}

export default React.memo(LibraryFiles);
