import React from "react";
import Index from "../components/Branches/Index";

const Branches = () => {
    return (
        <div>
            <Index />
        </div>
    );
};

export default Branches;
