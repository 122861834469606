import { Button, CircularProgress, Grid } from "@material-ui/core";
import React, { useRef, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import REACT_APP from "../../environment";
import { toast } from "react-toastify";
import axios from "axios";
import regex from "tts-hrp-val-lib";
import { useTranslation } from "react-i18next";

const AddCoreValue = ({ getDate }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const closeRef = useRef(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const Submit = async (date) => {
        // setLoading(true)
        const body = new FormData();
        body.append("name", date.name);
        body.append("value", date.value);
        body.append("image", document.querySelector("#file")?.files[0]);
        body.append("description", date.description);
        axios
            .post(`${REACT_APP.URL}/api/hrp/v1/core-value`, body, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setLoading(false);
                reset();
                getDate();
                closeRef.current.click();
                toast.success(t("Success"));
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("Something went wrong"));
            });
    };

    return (
        <div className="container">
            <Button className="float-end" variant="contained" color="primary" data-bs-toggle="modal" data-bs-target="#addModal">
                {t("Add Core Value")}
            </Button>

            <div className="modal fade" id="addModal" tabIndex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
                {loading ? <div className="hrp-overlay"></div> : null}
                <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body p-3">
                            <div className=" flex-column">
                                <form onSubmit={handleSubmit(Submit)} className="container py-2 example row g-3">
                                    <Grid className="modal-header py-2 px-0">
                                        <h5>{t("Add Core Value")}</h5>
                                    </Grid>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item container spacing={3} xs={12} sm="auto" className="pt-4 mt-0">
                                            <Grid item xs={12} sm={4}>
                                                <label className="form-label">{t("Name")}</label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.name ? "is-invalid" : ""}`}
                                                    {...register("name", { required: true, pattern: regex.core_value_name })}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <label className="form-label">{t("Value")}</label>
                                                <input
                                                    type="number"
                                                    {...register("value", { required: true })}
                                                    className={`form-control ${errors.value ? "is-invalid" : ""}`}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <label className="form-label">{t("Image")}</label>
                                                <input
                                                    id="file"
                                                    type="file"
                                                    {...register("image", { required: true })}
                                                    className={`form-control ${errors.image ? "is-invalid" : ""}`}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <label className="form-label">{t("Description")}</label>
                                                <textarea
                                                    rows="2"
                                                    className={`form-control ${errors.description ? "is-invalid" : ""}`}
                                                    {...register("description", {
                                                        required: true,
                                                        pattern: regex.description,
                                                    })}
                                                ></textarea>
                                            </Grid>

                                            <Grid className="modal-footer mt-4 pt-3" item xs={12} sm={12}>
                                                <Button
                                                    className="me-3"
                                                    color={loading ? "inherit" : "secondary"}
                                                    variant="contained"
                                                    data-bs-dismiss="modal"
                                                    ref={closeRef}
                                                    onClick={() => reset()}
                                                >
                                                    {t("Cancel")}
                                                </Button>

                                                <Button
                                                    className="float-end"
                                                    disabled={loading}
                                                    color="primary"
                                                    type="submit"
                                                    variant="contained"
                                                    startIcon={loading && <CircularProgress size="0.9rem" />}
                                                >
                                                    {loading ? t("Saving") : t("Save")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(AddCoreValue);
