import BranchesTable from "./BranchesTable";

const Index = () => {
    return (
        <div className="container">
            <BranchesTable />
        </div>
    );
};

export default Index;
