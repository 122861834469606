import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import REACT_APP from "../../../environment";
import saveAs from "file-saver";

const frameStyle = { width: "100%", height: "calc(100% - 30px)", minHeight: "80vh" };

const ReportBGD = ({ data }) => {
    const [loading, setLoading] = useState(false);
    const [sourceFile, setSourceFile] = useState(null);
    const [file, setFile] = useState(null);

    const getReport = () => {
        axios
            .get(`${REACT_APP.URL}/api/hrp/v1/bgd/consumer/request/${data.id}/report`, {
                responseType: "blob",
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((response) => {
                setLoading(false);
                const reader = new FileReader();
                reader.onload = (event) => setSourceFile(event.target.result);
                reader.readAsDataURL(response.data);
                setFile(response.data);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getReport();
    }, []);

    return (
        <div className="container">
            <div className="modal fade" id="editModal" tabIndex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
                {loading ? <div className="hrp-overlay"></div> : null}
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body p-3 d-flex flex-column">
                            <div className="w-100 d-flex justify-content-end mt-0 mb-2">
                                <button data-bs-dismiss="modal" className="btn btn-outline-danger btn-sm">
                                    <CloseIcon />
                                </button>
                            </div>
                            <hr />
                            <iframe src={sourceFile} style={frameStyle} id="report" title="report"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default React.memo(ReportBGD);
