import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import { IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LinearProgress from "@mui/material/LinearProgress";
import REACT_APP from "../../environment";
import { visuallyHidden } from "@mui/utils";
import Paper from "@mui/material/Paper";
import axios from "axios";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ViewReport from "./ViewReport";
import { useTranslation } from "react-i18next";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: "id", numeric: false, label: "Id" },
    { id: "first_name", numeric: true, label: "First Name" },
    { id: "last_name", numeric: true, label: "Last Name" },
    { id: "position", numeric: true, label: "Position" },
    { id: "phone", numeric: true, label: "Phone" },
    { id: "email", numeric: true, label: "Email" },
    { id: "tin", numeric: true, label: "TIN" },
    { id: "citizenship", numeric: true, label: "Citizenship" },
    { id: "status", numeric: true, label: "Status" },
    { id: "action", numeric: true, label: "Action" },
];

function EnhancedTableHead(props) {
    const { t } = useTranslation();
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align="left" sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {t(headCell.label)}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const OnbTable = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [idx, setIdx] = React.useState("");
    const [page, setPage] = React.useState(0);
    const [data, setData] = React.useState([]);
    const [order, setOrder] = React.useState("asc");
    const [loading, setLoading] = React.useState(true);
    const [errorMsg, setErrorMsg] = React.useState(null);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [orderBy, setOrderBy] = React.useState("calories");

    React.useEffect(() => {
        getDate();
    }, []);

    const getDate = async () => {
        setErrorMsg(null);
        setLoading(true);
        axios
            .get(`${REACT_APP.URL}/api/hrp/v1/onboarding`, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setData(res.data.data.employees);
                setLoading(false);
            })
            .catch((err) => {
                setErrorMsg(err.message);
                setLoading(false);
            });
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [anchor, setAnchor] = React.useState(null);
    const [anchor2, setAnchor2] = React.useState(null);

    const openMenu = (event, id) => {
        setIdx(id);
        setAnchor(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchor(null);
    };

    const closeAllModal = () => {
        setAnchor2(null);
        setAnchor(null);
    };

    const approveStatus = () => {
        axios
            .post(`${REACT_APP.URL}/api/hrp/v1/employee/approve/${idx.id}`, {}, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                closeAllModal();
                getDate();
                toast.success(t("Success"));
            })
            .catch((err) => toast.error(t("Something went wrong")));
    };

    const rejectStatus = () => {
        axios
            .post(
                `${REACT_APP.URL}/api/hrp/v1/employee/openPosition/onboardingRejected/${idx.id}`,
                {},
                { headers: { Authorization: sessionStorage.getItem("token") } }
            )
            .then((res) => {
                closeAllModal();
                getDate();
                toast.success(t("Success"));
            })
            .catch((err) => toast.error(t("Something went wrong")));
    };

    const pendingBgdStatus = () => {
        axios
            .post(`${REACT_APP.URL}/api/hrp/v1/employee/openPosition/bgdPending/${idx.id}`, {}, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                closeAllModal();
                getDate();
                toast.success(t("Success"));
            })
            .catch((err) => toast.error(t("Something went wrong")));
    };

    const sendBgdStatus = () => {
        axios
            .post(`${REACT_APP.URL}/api/hrp/v1/employee/sendBGD/${idx.id}`, {}, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                closeAllModal();
                getDate();
                toast.success(t("Success"));
            })
            .catch((err) => toast.error(t("Something went wrong")));
    };

    return (
        <>
            {loading ? (
                <Loading />
            ) : errorMsg ? (
                <Error message={errorMsg} />
            ) : (
                <div className="container py-5">
                    <Box sx={{ width: "100%" }}>
                        <Paper sx={{ width: "100%", mb: 2 }}>
                            <TableContainer>
                                {loading ? (
                                    <LinearProgress />
                                ) : (
                                    <Table sx={{ minWidth: 750, px: 2 }} aria-labelledby="tableTitle" size="medium">
                                        <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={data.length} />
                                        <TableBody>
                                            {stableSort(data, getComparator(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row, index) => {
                                                    return (
                                                        <TableRow hover tabIndex={-1} key={row.id}>
                                                            <TableCell align="left">{row.id}</TableCell>
                                                            <TableCell align="left">{row.first_name}</TableCell>
                                                            <TableCell align="left">{row.last_name}</TableCell>
                                                            <TableCell align="left">{row.position}</TableCell>
                                                            <TableCell align="left">{row.cell_phone}</TableCell>
                                                            <TableCell align="left">{row.profile_email}</TableCell>
                                                            <TableCell align="left">{row.tin}</TableCell>
                                                            <TableCell align="left">{row.citizenship}</TableCell>
                                                            <TableCell align="left">{t(row.status)}</TableCell>
                                                            <TableCell align="left">
                                                                <Box sx={{ flexGrow: 0 }}>
                                                                    <Tooltip title={t("Action")}>
                                                                        <IconButton onClick={(e, id = row) => openMenu(e, id)} sx={{ p: 0 }}>
                                                                            <MoreVertIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Menu
                                                                        sx={{ mt: "23px" }}
                                                                        id="menu-appbar"
                                                                        anchorOrigin={{ vertical: "top", horizontal: "left" }}
                                                                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                                                                        anchorEl={anchor}
                                                                        open={Boolean(anchor)}
                                                                        onClose={closeMenu}
                                                                    >
                                                                        <MenuItem
                                                                            onClick={() => {
                                                                                navigate(`/onboarded/${idx.id}`);
                                                                            }}
                                                                        >
                                                                            <Typography>{t("View")}</Typography>
                                                                        </MenuItem>
                                                                        <MenuItem onClick={(e) => setAnchor2(e.currentTarget)}>
                                                                            <Typography>{t("Status")}</Typography>
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                            onClick={(e) => setAnchor(null)}
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#viewReport"
                                                                        >
                                                                            <Typography>{t("File")}</Typography>
                                                                        </MenuItem>
                                                                    </Menu>
                                                                    <Menu
                                                                        sx={{ ml: "-76px" }}
                                                                        id="menu-appbar"
                                                                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                                                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                                                                        anchorEl={anchor2}
                                                                        open={Boolean(anchor2)}
                                                                        onClose={() => setAnchor2(null)}
                                                                    >
                                                                        <MenuItem
                                                                            disabled={idx.status !== "onboarding_completed"}
                                                                            onClick={() => sendBgdStatus()}
                                                                        >
                                                                            <Typography>{t("Send BGD")}</Typography>
                                                                        </MenuItem>
                                                                        <MenuItem disabled={idx.status !== "bgd_initiated"} onClick={() => pendingBgdStatus()}>
                                                                            <Typography>{t("Pending BGD")}</Typography>
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                            disabled={idx.status !== "bgd_pending" && idx.status !== "onboarding_completed"}
                                                                            onClick={() => approveStatus()}
                                                                        >
                                                                            <Typography>{t("Approved")}</Typography>
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                            disabled={idx.status !== "onboarding_completed" && idx.status !== "bgd_pending"}
                                                                            onClick={() => rejectStatus()}
                                                                        >
                                                                            <Typography>{t("Rejected")}</Typography>
                                                                        </MenuItem>
                                                                    </Menu>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                )}
                            </TableContainer>
                            {!errorMsg && (
                                <TablePagination
                                    labelRowsPerPage={t("Rows per page")}
                                    rowsPerPageOptions={[5, 10, 25, 100]}
                                    component="div"
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            )}
                        </Paper>
                    </Box>
                    <ViewReport id={idx?.id} />
                </div>
            )}
        </>
    );
};

export default OnbTable;
