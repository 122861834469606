import React, { useEffect, useRef, useState } from "react";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import REACT_APP from "../../environment";
import { useForm } from "react-hook-form";
import axios from "axios";
import regex from "tts-hrp-val-lib";
import { useTranslation } from "react-i18next";

const AddPosition = ({ getDate, setShow }) => {
    const { t } = useTranslation();
    const [jobType, setJobType] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [branchPresence, setBranchPresence] = useState(sessionStorage.getItem("branch_presence") === "true");
    const [branches, setBranches] = useState([]);
    const [loading, setLoading] = useState(false);
    const [empArrange, setEmpArrange] = useState([]);
    const closeRef = useRef(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    useEffect(() => {
        getJobType();
        getBranches();
        getEmployeeArrangement();
    }, []);

    const Submit = async (data) => {
        const nowDate = new Date().getTime();
        if (new Date(data.expiration_date).getTime() < nowDate) {
            toast.warning(t("Expiration date must be greeter than current date!"));
            return;
        }
        if (!branchPresence) data.branch_id = branches[0].id;
        setLoading(true);
        axios
            .post(`${REACT_APP.URL}/api/hrp/v1/employee/openPosition`, data, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setLoading(false);
                closeRef.current.click();
                toast.success(t("Success"));
                reset();
                getDate();
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("Something went wrong"));
            });
    };

    const getJobType = () => {
        axios(`${REACT_APP.URL}/api/hrp/v1/employee/openPosition/job_types`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => setJobType(res.data.data))
            .catch((err) => {});
    };

    const getDepartments = (id) => {
        setShow(true);
        axios(`${REACT_APP.URL}/api/hrp/v1/organization/${id}/departments`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => {
                setDepartments(res.data.data);
                setShow(false);
            })
            .catch((err) => setShow(false));
    };

    const getBranches = () => {
        axios(`${REACT_APP.URL}/api/hrp/v1/organization/branches`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => {
                if (!branchPresence) getDepartments(res.data.data[0].id);
                setBranches(res.data.data);
            })
            .catch((err) => {});
    };

    const getEmployeeArrangement = () => {
        axios(`${REACT_APP.URL}/api/hrp/v1/types/employment-type`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => setEmpArrange(res.data.data))
            .catch((err) => {});
    };

    return (
        <div className="container">
            <Button className="float-end" variant="contained" color="primary" data-bs-toggle="modal" data-bs-target="#addModal">
                {t("Add Position")}
            </Button>

            <div className="modal fade" id="addModal" tabIndex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
                {loading ? <div className="hrp-overlay"></div> : null}
                <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body p-3">
                            <div className=" flex-column container">
                                <form onSubmit={handleSubmit(Submit)} className="container py-2 example row g-3">
                                    <Grid className="modal-header py-2 px-0">
                                        <h5>{t("Add Position")}</h5>
                                    </Grid>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item container spacing={3} xs={12} sm="auto" className="pt-4 mt-0">
                                            <Grid item xs={12} sm={6}>
                                                <input
                                                    type="text"
                                                    placeholder={t("Name")}
                                                    disabled={loading}
                                                    className={errors.name ? "is-invalid form-control" : "form-control"}
                                                    {...register("name", { required: true, pattern: regex.work_position })}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <select
                                                    className={errors.type ? "is-invalid form-select" : "form-select"}
                                                    {...register("type", { required: true })}
                                                    disabled={loading}
                                                >
                                                    <option value="">{t("Select type")}</option>
                                                    <option value="internal">{t("Internal")}</option>
                                                    <option value="external">{t("External")}</option>
                                                </select>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <select
                                                    className={errors.job_type ? "is-invalid form-select" : "form-select"}
                                                    {...register("job_type", { required: true })}
                                                    disabled={loading}
                                                >
                                                    <option value="">{t("Select job type")}</option>
                                                    {jobType.map((el) => (
                                                        <option key={el.id} value={el.id}>
                                                            {el.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <select
                                                    className={errors.employee_arrangement ? "is-invalid form-select" : "form-select"}
                                                    {...register("employee_arrangement", { required: true })}
                                                    disabled={loading}
                                                >
                                                    <option value="">{t("Select employee Arrangement")}</option>
                                                    {empArrange.map((el) => (
                                                        <option key={el.id} value={el.id}>
                                                            {t(el.name)}
                                                        </option>
                                                    ))}
                                                </select>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <input
                                                    type="text"
                                                    placeholder={t("Expiration date")}
                                                    disabled={loading}
                                                    onFocus={(e) => (e.target.type = "date")}
                                                    onBlur={(e) => (e.target.type = "text")}
                                                    className={errors.expiration_date ? "is-invalid form-control" : "form-control"}
                                                    {...register("expiration_date", { required: true })}
                                                />
                                            </Grid>
                                            {branchPresence && (
                                                <Grid item xs={12} sm={4}>
                                                    <select
                                                        className={errors.branch_id ? "is-invalid form-select" : "form-select"}
                                                        {...register("branch_id", {
                                                            required: true,
                                                            onChange: (e) => {
                                                                getDepartments(e.target.value);
                                                            },
                                                        })}
                                                        disabled={loading}
                                                    >
                                                        <option value="">{t("Select branch")}</option>
                                                        {branches.map((el) => (
                                                            <option key={el.id} value={el.id}>
                                                                {el.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </Grid>
                                            )}
                                            <Grid item xs={12} sm={branchPresence ? 4 : 6}>
                                                <select
                                                    className={errors.department_id ? "is-invalid form-select" : "form-select"}
                                                    {...register("department_id", { required: true })}
                                                    disabled={loading}
                                                >
                                                    <option value="">{t("Select department")}</option>
                                                    {departments?.map((el) => (
                                                        <option key={el.id} value={el.id}>
                                                            {el.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </Grid>
                                            <Grid item xs={12} sm={branchPresence ? 4 : 6}>
                                                <input
                                                    type="number"
                                                    placeholder={t("Number of employee")}
                                                    disabled={loading}
                                                    className={errors.number ? "is-invalid form-control" : "form-control"}
                                                    {...register("number", { required: true })}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12}>
                                                <textarea
                                                    rows="2"
                                                    placeholder={t("Requirement")}
                                                    disabled={loading}
                                                    className={errors.requirement ? "is-invalid form-control" : "form-control"}
                                                    {...register("requirement", { required: true, pattern: regex.description })}
                                                ></textarea>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <textarea
                                                    rows="2"
                                                    placeholder={t("Full job description")}
                                                    disabled={loading}
                                                    className={errors.full_job_description ? "is-invalid form-control" : "form-control"}
                                                    {...register("full_job_description", {
                                                        required: true,
                                                        pattern: regex.description,
                                                    })}
                                                ></textarea>
                                            </Grid>

                                            <Grid className="modal-footer mt-4 pt-3" item xs={12} sm={12}>
                                                <Button
                                                    className="me-3"
                                                    color={loading ? "inherit" : "secondary"}
                                                    variant="contained"
                                                    data-bs-dismiss="modal"
                                                    ref={closeRef}
                                                >
                                                    {t("Cancel")}
                                                </Button>

                                                <Button
                                                    className="float-end"
                                                    disabled={loading}
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={loading && <CircularProgress size="0.9rem" />}
                                                >
                                                    {loading ? t("Saving") : t("Save")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(AddPosition);
