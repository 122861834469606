import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import REACT_APP from "../../../environment";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Skill({ next }) {
    const { t } = useTranslation();
    // const [edit, setEdit] = useState(true);
    const [id, setId] = useState(useParams().id);
    const [loading, setLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState(false);

    const [getAll, setGetAll] = useState([]);
    const [expert, setExpert] = useState([]);
    const [beginner, setBeginner] = useState([]);
    const [advanced, setAdvanced] = useState([]);
    const [proficient, setProficient] = useState([]);

    useEffect(() => {
        getSkills();
    }, []);

    const getSkills = () => {
        axios(`${REACT_APP.ONB_URL}/api/hrp/v2/onb/adm/${id}/skill`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => {
                setGetAll(res.data.data);
                res.data.data.forEach((el) => {
                    if (el.level === 1) setBeginner((prev) => [...prev, el]);
                    if (el.level === 2) setAdvanced((prev) => [...prev, el]);
                    if (el.level === 3) setProficient((prev) => [...prev, el]);
                    if (el.level === 4) setExpert((prev) => [...prev, el]);
                });
                setLoading(false);
            })
            .catch((err) => {
                setErrorMsg(true);
                setLoading(false);
            });
    };

    return (
        <div className="d-flex justify-content-center flex-column container my-2">
            <div id="revSkills" className="row g-3">
                <div className="">
                    <div className="card form1">
                        <div className="card-header">
                            <h5 className="text-center" style={{ color: "#aaa" }}>
                                {t("Skills")}
                            </h5>
                        </div>
                        <div className="card-body py-3 mb-4">
                            {loading ? (
                                <div className="w-100 all__center py-5 my-5">
                                    <CircularProgress />
                                </div>
                            ) : errorMsg ? (
                                <div className="w-100 all__center py-5 my-5">
                                    <p style={{ color: "#ff0000", fontWeight: "550" }}>{t("An error occurred")}</p>
                                </div>
                            ) : getAll.length !== 0 ? (
                                <div className="form-group row g-3">
                                    {beginner.length !== 0 && (
                                        <div className="col-md-6 my-4">
                                            <label className="form-label skill_lbl">{t("Beginner")}</label>
                                            <div className="card-brd w-100 h-100 p-2">
                                                {beginner.map((el, i) => (
                                                    <span key={i} className="text-success m-1 rounded float-start d-flex align-items-center">
                                                        {el.name}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    {advanced.length !== 0 && (
                                        <div className="col-md-6 my-4">
                                            <label className="form-label skill_lbl">{t("Advanced")}</label>
                                            <div className="card-brd w-100 h-100 p-2">
                                                {advanced.map((el, i) => (
                                                    <span key={i} className="text-success m-1 rounded float-start d-flex align-items-center">
                                                        {el.name}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    {proficient.length !== 0 && (
                                        <div className="col-md-6 my-4">
                                            <label className="form-label skill_lbl">{t("Proficient")}</label>
                                            <div className="card-brd w-100 h-100 p-2">
                                                {proficient.map((el, i) => (
                                                    <span key={i} className="text-success m-1 rounded float-start d-flex align-items-center">
                                                        {el.name}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    {expert.length !== 0 && (
                                        <div className="col-md-6 my-4">
                                            <label className="form-label skill_lbl">{t("Expert")}</label>
                                            <div className="card-brd w-100 h-100 p-2">
                                                {expert.map((el, i) => (
                                                    <span key={i} className="text-success m-1 rounded float-start d-flex align-items-center">
                                                        {el.name}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="w-100 all__center py-5 my-5">
                                    <p style={{ color: "#1976d2" }}>{t("Skills are empty")}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
