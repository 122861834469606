const NODE_ENV = process.env.REACT_APP_ENV || "production";
// scp -r build root@159.223.217.246:/var/www/tts-admin

const modes = {
    development: {
        AUTH: "http://188.166.84.222:9100",
        URL: "http://209.38.204.67:9101",
        ONB_URL: "http://209.38.204.67:9104",
        HRP_DOC_API: "http://159.223.205.127:9103",
        CLIENT_ID: "398970e4cfa7449eb02f68c7d181412343e4b82f838b5aa72691584e958c41c2",
        SECRET: "1~BNt'[TN!1|%1W71*54>=S9&^AYaG(k,}'B6C8),Yj=zMkeCdHu8[H\"|GBE;'du",
        LOGIN: "http://174.138.2.211:9100",
        COMMON_API: "https://tts-common-api.herokuapp.com",
        COMMON_ID: "74a7a67458d77c3699a2db5132df8506afea37b31081987585d74724db98c1eb",
        COMMON_SECRET: 'I."b^34+Q>X\\"bHW.^9,6~=PtZsOn5F49kdWolPIlS=BW}9@N\\DGRxA9gNeHI}q{',
        TIMESHEET_URL: "http://143.244.173.81:9102",
    },
    qa: {
        AUTH: "https://api1-qa.ishkuni.uz",
        URL: "https://api4-qa.ishkuni.uz",
        ONB_URL: "https://api5-qa.ishkuni.uz",
        LOGIN: "https://auth-qa.ishkuni.uz",
        HRP_DOC_API: "https://api3-qa.ishkuni.uz",
        CLIENT_ID: "398970e4cfa7449eb02f68c7d181412343e4b82f838b5aa72691584e958c41c2",
        SECRET: "1~BNt'[TN!1|%1W71*54>=S9&^AYaG(k,}'B6C8),Yj=zMkeCdHu8[H\"|GBE;'du",
        COMMON_API: "https://tts-common-api.herokuapp.com",
        COMMON_ID: "74a7a67458d77c3699a2db5132df8506afea37b31081987585d74724db98c1eb",
        COMMON_SECRET: 'I."b^34+Q>X\\"bHW.^9,6~=PtZsOn5F49kdWolPIlS=BW}9@N\\DGRxA9gNeHI}q{',
        TIMESHEET_URL: "https://tms-qa.ishkuni.uz",
    },
    production: {
        AUTH: "https://api.ishkunim.uz",
        URL: "https://api4.ishkuni.uz",
        ONB_URL: "https://api5.ishkuni.uz",
        LOGIN: "https://ishkunim.uz",
        HRP_DOC_API: "https://api3.ishkuni.uz",
        CLIENT_ID: "398970e4cfa7449eb02f68c7d181412343e4b82f838b5aa72691584e958c41c2",
        SECRET: "1~BNt'[TN!1|%1W71*54>=S9&^AYaG(k,}'B6C8),Yj=zMkeCdHu8[H\"|GBE;'du",
        COMMON_API: "https://tts-common-api.herokuapp.com",
        COMMON_ID: "74a7a67458d77c3699a2db5132df8506afea37b31081987585d74724db98c1eb",
        COMMON_SECRET: 'I."b^34+Q>X\\"bHW.^9,6~=PtZsOn5F49kdWolPIlS=BW}9@N\\DGRxA9gNeHI}q{',
        TIMESHEET_URL: "https://tms.ishkuni.uz",
    },
};

export default modes[NODE_ENV] || {
    AUTH: "http://188.166.84.222:9100",
    URL: "http://209.38.204.67:9101",
    ONB_URL: "http://209.38.204.67:9104",
    HRP_DOC_API: "http://159.223.205.127:9103",
    CLIENT_ID: "398970e4cfa7449eb02f68c7d181412343e4b82f838b5aa72691584e958c41c2",
    SECRET: "1~BNt'[TN!1|%1W71*54>=S9&^AYaG(k,}'B6C8),Yj=zMkeCdHu8[H\"|GBE;'du",
    LOGIN: "http://174.138.2.211:9100",
    COMMON_API: "https://tts-common-api.herokuapp.com",
    COMMON_ID: "74a7a67458d77c3699a2db5132df8506afea37b31081987585d74724db98c1eb",
    COMMON_SECRET: 'I."b^34+Q>X\\"bHW.^9,6~=PtZsOn5F49kdWolPIlS=BW}9@N\\DGRxA9gNeHI}q{',
    TIMESHEET_URL: "http://143.244.173.81:9102",
};
