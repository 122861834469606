import { Button, Grid } from "@material-ui/core";
import React, { useRef, useState } from "react";
import { CircularProgress } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";
import REACT_APP from "../../environment";
import { useEffect } from "react";
import regex from "tts-hrp-val-lib";
import { useTranslation } from "react-i18next";

const UpdateDepartment = (props) => {
    const { t } = useTranslation();
    const { getDate, data } = props;
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(null);
    const closeRef = useRef(null);
    const {
        register,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const Submit = async (date) => {
        setLoading(true);
        axios
            .put(`${REACT_APP.URL}/api/hrp/v1/hr/department/${data.department_id}`, date, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setLoading(false);
                getDate();
                closeRef.current.click();
                toast.success(t("Success"));
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("Something went wrong"));
            });
    };

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setValue(data);
            setLoading(false);
        });
    }, [data]);

    return (
        <div className="container">
            <div className="modal fade" id="editDepartment" tabIndex="-1" aria-labelledby="editDepartmentLabel" aria-hidden="true">
                {loading ? <div className="hrp-overlay"></div> : null}
                <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body p-3">
                            <div className=" flex-column container">
                                <form onSubmit={handleSubmit(Submit)} className="container py-2 example row g-3">
                                    <Grid className="modal-header py-2 px-0">
                                        <h5>{t("Update Department")}</h5>
                                    </Grid>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item container spacing={3} xs={12} sm="auto" className="pt-4 mt-0">
                                            <Grid item xs={12} sm={12} className="pt-0">
                                                <label for="" className="form-label">
                                                    {t("Name")}
                                                </label>
                                                <input
                                                    type="text"
                                                    defaultValue={value?.department_name ? value?.department_name : ""}
                                                    className={`form-control ${errors.name && "is-invalid"}`}
                                                    disabled={loading}
                                                    {...register("name", { required: true, pattern: regex.company_name })}
                                                />
                                            </Grid>

                                            <Grid className="modal-footer mt-4 pt-3" item xs={12} sm={12}>
                                                <Button
                                                    color={loading ? "inherit" : "secondary"}
                                                    className="me-3"
                                                    variant="contained"
                                                    data-bs-dismiss="modal"
                                                    ref={closeRef}
                                                    onClick={() => reset()}
                                                >
                                                    {t("Cancel")}
                                                </Button>

                                                <Button
                                                    className="float-end"
                                                    disabled={loading}
                                                    color="primary"
                                                    type="submit"
                                                    variant="contained"
                                                    startIcon={loading && <CircularProgress size="0.9rem" />}
                                                >
                                                    {loading ? t("Saving") : t("Save")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default React.memo(UpdateDepartment);
