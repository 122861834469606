import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, CircularProgress, Grid, Typography } from "@mui/material";
import { Field, FieldArray, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { useParams } from "react-router-dom";
import { array, object, string } from "yup";
import REACT_APP from "../../../environment";
import { toast } from "react-toastify";
import FileSaver from "file-saver";
import axios from "axios";
import TextFieldd from "@mui/material/TextField";
import regex from "tts-hrp-val-lib";
import { useTranslation } from "react-i18next";

export default function Document({ next }) {
    const { t } = useTranslation();
    const [edit, setEdit] = useState(true);
    const [id, setId] = useState(useParams().id);
    const [disable, setDisable] = useState(true);
    const [loading, setLoading] = useState(true);
    const [document, setDocument] = useState([]);
    const [docType, setDocType] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [resumeErrorMsg, setResumeErrorMsg] = useState(false);

    useEffect(() => {
        getResume();
        getDocument();
    }, []);

    const getDocument = () => {
        setLoading(true);
        axios(`${REACT_APP.ONB_URL}/api/hrp/v2/onb/adm/${id}/documents`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => {
                setDocument(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                setErrorMsg(true);
                setLoading(false);
            });
    };

    const submit = (values) => {
        if (values.documents.length === 0) {
            setDisable(true);
            next();
            return;
        }

        values.documents?.forEach((element, i) => {
            const body = new FormData();
            body.append("diploma", window.document.querySelector(`#documents${i}document`).files[0]);
            body.append("document_name", element.document_name);

            axios
                .post(`${REACT_APP.ONB_URL}/api/hrp/v2/onb/adm/${id}/document`, body, { headers: { Authorization: sessionStorage.getItem("token") } })
                .then((res) => {
                    setDisable(true);
                    next();
                })
                .catch((err) => toast.error(t("Something went wrong")));
        });
    };

    const getImage = (id) => {
        setLoadingModal(true);
        fetch(`${REACT_APP.HRP_DOC_API}/file/${id}`, {
            headers: {
                "client-id": REACT_APP.CLIENT_ID,
                secret: REACT_APP.SECRET,
                "Content-type": "application/pdf",
            },
        })
            .then((res) => res.blob())
            .then((blob) => {
                if (blob.type === "application/pdf" || /image/.test(blob.type)) {
                    setLoadingModal(false);
                    setTimeout(() => {
                        window.document.querySelector("#document_doc").src = window.URL.createObjectURL(blob);
                    });
                } else {
                    setLoadingModal(false);
                    setDocType(true);
                    setTimeout(() => {
                        FileSaver.saveAs(blob, "diploma");
                    });
                }
            })
            .catch((err) => setLoadingModal(false));
    };

    const removeFile = async (uuid) => {
        const response = await axios.delete(`${REACT_APP.ONB_URL}/api/hrp/v2/onb/document/${uuid}`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        });
        getDocument();
    };

    const handleEdit = () => {
        setDisable(false);
        setEdit(false);
    };

    // RESUME

    const [resume, setResume] = useState([]);
    const [resumeLoading, setResumeLoading] = useState(true);
    const [errorMsgResume, setErrorMsgResume] = useState(false);

    const getResume = () => {
        setResumeLoading(true);
        axios
            .get(`${REACT_APP.ONB_URL}/api/hrp/v2/onb/adm/${id}/resume`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setResume(res.data.data);
                setResumeLoading(false);
            })
            .catch((err) => {
                setResumeErrorMsg(true);
                setResumeLoading(false);
            });
    };

    const submitResume = () => {
        if (!window.document.querySelector("#resume").value) {
            setErrorMsgResume(true);
            return;
        }
        setLoadingModal(true);
        const body = new FormData();
        body.append("diploma", window.document.querySelector("#resume").files[0]);

        axios
            .post(`${REACT_APP.ONB_URL}/api/hrp/v2/onb/adm/${id}/resume`, body, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setDisable(true);
                getResume();
                setLoadingModal(false);
            })
            .catch((err) => {
                toast.error(t("Something went wrong"));
                setLoadingModal(false);
            });
    };

    const removeResume = (idx) => {
        axios
            .delete(`${REACT_APP.ONB_URL}/api/hrp/v2/onb/adm/${id}/resume/${idx}`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((date) => getResume())
            .catch((err) => toast.error(t("Something went wrong")));
    };

    const handleChange = (e) => {
        e.target.value ? setErrorMsgResume(false) : setErrorMsgResume(true);
    };

    return (
        <>
            <div className="d-flex justify-content-center flex-column container py-3">
                <div className="py-2">
                    {/* RESUME */}
                    <div className="card mb-4">
                        <div className="card-header">
                            <h5 className="text-center" style={disable ? { color: "#aaa" } : {}}>
                                {t("Resume")}
                            </h5>
                        </div>
                        <div className="row d-flex justify-content-center p-5">
                            {resumeLoading ? (
                                <div className="w-100 all__center py-4">
                                    <CircularProgress />
                                </div>
                            ) : resumeErrorMsg ? (
                                <div className="w-100 all__center py-4 my-4">
                                    <p style={{ color: "#ff0000", fontWeight: "550" }}>{t("An error occurred")}</p>
                                </div>
                            ) : (
                                <>
                                    {resume.length === 0 ? (
                                        <div className="row g-3 d-flex align-items-end justify-content-center">
                                            <div className="col-sm-5">
                                                <TextFieldd
                                                    error={errorMsgResume}
                                                    style={{ width: "100%" }}
                                                    id="resume"
                                                    name="resume"
                                                    type="file"
                                                    variant="standard"
                                                    helperText={errorMsgResume && t("field is required")}
                                                    onChange={handleChange}
                                                    disabled={loadingModal}
                                                    inputProps={{ accept: "application/msword, application/pdf" }}
                                                />
                                            </div>
                                            <div className="col-sm-2">
                                                <Button
                                                    className="float-end mt-1"
                                                    onClick={() => submitResume()}
                                                    variant="contained"
                                                    disabled={loadingModal}
                                                    style={errorMsgResume ? { marginBottom: "20px" } : {}}
                                                    startIcon={loadingModal ? <CircularProgress size="0.9rem" /> : ""}
                                                >
                                                    {loadingModal ? t("Saving") : t("Save")}
                                                </Button>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="row g-3 d-flex align-items-end justify-content-center">
                                                <div className="col-md-6">
                                                    <div id="img_span">
                                                        <span
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#exampleModal"
                                                            onClick={() => getImage(resume[0]?.document_id)}
                                                        >
                                                            {resume[0]?.document_id}
                                                        </span>
                                                        {!disable && (
                                                            <button
                                                                type="button"
                                                                aria-label="Close"
                                                                className="btn-close"
                                                                onClick={() => removeResume(resume[0]?.document_id)}
                                                            ></button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div className="col-md-2 pt-2"></div>
                                </>
                            )}
                        </div>
                    </div>

                    {/* DOCUMENTS */}
                    <div className="card">
                        <div className="card-header">
                            <h5 className="text-center" style={disable ? { color: "#aaa" } : {}}>
                                {t("Documents")}
                            </h5>
                        </div>
                        <div>
                            {loading ? (
                                <div className="w-100 all__center py-5 my-4">
                                    <CircularProgress />
                                </div>
                            ) : errorMsg ? (
                                <div className="w-100 all__center py-5 my-5">
                                    <p style={{ color: "#ff0000", fontWeight: "550" }}>{t("An error occurred")}</p>
                                </div>
                            ) : (
                                <Card>
                                    <CardContent style={{ padding: "24px" }}>
                                        <div className="row mb-4">
                                            {document.map((el, i) => (
                                                <div className="col-md-6" key={i}>
                                                    <div className="w-100 d-flex align-items-end justify-content-center">
                                                        <div id="img_span">
                                                            <span
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                                onClick={() => getImage(el.document_id)}
                                                            >
                                                                {el.document_name}
                                                            </span>
                                                            {!disable && (
                                                                <button
                                                                    type="button"
                                                                    className="btn-close"
                                                                    aria-label="Close"
                                                                    onClick={() => removeFile(el.document_id)}
                                                                ></button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <Formik
                                            initialValues={{ documents: [] }}
                                            validationSchema={object({
                                                documents: array(
                                                    object({
                                                        document_name: string()
                                                            .matches(regex.file_name, { message: t("invalid value") })
                                                            .required(t("field is required")),
                                                        document: string().required(t("field is required")),
                                                    })
                                                ),
                                            })}
                                            onSubmit={async (values) => {
                                                submit(values);
                                                return new Promise((res) => setTimeout(res, 500));
                                            }}
                                        >
                                            {({ values, errors, isSubmitting }) => (
                                                <Form autoComplete="off">
                                                    <Grid container direction="column" spacing={2}>
                                                        <FieldArray name="documents">
                                                            {({ push, remove }) => (
                                                                <React.Fragment>
                                                                    {values.documents?.map((_, index) => (
                                                                        <Grid container item key={index} spacing={2}>
                                                                            <Grid item container spacing={4} xs={12} className="d-flex justify-content-center">
                                                                                <Grid item xs={12} md={5}>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        variant="standard"
                                                                                        name={`documents[${index}].document_name`}
                                                                                        component={TextField}
                                                                                        label={t("Document's name")}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} md={5}>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        variant="standard"
                                                                                        type="file"
                                                                                        name={`documents[${index}].document`}
                                                                                        id={`documents${index}document`}
                                                                                        component={TextField}
                                                                                        label={t("Document")}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} md={2}>
                                                                                    <Button
                                                                                        color="error"
                                                                                        variant="contained"
                                                                                        className="float-end mt-3"
                                                                                        disabled={isSubmitting}
                                                                                        onClick={() => remove(index)}
                                                                                    >
                                                                                        {t("Delete")}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    ))}
                                                                    <Grid item>
                                                                        {typeof errors.contacts === "string" ? (
                                                                            <Typography color="error">{errors.contacts}</Typography>
                                                                        ) : null}
                                                                    </Grid>
                                                                </React.Fragment>
                                                            )}
                                                        </FieldArray>
                                                    </Grid>
                                                </Form>
                                            )}
                                        </Formik>
                                    </CardContent>
                                </Card>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content bg-white">
                        <div className="modal-header py-1">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {t("Document")}
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body img-fluid">
                            {loadingModal ? (
                                <div className="spinner-border text-info my-5" role="status">
                                    <span className="visually-hidden">{t("Loading...")}</span>
                                </div>
                            ) : docType ? (
                                <h2 className="m-auto my-5">{t("File downloaded")}!</h2>
                            ) : (
                                <iframe id="document_doc" title="document_doc" src="" alt="" height="750" width="900" />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
