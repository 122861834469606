import List from "./List";
import "./Board.css";

const EmployeeTransfer = () => {
    return (
        <div className="policy">
            <List />
        </div>
    );
};

export default EmployeeTransfer;
