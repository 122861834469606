import { Button, Grid } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { CircularProgress } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import REACT_APP from "../../environment";
import axios from "axios";
import regex from "tts-hrp-val-lib";
import { useTranslation } from "react-i18next";

const EditBranches = ({ getDate, setIdx, data }) => {
    const { t } = useTranslation();
    const [countryValue, setCountryValue] = useState("");
    const [loading, setLoading] = useState(false);
    const [country, setCountry] = useState([]);
    const [regions, setRegions] = useState([]);
    const closeRef = useRef(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm();

    const getCountry = () => {
        axios(`${REACT_APP.COMMON_API}/tts/api/v01/countries`, {
            headers: { "client-id": REACT_APP.COMMON_ID, secret: REACT_APP.COMMON_SECRET },
        })
            .then((res) => setCountry(res.data.data))
            .catch((err) => {});
    };

    const getRegions = () => {
        axios(`${REACT_APP.COMMON_API}/tts/api/v01/region/14ed6cfc-d787-45ba-b44d-84ac06e37935`, {
            headers: { "client-id": REACT_APP.COMMON_ID, secret: REACT_APP.COMMON_SECRET },
        })
            .then((res) => setRegions(res.data.data))
            .catch((err) => {});
    };

    const Submit = async (date) => {
        let body = {
            id: data.id,
            name: date.name,
            email: date.email,
            phone: date.phone,
            address: {
                id: data.branch_address.id,
                country: date.country,
                city: date.city,
                region: date.region,
                address1: date.address1,
                address2: date.address2 ? date.address2 : null,
                zip_code: date.zip_code ? date.zip_code : null,
            },
        };
        setLoading(true);
        axios
            .put(`${REACT_APP.URL}/api/hrp/v1/branch`, body, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setLoading(false);
                getDate();
                closeRef.current.click();
                toast.success(t("Success"));
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("Something went wrong"));
            });
    };

    const handleCancel = () => {
        setTimeout(() => {
            setIdx("");
        });
    };

    useEffect(() => {
        getCountry();
        getRegions();
        setCountryValue(data.branch_address?.country);
    }, []);

    return (
        <div className="container">
            <div className="modal fade" id="editModal" tabIndex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
                {loading ? <div className="hrp-overlay"></div> : null}
                <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body p-3">
                            <div className=" flex-column container">
                                <form onSubmit={handleSubmit(Submit)} className="container py-2 example row g-3">
                                    <Grid className="modal-header py-2 px-0">
                                        <h5>{t("Update Branch")}</h5>
                                    </Grid>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item container spacing={3} xs={12} sm="auto" className="pt-4 mt-0">
                                            <Grid item xs={12} sm={6}>
                                                <input
                                                    type="text"
                                                    placeholder={t("Name")}
                                                    defaultValue={data?.name ? data?.name : ""}
                                                    className={errors.name ? "is-invalid form-control" : "form-control"}
                                                    {...register("name", { required: true, pattern: regex.company_name })}
                                                    disabled={loading}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <input
                                                    type="email"
                                                    placeholder={t("Email")}
                                                    defaultValue={data?.email ? data?.email : ""}
                                                    className={errors.email ? "is-invalid form-control" : "form-control"}
                                                    disabled={loading}
                                                    {...register("email", { required: true, pattern: regex.email })}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <input
                                                    type="text"
                                                    placeholder={t("Phone")}
                                                    defaultValue={data?.phone ? data?.phone : ""}
                                                    className={errors.phone ? "is-invalid form-control" : "form-control"}
                                                    disabled={loading}
                                                    {...register("phone", { required: true, pattern: regex.phone })}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <input
                                                    type="text"
                                                    placeholder={t("Zip Code")}
                                                    defaultValue={data?.branch_address?.zip_code ? data?.branch_address?.zip_code : ""}
                                                    className={errors.zip_code ? "is-invalid form-control" : "form-control"}
                                                    disabled={loading}
                                                    {...register("zip_code", { pattern: regex.zip_code })}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <select
                                                    className={errors.country ? "is-invalid form-select" : "form-select"}
                                                    onClick={(e) => {
                                                        setCountryValue(e.target.value);
                                                        setValue("region", "");
                                                    }}
                                                    disabled={loading}
                                                    {...register("country", { required: true })}
                                                    defaultValue={data.branch_address?.country}
                                                >
                                                    <option value="">{t("Select country")}</option>
                                                    {country.map((el) => (
                                                        <option key={el.id} selected={data.branch_address?.country === el.name} value={el.name}>
                                                            {el.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <input
                                                    type="text"
                                                    placeholder={t("City")}
                                                    defaultValue={data.branch_address?.city ? data.branch_address?.city : ""}
                                                    className={errors.city ? "is-invalid form-control" : "form-control"}
                                                    disabled={loading}
                                                    {...register("city", { required: true, pattern: regex.city })}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                {countryValue === "Uzbekistan" ? (
                                                    <select
                                                        className={errors.region ? "is-invalid form-select" : "form-select"}
                                                        defaultValue={data.branch_address?.region ? data.branch_address?.region : ""}
                                                        {...register("region", { required: true })}
                                                    >
                                                        <option value="">{t("Select region")}</option>
                                                        {regions.map((el) => (
                                                            <option key={el.id} selected={data.branch_address?.region === el.name} value={el.name}>
                                                                {el.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                ) : (
                                                    <input
                                                        type="text"
                                                        placeholder={t("Region")}
                                                        defaultValue={data.branch_address?.region ? data.branch_address?.region : ""}
                                                        className={errors.region ? "is-invalid form-control" : "form-control"}
                                                        {...register("region", { required: true, pattern: regex.region })}
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <input
                                                    type="text"
                                                    placeholder={t("Address 1")}
                                                    defaultValue={data.branch_address?.address1 ? data.branch_address?.address1 : ""}
                                                    {...register("address1", { required: true, pattern: regex.address1 })}
                                                    className={errors.address1 ? "is-invalid form-control" : "form-control"}
                                                    disabled={loading}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <input
                                                    type="text"
                                                    placeholder={t("Address 2")}
                                                    defaultValue={data.branch_address?.address2 ? data.branch_address?.address2 : ""}
                                                    className={errors.address2 ? "is-invalid form-control" : "form-control"}
                                                    disabled={loading}
                                                    {...register("address2", { pattern: regex.address2 })}
                                                />
                                            </Grid>

                                            <Grid className="modal-footer mt-4 pt-3" item xs={12} sm={12}>
                                                <Button
                                                    className="me-3"
                                                    variant="contained"
                                                    data-bs-dismiss="modal"
                                                    ref={closeRef}
                                                    onClick={handleCancel}
                                                    color={loading ? "inherit" : "secondary"}
                                                >
                                                    {t("Cancel")}
                                                </Button>

                                                <Button
                                                    className="float-end"
                                                    disabled={loading}
                                                    color="primary"
                                                    type="submit"
                                                    variant="contained"
                                                    startIcon={loading && <CircularProgress size="0.9rem" />}
                                                >
                                                    {loading ? t("Saving") : t("Save")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default React.memo(EditBranches);
