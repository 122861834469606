import DepartmentDetails from "../components/Department/Tabs/Tabs";

const DepartmentTabs = () => {
    return (
        <div className="container">
            <DepartmentDetails />
        </div>
    );
};

export default DepartmentTabs;
