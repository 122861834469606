import React from "react";
import { Card, CardContent, Grid, MenuItem } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Empty } from "antd";

export default function WorkHistory({ data }) {
    return (
        <div className="d-flex justify-content-center flex-column container py-3">
            <div className="py-2">
                <div className="card">
                    <div className="card-header">
                        <h5 className="text-center" style={{ color: "#aaa" }}>
                            Work History
                        </h5>
                    </div>
                    <div>
                        {data.length === 0 ? (
                            <div className="py-4">
                                <Empty />
                            </div>
                        ) : (
                            <Card>
                                <CardContent style={{ padding: "24px" }}>
                                    <Formik initialValues={{ work: data }}>
                                        {({ values }) => (
                                            <Form autoComplete="off">
                                                <Grid container direction="column" spacing={2}>
                                                    {values.work?.map((_, index) => (
                                                        <Grid container item key={index} spacing={2}>
                                                            <Grid item container spacing={2} xs={12}>
                                                                <Grid item xs={12} style={index === 0 ? { display: "none" } : { display: "block" }}>
                                                                    <p className="text-center mt-3" style={{ fontWeight: "500", color: "#aaa" }}>
                                                                        Work history {index + 1}
                                                                    </p>
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled
                                                                        name={`work[${index}].name`}
                                                                        pattern={"[0-9]"}
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label="Name"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled
                                                                        name={`work[${index}].position`}
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label="Position"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled
                                                                        name={`work[${index}].phone`}
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label="Company phone"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled
                                                                        name={`work[${index}].website`}
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label="Company website"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled
                                                                        name={`work[${index}].email`}
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label="Company email"
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled
                                                                        name={`work[${index}].supervisorName`}
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label="Supervisor name"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled
                                                                        name={`work[${index}].supervisorPosition`}
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label="Supervisor position"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled
                                                                        name={`work[${index}].supervisorPhone`}
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label="Supervisor phone"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled
                                                                        name={`work[${index}].supervisorEmail`}
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label="Supervisor email"
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} sm={6}>
                                                                    <label className="mat_label">Date of starting</label>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled
                                                                        type="date"
                                                                        name={`work[${index}].start_date`}
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        value={_.start_date?.slice(0, 10)}
                                                                        format="dd/MM/yyyy"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <label className="mat_label">Date of leaving</label>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled
                                                                        type="date"
                                                                        name={`work[${index}].end_date`}
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        value={_.end_date?.slice(0, 10)}
                                                                        format="dd/MM/yyyy"
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12}>
                                                                    <p className="text-center mt-3" style={{ color: "#aaa" }}>
                                                                        Reference
                                                                    </p>
                                                                </Grid>

                                                                {_.references?.map((ref, idx) => (
                                                                    <Grid container item key={idx} spacing={2}>
                                                                        <Grid item container spacing={2} xs={12}>
                                                                            <Grid item xs={12} sm={3}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled
                                                                                    name={`work[${index}].references[${idx}].name`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    value={ref.first_name + " " + ref.last_name}
                                                                                    label="Name"
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={3}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled
                                                                                    name={`work[${index}].references[${idx}].position`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label="Position"
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={3}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled
                                                                                    name={`work[${index}].references[${idx}].phone`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label="Phone"
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={3}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    disabled
                                                                                    name={`work[${index}].references[${idx}].email`}
                                                                                    component={TextField}
                                                                                    variant="standard"
                                                                                    label="Email"
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                ))}

                                                                <Grid item xs={12}>
                                                                    <p className="text-center mt-3" style={{ color: "#aaa" }}>
                                                                        Address
                                                                    </p>
                                                                </Grid>

                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled
                                                                        name={`work[${index}].address1`}
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label="Address 1"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled
                                                                        name={`work[${index}].address2`}
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label="Address 2"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled
                                                                        name={`work[${index}].city`}
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label="City"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled
                                                                        name={`work[${index}].zip_code`}
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label="Zipcode"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled
                                                                        name={`work[${index}].country`}
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label="Country"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Field
                                                                        fullWidth
                                                                        disabled
                                                                        name={`work[${index}].region`}
                                                                        component={TextField}
                                                                        variant="standard"
                                                                        label="Region"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Form>
                                        )}
                                    </Formik>
                                </CardContent>
                            </Card>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
